import { gql } from '@apollo/client';

export const MODE_OF_TRANSPORT_FOR_DROPDOWN = gql`
    query GetAllModeOfTransport {
        getAllModeOfTransport {
            code
            success
            message
            results {
                Id
                Code
            }
        }
  }`;

export default MODE_OF_TRANSPORT_FOR_DROPDOWN;
