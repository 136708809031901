import { gql } from '@apollo/client';

export const PRINT_FINAL_GOODS_RECEIPT_NOTE = gql`
    mutation PRINT_FINAL_GOODS_RECEIPT_NOTE($warehouseInId: Int!) {
        printFinalGoodsReceiptNote(warehouseInId: $warehouseInId) {
            code
            success
            message
        }
    }`;

export default PRINT_FINAL_GOODS_RECEIPT_NOTE;
