import React, { memo, useCallback, useState } from 'react';
import {
  Form, Row, Col, Input,
  Button,
  Flex,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';

import CREATE_PARTY from '@graphql/createParty';

import useCreateUpdateRecord from '@hooks/useCreateUpdateRecord';

import { FormStyled } from '@components/Styles/FormStyles';
import SelectParty from '@components/Select/SelectParty';

function PartyAddForm({
  onSuccess,
  formComponentProps,
}) {
  const { parentPartyFilterKey } = formComponentProps;

  const [form] = useForm();
  const [formData, setFormData] = useState(null);

  const { loading } = useCreateUpdateRecord({
    formData,
    mutation: CREATE_PARTY,
    callback: (result) => onSuccess(result),
  });

  const handleFinish = useCallback((values) => {
    const newData = {
      ...values,
      [parentPartyFilterKey]: 1,
      Active: 1,
    };

    setFormData(newData);
  }, []);

  return (
    <FormStyled
      form={form}
      layout="horizontal"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={handleFinish}
    >
      <Row gutter={24}>
        <Col sm={24}>
          <Form.Item
            name="Code"
            label="Code"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={24}>
          <Form.Item
            name="Name"
            label="Name"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={24}>
          <Form.Item
            name="Website"
            label="Website"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={24}>
          <Form.Item
            name="Parent_Party_Fk"
            label="Parent Party"
          >
            <SelectParty
              customFilter={(data) => data.filter((d) => d[parentPartyFilterKey] === 1)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Flex justify="flex-end">
        <Button type="primary" disabled={loading} loading={loading} htmlType="submit">
          Create
        </Button>
      </Flex>
    </FormStyled>
  );
}

export default memo(PartyAddForm);
