import { gql } from '@apollo/client';

const GET_PRINT_PICK = gql`
  query GetPrintablePickedScanIds($Id: Int!) {
    getPrintablePickedScanIds(WarehouseOutId: $Id) {
      code
      success
      message
      result {
        WarehouseOutDetails {
          JobNumber
          CustomerParty {
            Code
            Name
          }
          ModeOfTransport {
            Code
            Description
          }
          JobDate
          JobStatus
          FreightJobNumber
          Remarks
        }
        PickedScanIds {
          ScanId
          Warehouse {
            Code
            Name
          }
          StorageLocation {
            Id
            Code
          }
          LengthInch
          WidthInch
          HeightInch
          LengthCm
          WidthCm
          HeightCm
          WeightKg
          WeightLbs
          VolumeCbm
          VolumeCft
          POLineItems {
            PurchaseOrder {
              PONumber
            }
            MRNumberWarehouseInboundJob
            Part {
              Part_Number
              Part_Description
              Schedule_B
            }
            AdditionalDescription
            ReceivedQuantity
            CountryOfOrigin {
                Code
                Name
            }
            UnitOfMeasure {
              Code
              Description
            }
            LineNumber
          }
        }
        TotalScanIds
      }
    }
  }
`;

export default GET_PRINT_PICK;
