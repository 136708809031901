import { gql } from '@apollo/client';

const GET_ALL_CURRENCY = gql`
    query Currency{
    getAllCurrency {
        code
        success
        message
        results {
        Id
        Code
        Description
        Country_Fk
        }
    }
    }
`;

export default GET_ALL_CURRENCY;
