import { gql } from '@apollo/client';

const CREATE_PARTY = gql`
    mutation CreateParty($data: PartyInput!) {
        createParty(data: $data) {
            code
            success
            message
            result {
                Id
                Code
                Name
            }
        }
    }
`;

export default CREATE_PARTY;
