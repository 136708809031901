import { gql } from '@apollo/client';

const GET_INBOUND_JOB_BY_ID = gql`
    query GetWarehouseInById($Id: Int!) {
        getWarehouseInById(Id: $Id) {
            code
            success
            message
            result {
                Id
                JobNumber
                JobDate
                JobStatus
                ModeOfTransport_Fk
                PurchaseOrder_Fk
                Customer_Party_Fk
                Supplier_Party_Fk
                Consignee_Party_Fk
                Shipper_Party_Fk
                Carrier_Party_Fk
                CarrierReference
                ArrivalDate
                PackageCount
                ScanIDCount
                PersonInCharge
                FirstMrPrintedBy
                FirstMrPrintedDate
                MrPrintedBy
                MrPrintedDate
                CustomerReference
                SupplierReference
                ConsigneeReference
                ShipperReference
                ZoneStatus
                FtzParty
                FtzEntryNumber
                FreightJobNumber
                CreatedBy
                CreatedDate
                UpdatedBy
                UpdatedDate
                OfficeLocation
                IsCustomerEditable

                PurchaseOrder {
                    PONumber
                    CustomerParty {
                        Id
                        Name
                        Code
                    }
                }
                ModeOfTransport {
                    Id
                    Code
                    Description
                }
                Customer {
                    Id
                    Name
                    Code
                }
                Supplier {
                    Id
                    Name
                    Code
                }
                Consignee {
                    Id
                    Name
                    Code
                }
                Shipper {
                    Id
                    Name
                    Code
                }
                Carrier {
                    Id
                    Name
                    Code
                }
            }
        }
    }
`;

export default GET_INBOUND_JOB_BY_ID;
