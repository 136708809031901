import { memo } from 'react';

import GET_ALL_WAREHOUSE from '@graphql/getAllWarehouse';

import SelectLazy from '../SelectLazy';

function SelectWarehouse(props) {
  const renderOptions = (data) => (data || []).map((d) => ({
    value: d.Id,
    label: `[${d.Code}] ${d.Name}`,
  }));

  return (
    <SelectLazy
      {...props}
      placeholder="Select Warehouse"
      customRender={renderOptions}
      query={GET_ALL_WAREHOUSE}
    />
  );
}

export default memo(SelectWarehouse);
