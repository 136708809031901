import React, { memo } from 'react';
import { Button, Skeleton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { ModalStyled } from '@components/Styles/ModalStyles';
import { ContentStyled } from '@components/Styles/Global';
import ScanIdForm from '../Components/ScanIdForm';

function InboundJobAddScanId({
  record,
  form,
  onFinish,
  onFinishFailed,
  onValuesChange,
  onSave,
  onCancel,
  loading,
  detailLoading,
  open,
  onOpen,
}) {
  return (
    <>
      <Button icon={<PlusOutlined />} onClick={onOpen} />
      {open && (
        <ModalStyled
          title={record?.Id ? `Scan ID #${record?.ScanId}` : 'Scan ID'}
          open={open}
          onCancel={onCancel}
          maskClosable={false}
          keyboard={false}
          width={700}
          footer={[
            <div key={1} />,
            <Button key={2} type="primary" onClick={onSave} disabled={loading} loading={loading}>
              {record?.Id ? 'Save' : 'Create'}
            </Button>,
          ]}
        >
          <ContentStyled style={{ marginTop: 25 }}>
            <Skeleton loading={detailLoading} active>
              <ScanIdForm
                record={record}
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onValuesChange={onValuesChange}
              />
            </Skeleton>
          </ContentStyled>
        </ModalStyled>
      )}
    </>
  );
}

export default memo(InboundJobAddScanId);
