import { gql } from '@apollo/client';

const DELETE_PURCHASE_ORDER_OSD_REASON = gql`
    mutation DeleteOSDReason($id: Int!) {
        deleteOSDReason(id: $id) {
            code
            success
            message
        }
    }
`;

export default DELETE_PURCHASE_ORDER_OSD_REASON;
