import { gql } from '@apollo/client';

const CANCEL_SCAN_ID = gql`
    mutation CancelScanId($id: Int!) {
        cancelScanId(id: $id) {
            code
            success
            message
        }
    }
`;

export default CANCEL_SCAN_ID;
