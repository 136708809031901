import React, { memo, useCallback } from 'react';
import {
  Page, Text, View, Document, StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { TD, TR, Table } from '@ag-media/react-pdf-table';

import { parseAndToFixed } from '@utils/helpers/helpers';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    fontSize: 14,
  },
  mrNo: {
    padding: 12,
  },
  barcode: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  barcodeImg: {
    width: 300,
    height: 100,
    marginBottom: 4,
  },
  spacer: {
    height: 86,
  },
  dimensions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
  },
  dimensionsInner: {
    width: 320,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    justifyContent: 'space-between',
  },
  borderSmall: {
    borderTop: '1px solid #000',
    height: '1px',
  },
  barcodeDetails: {
    padding: 12,
    fontSize: 11,
  },
  cellLabel: {
    justifyContent: 'space-between',
  },
  tableNoBorder: {
    border: 'none',
  },
});

function PrintGRNLabels({
  isDraft,
  barcodes,
  list,
  inboundJob,
  onLoadPrintFile,
}) {
  const handleRender = useCallback((blob) => {
    onLoadPrintFile(blob);
  }, []);

  return (
    <Document onRender={handleRender}>
      {(Object.keys(barcodes) || []).map((barcodeKey) => {
        const scanIdObj = list.find((record) => record?.ScanId === barcodeKey);
        const {
          LengthInch,
          WidthInch,
          HeightInch,
          LengthCm,
          WidthCm,
          HeightCm,
          WeightLbs,
          WeightKg,
          VolumeCbm,
          VolumeCft,
        } = scanIdObj || {};
        return (
          <Page size="B6" key={barcodeKey} orientation="landscape" style={styles.page}>
            <View style={styles.mrNo}>
              <Text>
                MR No:
                {inboundJob?.JobNumber}
              </Text>
            </View>
            <View style={styles.barcode}>
              <Image src={barcodes[barcodeKey]} style={styles.barcodeImg} />
            </View>
            {!isDraft ? (
              <View style={styles.dimensions}>
                <View style={{ height: 3 }} />
                <Text>
                  {LengthCm ? parseAndToFixed(LengthCm) : 0}
                  {' '}
                  x
                  {' '}
                  {WidthCm ? parseAndToFixed(WidthCm) : 0}
                  {' '}
                  x
                  {' '}
                  {HeightCm ? parseAndToFixed(HeightCm) : 0}
                  {' '}
                  CM
                </Text>
                <View style={{ height: 6 }} />
                <Text>
                  {LengthInch ? parseAndToFixed(LengthInch) : 0}
                  {' '}
                  x
                  {' '}
                  {WidthInch ? parseAndToFixed(WidthInch) : 0}
                  {' '}
                  x
                  {' '}
                  {HeightInch ? parseAndToFixed(HeightInch) : 0}
                  {' '}
                  INCH
                </Text>
                <View style={{ height: 16 }} />
                <View style={styles.dimensionsInner}>
                  <Text>
                    {WeightLbs ? parseAndToFixed(WeightLbs) : 0}
                    {' '}
                    LBS
                  </Text>
                  <Text>
                    {VolumeCft ? parseAndToFixed(VolumeCft, 3) : 0}
                    {' '}
                    CFT
                  </Text>
                </View>
                <View style={{ height: 6 }} />
                <View style={styles.dimensionsInner}>
                  <Text>
                    {WeightKg ? parseAndToFixed(WeightKg) : 0}
                    {' '}
                    KGS
                  </Text>
                  <Text>
                    {VolumeCbm ? parseAndToFixed(VolumeCbm, 3) : 0}
                    {' '}
                    CBM
                  </Text>
                </View>
              </View>
            ) : (
              <View style={styles.spacer} />
            )}
            <View style={{ height: 12 }} />
            <View style={styles.borderSmall} />
            <View style={styles.barcodeDetails}>
              <Table
                style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
                tdStyle={{ alignItems: 'start', fontWeight: 600, padding: 2 }}
                weightings={[0.25, 0.75]}
              >
                <TR>
                  <TD style={styles.cellLabel}>
                    <Text>Date Arrived</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    {` ${inboundJob?.ArrivalDate || ''}`}
                  </TD>
                </TR>
                <TR>
                  <TD style={styles.cellLabel}>
                    <Text>Customer</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    {`${inboundJob?.Customer?.Name || ''}`}
                  </TD>
                </TR>
                <TR>
                  <TD style={styles.cellLabel}>
                    <Text>Consignee</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    {`${inboundJob?.Consignee?.Name || ''}`}
                  </TD>
                </TR>
                <TR>
                  <TD style={styles.cellLabel}>
                    <Text>Purchase Order</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    {`${inboundJob?.PurchaseOrder?.PONumber || ''}`}
                  </TD>
                </TR>
                <TR>
                  <TD style={styles.cellLabel}>
                    <Text>Location</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    {`${inboundJob?.OfficeLocation || ''}`}
                  </TD>
                </TR>
              </Table>
            </View>
          </Page>
        );
      })}
    </Document>
  );
}

export default memo(PrintGRNLabels);
