import React, { memo, useCallback, useState } from 'react';
import { Button } from 'antd';

import { ModalStyled } from '@components/Styles/ModalStyles';
import AutoDistributionTransfer from '../Components/AutoDistributionTransfer';

function AutoDistribution() {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCancel = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Button type="default" onClick={handleOpen}>
        Auto Distribution
      </Button>
      <ModalStyled
        title="Auto Distribution"
        open={open}
        onCancel={handleCancel}
        maskClosable={false}
        keyboard={false}
        width="95%"
        styles={{ body: { height: 'calc(90vh - 180px)', overflow: 'auto' } }}
        footer={[]}
      >
        <AutoDistributionTransfer />
      </ModalStyled>
    </>
  );
}

export default memo(AutoDistribution);
