import { gql } from '@apollo/client';

export const REMOVE_FROM_OVERPACK_SCAN_ID = gql`
    mutation RemoveFromOverPack($data: RemoveFromOverPackInput!) {
        removeFromOverPack(data: $data) {
            code
            success
            message
        }
    }`;

export default REMOVE_FROM_OVERPACK_SCAN_ID;
