import { gql } from '@apollo/client';

const CHANGE_PASSWORD = gql`
    mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
        changePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
            code
            success
            message
        }
    }
`;

export default CHANGE_PASSWORD;
