import { gql } from '@apollo/client';

export const REOPEN_OVERPACK = gql`
    mutation ReopenOverpack($data: CloseOrReopenOverpackInput!) {
        reopenOverpack(data: $data) {
            code
            success
            message
        }
    }`;

export default REOPEN_OVERPACK;
