import React from 'react';

import GET_ALL_CURRENCY from '@graphql/THgetallCurrency';

import useList from '@hooks/useList';

import { ContentStyled } from '@components/Styles/Global';
import { TableStyled } from '@components/Styles/TableStyles';

function Company() {
  const { data, loading } = useList({ query: GET_ALL_CURRENCY });

  const columns = [
    {
      title: 'Id',
      dataIndex: 'Id',
      key: 'Id',
      defaultSortOrder: 'none',
      render: (text) => (
        <a style={{ userSelect: 'text' }}>
          {text}
        </a>
      ),
    },
    {
      title: 'Code',
      dataIndex: 'Code',
      key: 'Code',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'Description',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Country',
      dataIndex: 'Country_Fk',
      key: 'Country_Fk',
    },
  ];

  return (
    <ContentStyled>
      <TableStyled
        size="small"
        loading={loading}
        dataSource={data}
        columns={columns}
        pagination
        scroll={{ x: true }}
      />
    </ContentStyled>
  );
}

export default Company;
