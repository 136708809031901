import { gql } from '@apollo/client';

export const UPDATE_INBOUND_JOB = gql`
    mutation UpdateInboundJob($data: WarehouseInInputV2!, $id: Int) {
        updateWarehouseInboundJob(data: $data, id: $id) {
            code
            success
            message
        }
    }`;

export default UPDATE_INBOUND_JOB;
