import { memo } from 'react';

import GET_ALL_PURCHASE_ORDERS_FOR_DROPDOWN from '@graphql/TH_getAllPurchaseOrdersForDropdown';

import SelectLazy from '../SelectLazy';

function SelectPurchaseOrder(props) {
  const renderOptions = (data) => (data || []).map((d) => ({
    value: d.Id,
    label: `${d.PONumber} (${d.CustomerParty?.Name} [${d.CustomerParty?.Code}])`,
  }));

  return (
    <SelectLazy
      {...props}
      placeholder="Select Purchase Order"
      customRender={renderOptions}
      query={GET_ALL_PURCHASE_ORDERS_FOR_DROPDOWN}
      lazySearch
    />
  );
}

export default memo(SelectPurchaseOrder);
