import { gql } from '@apollo/client';

const GET_ALL_COMPANIES = gql`
    query GetAllCompanies($limit: Int, $offset: Int, $searchText: String) {
        getAllCompanies (limit: $limit, offset: $offset, searchText: $searchText){
        code
        success
        message
        count
        results {
            _id
            code
            name
            status
            company_type
            phone_no
            email
        }
    }
    }
 `;

export default GET_ALL_COMPANIES;
