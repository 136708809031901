import { memo, useCallback, useState } from 'react';
import { Button, Flex, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ModalStyled } from '@components/Styles/ModalStyles';

function QuickCreate({
  children, modalTitle, tooltipTitle, form, FormComponent, formComponentProps, parseData, parseDropdownValue, ...formItemProps
}) {
  const [open, setOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSuccess = useCallback((result) => {
    form.setFieldValue(formItemProps.id, parseDropdownValue(result));
    setOpen(false);
  }, []);

  return (
    <>
      <Flex gap={12}>
        {children(formItemProps)}
        <Tooltip title={tooltipTitle}>
          <Button icon={<PlusOutlined />} onClick={handleOpenModal} />
        </Tooltip>
      </Flex>

      {open && (
        <ModalStyled
          title={modalTitle}
          open={open}
          onCancel={handleCloseModal}
          maskClosable={false}
          keyboard={false}
          width={700}
          footer={[]}
        >
          <FormComponent formComponentProps={formComponentProps} onSuccess={handleSuccess} />
        </ModalStyled>
      )}
    </>
  );
}

export default memo(QuickCreate);
