import { gql } from '@apollo/client';

export const UPDATE_LOAD_PLAN = gql`
    mutation UpdateLoadPlan($id: Int, $data: LoadPlanInput!) {
        updateLoadPlan(id: $id, data: $data) {
            code
            success
            message
        }
    }`;

export default UPDATE_LOAD_PLAN;
