import { gql } from '@apollo/client';

const GET_PARTS_BY_ID = gql`
    query GetPartsById($Id: Int!) {
        getPartsById(Id: $Id) {
            code
            success
            message
            result {
                Id
                Customer_Party_Fk
                Supplier_Party_Fk
                Part_Number
                Part_Description
                Remarks_1
                Remarks_2
                Remarks_3
                Parent_Part_Fk
                Schedule_B
                ECCN
                CountryOfOrigin_Country_Fk
                Currency_Fk
                Unit_Price
                Customs_Unit_Price
                UnitOfMeasure_Fk
                Active

                Customer {
                    Id
                    Name
                    Code
                }
                Supplier {
                    Id
                    Name
                    Code
                }
                ParentPart {
                    Id
                    Part_Number
                    Part_Description
                }
                CountryOfOrigin {
                    Id
                    Name
                    Code
                }
                UnitOfMeasure {
                    Id
                    Description
                    Code
                }
                Currency {
                    Id
                    Description
                    Code
                }
            }
        }
    }
`;

export default GET_PARTS_BY_ID;
