import { memo, useEffect } from 'react';
import {
  Col, DatePicker, Form, Input, Row, Select,
} from 'antd';
import moment from 'moment';

import GET_ALL_PARTIES from '@graphql/TH_getAllParties';
import MODE_OF_TRANSPORT_FOR_DROPDOWN from '@graphql/TH_getModeOfTransportForDropdown';

import { FORMAT_DATE, timestampToDate } from '@utils/helpers/date';

import { FormStyled } from '@components/Styles/FormStyles';
import { CardStyled } from '@components/Styles/CardStyles';
import SelectLazy from '@components/SelectLazy';

const { Option } = Select;

function OutboundJobForm({
  record, form, onFinish, onValuesChange,
}) {
  const isCreate = !record?.Id;

  const initialValues = {
    JobStatus: 'Open',
    JobDate: moment(),
    Customer_Party_Fk: null,
    FreightJobNumber: null,
    ModeOfTransport_Fk: null,
    Remarks: null,
  };

  useEffect(() => {
    if (record?.Id) {
      form.setFieldsValue({
        JobNumber: record.JobNumber || null,
        JobStatus: record.JobStatus || null,
        JobDate: record.JobDate ? timestampToDate(record.JobDate, false, true) : '',
        Customer_Party_Fk: record.Customer_Party_Fk || 0,
        FreightJobNumber: record.FreightJobNumber || '',
        ModeOfTransport_Fk: record.ModeOfTransport_Fk || null,
        Remarks: record.Remarks || '',
      });
    }
  }, [record]);

  return (
    <FormStyled
      form={form}
      layout="horizontal"
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      initialValues={initialValues}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
    >
      <CardStyled>
        <Row gutter={24}>
          <Col sm={8}>
            <Form.Item
              name="JobNumber"
              label="Outbound Job No."
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item
              name="JobStatus"
              label="Job Status"
            >
              <Select disabled>
                <Option value="Open">Open</Option>
                <Option value="Closed">Closed</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item
              name="JobDate"
              label="Job Date"
            >
              <DatePicker disabled format={FORMAT_DATE} placeholder="" />
            </Form.Item>
          </Col>
        </Row>
      </CardStyled>
      <CardStyled>
        <Row gutter={24}>
          <Col sm={8}>
            <Form.Item
              name="Customer_Party_Fk"
              label="Customer"
            >
              <SelectLazy
                disabled={!isCreate}
                valueKey="Id"
                label="Name"
                query={GET_ALL_PARTIES}
                customFilter={(data) => (data || []).filter((d) => d.Is_Customer === 1)}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="FreightJobNumber"
              label="Freight Job No."
            >
              <Input />
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item
              name="ModeOfTransport_Fk"
              label="Mode of Transport"
            >
              <SelectLazy
                valueKey="Id"
                label="Code"
                query={MODE_OF_TRANSPORT_FOR_DROPDOWN}
              />
            </Form.Item>
          </Col>
          <Col sm={24}>
            <Form.Item
              name="Remarks"
              label="Packing Remarks"
            >
              <Input.TextArea
                rows={12}
              />
            </Form.Item>
          </Col>
        </Row>
      </CardStyled>
    </FormStyled>
  );
}

export default memo(OutboundJobForm);
