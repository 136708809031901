import { useQuery } from '@apollo/client';

import { handleApolloResponse } from '@utils/helpers/apolloResponse';

const useDetail = ({
  query,
  id,
}) => {
  const response = useQuery(query, {
    skip: !id,
    variables: { Id: id },
  });

  const data = handleApolloResponse(response, 'result', {});

  return { ...response, data };
};

export default useDetail;
