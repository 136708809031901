import { gql } from '@apollo/client';

const UPDATE_PARTY = gql`
    mutation UpdateParty($id: Int, $data: PartyInput!) {
        updateParty(id: $id, data: $data) {
            code
            success
            message
        }
    }
`;

export default UPDATE_PARTY;
