// const url = '/test-request/form/:id';
const HEADERS = {
  '/dashboard': {
    titleBold: ['Dashboard and Reports'],
  },
  '/admin/party': {
    titleBold: 'Party List',
  },
  '/admin/currency': {
    titleBold: 'Currency List',
  },
  '/admin/company': {
    titleBold: 'Company List',
  },
  '/admin/location': {
    titleBold: 'Location List',
  },
  '/admin/osdreason': {
    titleBold: 'OSD Reason List',
  },
  '/admin/modetransport': {
    titleBold: 'Mode of Transport List',
  },
  '/admin/tariff': {
    titleBold: 'Tariff List',
  },
  '/admin/unitmeasure': {
    titleBold: 'Unit of Measure List',
  },
  '/admin/warehouse': {
    titleBold: 'Warehouse List',
  },
  '/admin/companies': {
    titleBold: 'Company List',
  },
  '/admin/users': {
    titleBold: 'User List',
  },
  '/admin/users/details': {
    titleBold: 'User Details',
  },
  '/admin/eventlogs': {
    titleBold: 'Event Logs',
  },
  '/tools/event-logs': {
    titleBold: 'Logs',
  },
  '/handover-forms': {
    titleBold: 'Handover Forms',
  },
  '/po-parts-labs': {
    titleBold: 'Purchase Order',
  },
  '/add-parts': {
    titleBold: 'Add Parts',
  },
  '/warehouse-in/list': {
    titleBold: 'Warehouse In',
  },
  '/warehouse-out/list': {
    titleBold: 'Warehouse Out',
  },
  '/reports': {
    titleBold: 'Reports',
  },
};

export default HEADERS;
