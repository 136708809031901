import { gql } from '@apollo/client';

const GET_ALL_PURCHASE_ORDERS_FOR_DROPDOWN = gql`
    query GetAllPurchaseOrders ($limit: Int, $offset: Int, $searchText: String) {
        getAllPurchaseOrders (limit: $limit, offset: $offset, searchText: $searchText ) {
            code
            success
            message
            count
            results {
                Id
                PONumber
                CustomerParty {
                    Id
                    Name
                    Code
                }
            }
        }
    }
`;

export default GET_ALL_PURCHASE_ORDERS_FOR_DROPDOWN;
