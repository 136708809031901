import { gql } from '@apollo/client';

export const GET_ALL_PARTIES = gql`
    query GET_ALL_PARTIES {
        getAllParty {
            code
            success
            message
            results {
                Id
                Code
                Name
                Website
                Salesperson_User_Fk
                Is_Customer
                Is_Customer
                Is_Billing_Party
                Is_Supplier
                Is_Shipper
                Is_Consignee
                Is_Carrier
                Is_NotifyParty
                Parent_Party_Fk
                Active
            }
        }
    }`;

export default GET_ALL_PARTIES;
