import React, { memo } from 'react';
import { Outlet } from 'react-router-dom';

function AdminTools() {
  return (
    <Outlet />
  );
}

export default memo(AdminTools);
