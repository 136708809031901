import { memo } from 'react';
import { Select, Spin } from 'antd';

import useDropdownList from '@hooks/useDropdownList';
import { filterDropdownList } from '@utils/helpers/helpers';

function SelectLazy({
  query,
  valueKey,
  label,
  placeholder,
  selectProps,
  value,
  onChange,
  customFilter,
  lazySearch,
  customRender,
  ...props
}) {
  const { loading, data, filters } = useDropdownList({
    query,
    pageSize: lazySearch ? 99 : null,
  });
  let timer = null;

  const handleSearch = (value) => {
    debouncedFetchOptions(value);
  };

  const handleChange = (value) => {
    onChange(value);
  };

  const handleClear = () => {
    debouncedFetchOptions('', 0);
  };

  const debouncedFetchOptions = (value, timeout = 500) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      filters.setSearchQuery(value);
    }, timeout);
  };

  const list = customFilter ? customFilter(data) : data;

  const renderOptions = customRender ? customRender(list) : (list || []).map((d) => ({
    value: d[valueKey],
    label: d[label],
  }));

  return (
    <Select
      {...props}
      allowClear
      showSearch
      value={value}
      filterOption={lazySearch ? false : filterDropdownList}
      loading={loading}
      placeholder={placeholder || 'Select an option'}
      onSearch={lazySearch ? handleSearch : null}
      onClear={lazySearch ? handleClear : null}
      onChange={handleChange}
      options={renderOptions}
      notFoundContent={loading ? <Spin size="small" /> : null}
    />
  );
}

export default memo(SelectLazy);
