import { gql } from '@apollo/client';

export const GET_ALL_INBOUND_JOBS = gql`
    query GetAllWarehouseIn ($limit: Int, $offset: Int, $searchText: String) {
        getAllWarehouseIn (limit: $limit, offset: $offset, searchText: $searchText ) {
            code
            success
            message
            count
            results {
                Id
                JobDate
                JobNumber
                JobStatus
                ArrivalDate
                MrPrintedDate
                PersonInCharge
                PurchaseOrder_Fk
                Customer_Party_Fk
                Consignee_Party_Fk
                Carrier_Party_Fk
                ArrivalDate
                MrPrintedDate
                CreatedBy
                PurchaseOrder {
                    PONumber
                }
            }
        }
    }`;

export default GET_ALL_INBOUND_JOBS;
