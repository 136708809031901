import { gql } from '@apollo/client';

const GET_ALL_PARENT_PART = gql`
    query GET_ALL_PARENT_PART {
        getAllParentPart {
            code
            success
            message
            results {
                Id
                Part_Number
                Part_Description
            }
        }
    }
  `;

export default GET_ALL_PARENT_PART;
