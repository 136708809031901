import React, { memo } from 'react';
import { Button } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

import { ModalStyled } from '@components/Styles/ModalStyles';
import { ContentStyled } from '@components/Styles/Global';
import DistributeQuantityForm from '../Components/DistributeQuantityForm';

function DistributeQuantity({
  form,
  assignDirection,
  open,
  onFinish,
  onSave,
  onOpenAttach,
  onCancel,
  loading,
  disableAssignLeft,
  disableAssignRight,
}) {
  return (
    <>
      <Button type="primary" shape="circle" icon={<ArrowLeftOutlined />} onClick={() => onOpenAttach('assignLeft')} disabled={disableAssignLeft} />
      <Button type="primary" shape="circle" icon={<ArrowRightOutlined />} onClick={() => onOpenAttach('assignRight')} disabled={disableAssignRight} />

      <ModalStyled
        title="Distribute Quantity"
        open={open}
        onCancel={onCancel}
        maskClosable={false}
        keyboard={false}
        width={500}
        footer={[
          <div key={1} />,
          <Button key={2} type="primary" onClick={onSave} loading={loading} disabled={loading}>
            Go
          </Button>,
        ]}
      >
        <ContentStyled style={{ marginTop: 25 }}>
          <DistributeQuantityForm
            form={form}
            direction={assignDirection}
            onFinish={onFinish}
          />
        </ContentStyled>
      </ModalStyled>
    </>
  );
}

export default memo(DistributeQuantity);
