import { gql } from '@apollo/client';

const GET_PRINT_CIPL = gql`
  query GetPrintableCIPL($Id: Int!) {
    getPrintableCIPL(WarehouseOutId: $Id) {
        code
        success
        message
        result {
          GrandTotalCostForPackingList {
            TotalUnit
            WeightKgs
            TotalCFT
            TotalMRGRN
            GrandTotalMaterialValueCurrency
            GrandTotalMaterialValue
            TotalFreighChargesCurrency
            TotalFreighCharges
            TotalCFRCurrency
            TotalCFR
          }
          CIPLSection1 {
            Shipper {
              Code
              Name
            }
            Consignee {
              Code
              Name
            }
          }
          CIPLSection2 {
            ShippedVia {
              Code
              Description
            }
            Carrier {
              Code
              Name
            }
            VesselVoyageNo
            ETD
            ETA
            Destination {
              City_Code
              Country_Code
              Location_Code
              Name
            }
          }
          CIPLSection3 {
            Notify
          }
          CIPLSection4 {
            BillOfLading_FreightJobNumber
            ContainerOrPackingUnitNo
            ContainerType
            SealNoIfUsed
          }
          OverpackSections {
            PackingUnitNo
            UnitofMeasure {
              Code
              Description
            }
            OverpackScanId
            POLineItems {
              LineItems {
                ItemNo
                Quantity
                UOM
                PartNumber
                CountryOfOrigin
                PONumber
                ScheduleB
                MRNumberWarehouseInboundJob
                Supplier
                UnitPrice
                TotalCost
                PartDescription
                AdditionalDescription
                NetWeightKgs
                ECCN
              }
              SubTotalCostPerPO
            }
            SubTotalWeightKgs
            SubTotalWeightLbs
            SubTotalCBM
            SubTotalCFT
            SubTotalLengthInch
            SubTotalWidthInch
            SubTotalHeightInch
            SubTotalLengthCm
            SubTotalWidthCm
            SubTotalHeightCm
            
            TotalUnit
            TotalWeightKgs
            POSummaryForContainer
            
          }
          SupplierSummaryForEntirePackingList {
            
            SupplierName
            
          }
          POSummeryForEntirePackingList
          SupplierSummaryForEntirePackingList {
            SupplierId
            SupplierCode
            SupplierName
            Address
          }
        }
      }
    }
  `;

export default GET_PRINT_CIPL;
