import { gql } from '@apollo/client';

export const CLOSE_OVERPACK = gql`
    mutation CloseOverpack($data: CloseOrReopenOverpackInput!) {
        closeOverpack(data: $data) {
            code
            success
            message
        }
    }`;

export default CLOSE_OVERPACK;
