import { gql } from '@apollo/client';

const GET_PRINT_PACK = gql`
  query GetPrintablePackedScanIds($Id: Int!) {
    getPrintablePackedScanIds(WarehouseOutId: $Id) {
      code
      success
      message
      result {
        WarehouseOutDetails {
          JobNumber
          CustomerParty {
            Code
            Name
          }
          ModeOfTransport {
            Code
            Description
          }
          JobDate
          JobStatus
          FreightJobNumber
          Remarks
        }
        PackedScanIds {
          ScanId
          Warehouse {
            Code
            Name
          }
          StorageLocation {
            Id
            Code
          }
          LengthInch
          WidthInch
          HeightInch
          LengthCm
          WidthCm
          HeightCm
          WeightKg
          WeightLbs
          VolumeCbm
          VolumeCft
          AttachedScanIds {
            ScanId
            Warehouse {
              Code
              Name
            }
            StorageLocation {
              Id
              Code
            }
            LengthInch
            WidthInch
            HeightInch
            LengthCm
            WidthCm
            HeightCm
            WeightKg
            WeightLbs
            VolumeCbm
            VolumeCft
          }
        }
        TotalScanIds
      }
    }
  }
`;

export default GET_PRINT_PACK;
