import { gql } from '@apollo/client';

export const CREATE_INBOUND_JOB = gql`
    mutation CreateInboundJob($data: WarehouseInInputV2!) {
        createWarehouseInboundJob(data: $data) {
            code
            success
            message
        }
    }`;

export default CREATE_INBOUND_JOB;
