import { useState } from 'react';
import { useQuery } from '@apollo/client';

import { handleApolloResponse } from '@utils/helpers/apolloResponse';

const useDropdownList = ({
  query,
  pageSize,
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  let variables = {};

  if (pageSize) {
    variables = { limit: pageSize, offset: 0 };
  }

  const response = useQuery(query, {
    variables: { ...variables, searchText: searchQuery },
  });

  const data = handleApolloResponse(response, 'results');

  return { ...response, data, filters: { setSearchQuery } };
};

export default useDropdownList;
