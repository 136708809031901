import React, {
  useState, useEffect, memo, useCallback,
} from 'react';
import {
  DatePicker, Form, Input, Select, Col,
  Row, Divider, Radio,
} from 'antd';

import { dateToString } from '@utils/helpers/date';
import CREATE_PURCHASE_ORDER from '@graphql/TH_createPurchaseOrder';

import { ContentStyled } from '@components/Styles/Global';
import { CardStyled } from '@components/Styles/CardStyles';
import { FormStyled } from '@components/Styles/FormStyles';
import useCreateUpdateRecord from '@hooks/useCreateUpdateRecord';
import SelectParty from '@components/Select/SelectParty';
import SelectModeOfTransport from '@components/Select/SelectModeOfTransport';
import SelectLocation from '@components/Select/SelectLocation';
import QuickCreate from '@components/QuickCreate';
import PartyForm from '@components/QuickCreate/Forms/PartyForm';
import { get } from 'lodash';

function PurchaseOrderV1({
  onSubmitAction,
  onLoading,
  onSuccess,
  currentTab,
}) {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState(null);

  const { loading } = useCreateUpdateRecord({
    formData,
    mutation: CREATE_PURCHASE_ORDER,
    callback: () => onSuccess(),
  });

  const initialValues = {
    PONumber: '',
    PODate: '',
    POStatus: 'OPEN',
    POType: 'Manual',
    Customer_Party_Fk: null,
    Supplier_Party_Fk: null,
    Shipper_Party_Fk: null,
    Consignee_Party_Fk: null,
    ModeOfTransport_Fk: null,
    PortOfLoading_Location_Fk: null,
    PortOfDischarge_Location_Fk: null,
    FinalDestination_Location_Fk: null,
    ProjectAFECode: '',
    HarmonizedCode: '',
    CustomerReference: '',
    SupplierReference: '',
    ShipperReference: '',
    ConsigneeReference: '',
    PaymentTerm_Fk: 1,
    FreightTerm: 'Prepaid',
    RequiredDate: '',
    PlannedPickupDate: '',
    ActualPickupDate: '',
    POReceivedDate: '',
    FirstReceivedDate: '',
    LastReceivedDate: '',
    CompletionDate: '',
    ReferenceText1: '',
    ReferenceText2: '',
    ReferenceText3: '',
    Remarks: '',
  };

  const handleOnFinish = useCallback((values) => {
    const newData = {
      ...values,
      PODate: dateToString(values.PODate),
      RequiredDate: dateToString(values.RequiredDate),
      PlannedPickupDate: dateToString(values.PlannedPickupDate),
      ActualPickupDate: dateToString(values.ActualPickupDate),
      POReceivedDate: dateToString(values.POReceivedDate),
      FirstReceivedDate: dateToString(values.FirstReceivedDate),
      LastReceivedDate: dateToString(values.LastReceivedDate),
      CompletionDate: dateToString(values.CompletionDate),
      Customer_Party_Fk: get(values, 'Customer_Party_Fk.value', values.Customer_Party_Fk),
    };

    setFormData(newData);
  }, []);

  useEffect(() => {
    onSubmitAction(() => () => form.submit());
  }, []);

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  useEffect(() => {
    onSubmitAction(() => () => form.submit());
  }, [loading, currentTab]);

  return (
    <ContentStyled style={{ margin: 12 }}>
      <CardStyled style={{ backgroundColor: '#fcfcfc' }}>
        <FormStyled
          form={form}
          initialValues={initialValues}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleOnFinish}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="P.O. Number"
                name="PONumber"
                rules={[{ required: true, message: 'Required' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="P.O. Date"
                name="PODate"
                rules={[{ required: true, message: 'Required' }]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                defaultValue="OPEN"
                label="P.O. Status"
                name="POStatus"
                rules={[{ required: true, message: 'Required' }]}
              >
                <Select
                  defaultValue="OPEN"
                  disabled
                >
                  <Select.Option value="OPEN">OPEN</Select.Option>
                  <Select.Option value="COMPLETE">COMPLETE</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="P.O. Type"
                name="POType"
                rules={[{ required: true, message: 'Required' }]}
              >
                <Select
                  defaultValue="Manual"
                  disabled
                >
                  <Select.Option value="Manual">Manual</Select.Option>
                  <Select.Option value="EDI">EDI</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Customer"
                name="Customer_Party_Fk"
                rules={[{ required: true, message: 'Required' }]}
              >
                <QuickCreate
                  form={form}
                  tooltipTitle="Add New Customer"
                  modalTitle="Add Customer"
                  FormComponent={PartyForm}
                  formComponentProps={{
                    parentPartyFilterKey: 'Is_Customer',
                  }}
                  parseDropdownValue={(result) => result && {
                    label: `[${result.Code}] ${result.Name}`,
                    value: result.Id,
                  }}
                >
                  {(formItemProps) => (
                    <SelectParty
                      {...formItemProps}
                      customFilter={(data) => data.filter((d) => d.Is_Customer === 1)}
                    />
                  )}
                </QuickCreate>
              </Form.Item>
              <Form.Item
                label="Shipper"
                name="Shipper_Party_Fk"
                rules={[{ required: true, message: 'Required' }]}
              >
                <QuickCreate
                  form={form}
                  tooltipTitle="Add New Shipper"
                  modalTitle="Add Shipper"
                  FormComponent={PartyForm}
                  formComponentProps={{
                    parentPartyFilterKey: 'Is_Shipper',
                  }}
                  parseDropdownValue={(result) => result && {
                    label: `[${result.Code}] ${result.Name}`,
                    value: result.Id,
                  }}
                >
                  {(formItemProps) => (
                    <SelectParty
                      {...formItemProps}
                      customFilter={(data) => data.filter((d) => d.Is_Shipper === 1)}
                    />
                  )}
                </QuickCreate>
              </Form.Item>
              <Form.Item
                label="Supplier"
                name="Supplier_Party_Fk"
                rules={[{ required: true, message: 'Required' }]}
              >
                <QuickCreate
                  form={form}
                  tooltipTitle="Add New Supplier"
                  modalTitle="Add Supplier"
                  FormComponent={PartyForm}
                  formComponentProps={{
                    parentPartyFilterKey: 'Is_Supplier',
                  }}
                  parseDropdownValue={(result) => result && {
                    label: `[${result.Code}] ${result.Name}`,
                    value: result.Id,
                  }}
                >
                  {(formItemProps) => (
                    <SelectParty
                      {...formItemProps}
                      customFilter={(data) => data.filter((d) => d.Is_Supplier === 1)}
                    />
                  )}
                </QuickCreate>
              </Form.Item>
              <Form.Item
                label="Consignee"
                name="Consignee_Party_Fk"
                rules={[{ required: true, message: 'Required' }]}
              >
                <QuickCreate
                  form={form}
                  tooltipTitle="Add New Consignee"
                  modalTitle="Add Consignee"
                  FormComponent={PartyForm}
                  formComponentProps={{
                    parentPartyFilterKey: 'Is_Consignee',
                  }}
                  parseDropdownValue={(result) => result && {
                    label: `[${result.Code}] ${result.Name}`,
                    value: result.Id,
                  }}
                >
                  {(formItemProps) => (
                    <SelectParty
                      {...formItemProps}
                      customFilter={(data) => data.filter((d) => d.Is_Consignee === 1)}
                    />
                  )}
                </QuickCreate>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Mode of Transport"
                name="ModeOfTransport_Fk"
                rules={[{ required: true, message: 'Required' }]}
              >
                <SelectModeOfTransport />
              </Form.Item>
              <Form.Item
                label="Port of Loading"
                name="PortOfLoading_Location_Fk"
                rules={[{ required: true, message: 'Required' }]}
              >
                <SelectLocation
                  placeholder="Select a Location"
                />
              </Form.Item>
              <Form.Item
                label="Port of Discharge"
                name="PortOfDischarge_Location_Fk"
                rules={[{ required: true, message: 'Required' }]}
              >
                <SelectLocation
                  placeholder="Select a Location"
                />
              </Form.Item>
              <Form.Item
                label="Final Destination"
                name="FinalDestination_Location_Fk"
                rules={[{ required: true, message: 'Required' }]}
              >
                <SelectLocation
                  placeholder="Select a Location"
                />
              </Form.Item>
              <Form.Item
                label="Project AFE Code"
                name="ProjectAFECode"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Harmonized Code"
                name="HarmonizedCode"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Customer Reference"
                name="CustomerReference"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Supplier Reference"
                name="SupplierReference"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Shipper Reference"
                name="ShipperReference"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Consignee Reference"
                name="ConsigneeReference"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Freight Term"
                name="FreightTerm"
              >
                <Radio.Group>
                  <Radio value="Prepaid">Prepaid</Radio>
                  <Radio value="Collect">Collect</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Required Date"
                name="RequiredDate"
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label="Planned Pick Up Date"
                name="PlannedPickupDate"
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label="Actual Pick Up Date"
                name="ActualPickupDate"
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label="P.O. Received Date"
                name="POReceivedDate"
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label="First Received Date"
                name="FirstReceivedDate"
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label="Last Received Date"
                name="LastReceivedDate"
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label="P.O. Completion Date"
                name="CompletionDate"
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label="Payment Term"
                name="PaymentTerm_Fk"
                rules={[{ required: true, message: 'Required' }]}
              >
                <Select defaultValue={1}>
                  <Select.Option value={1}>15 Days</Select.Option>
                  <Select.Option value={2}>30 Days</Select.Option>
                  <Select.Option value={3}>60 Days</Select.Option>
                  <Select.Option value={4}>90 Days</Select.Option>
                  <Select.Option value={5}>CASH</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Divider />
            <Col span={12}>
              <Form.Item
                label="Reference Text 1"
                name="ReferenceText1"
              >
                <Input.TextArea rows={2} />
              </Form.Item>
              <Form.Item
                label="Reference Text 2"
                name="ReferenceText2"
              >
                <Input.TextArea rows={2} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Reference Text 3"
                name="ReferenceText3"
              >
                <Input.TextArea rows={2} />
              </Form.Item>
              <Form.Item
                label="Remarks"
                name="Remarks"
              >
                <Input.TextArea rows={2} />
              </Form.Item>
            </Col>
          </Row>
        </FormStyled>
      </CardStyled>
    </ContentStyled>
  );
}

export default memo(PurchaseOrderV1);
