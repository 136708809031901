import { memo } from 'react';
import { Content } from 'antd/es/layout/layout';
import { Skeleton } from 'antd';

function SectionLoading({
  loading,
  children,
}) {
  if (loading) {
    return (
      <Content style={{ padding: 16 }}>
        <Skeleton active />
      </Content>
    );
  }

  return children;
}

export default memo(SectionLoading);
