import { gql } from '@apollo/client';

const GET_PO_LINE_ITEM_BY_PART_ID = gql`
query GetPOLineItemByPartId($Id: Int!) {
    getPOLineItemByPartId(Id: $Id) {
      code
      success
      message
      result {
        Id
        LineNumber
        LineStatus
        Supplier_Party_Fk
        PartNumber_Part_Fk
        UnitOfMeasure_Fk
        ExpectedQuantity
        ReceivedQuantity
        AvailableQuantity
        Currency_Fk
        UnitPrice
        TotalPrice
        CustomsUnitPrice
        CustomsTotalPrice
        ModeOfTransport_Fk
        Eccn
        FreeBonded
        Osd
        PortOfLoading_ID
        Commodity_Fk
        UNClassification
        CountryOfOrigin_Country_Fk
        FinalDestination_Location_Fk
        AdditionalDescription
        Is_Hazardous
        CreatedBy
        CreatedDate
        UpdatedBy
        UpdateDate

        Supplier {
          Id
          Name
          Code
        }
        Part {
          Id
          Part_Number
          Part_Description
        }
        UnitOfMeasure {
          Id
          Code
          Description
        }
        Currency {
          Id
          Code
          Description
        }
        ModeOfTransport {
          Id
          Code
          Description
        }
        PortOfLoading {
          Id
          Name
          City_Code
        }
        FinalDestination {
          Id
          Name
          City_Code
        }
        Commodity {
          Commodity
          Descrip_1
        }
      }
    }
  }
`;

export default GET_PO_LINE_ITEM_BY_PART_ID;
