import React, {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Button,
  Checkbox,
  Col, List, Radio, Row, Skeleton, Space, Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { get } from 'lodash';
import { saveAs } from 'file-saver';
import printJS from 'print-js';
import { PDFViewer } from '@react-pdf/renderer';
import { useLazyQuery, useMutation } from '@apollo/client';

import useAuthStore from '@store/authStore';

import GET_SCAN_ID_BY_JOB_NUMBER from '@graphql/TH_getScanIdByJobNumber';
import GET_PRINT_FINAL_GOODS_RECEIPT_NOTE from '@graphql/getPrintableFinalGoodsReceiptNote';
import PRINT_FINAL_GOODS_RECEIPT_NOTE from '@graphql/TH_printFinalGoodsReceiptNote';

import { CardStyled } from '@components/Styles/CardStyles';
import Barcode from '../Components/Barcode';
import PrintGRNLabels from '../Components/PrintGRNLabels';
import PrintFinalGoods from '../Components/PrintFinalGoods';

function InboundJobPrint({
  record,
  currentTab,
  onSetModalActions,
}) {
  const [user] = useAuthStore((state) => [state.user]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [useParentLogo, setUseParentLogo] = useState(false);
  const [useCustomerLogo, setUserCustomerLogo] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [barcodes, setBarcodes] = useState({});
  const [printBlob, setPrintBlob] = useState();
  const [handlePrintOrDownload] = useMutation(PRINT_FINAL_GOODS_RECEIPT_NOTE);

  const cardRef = useRef();

  const [getScanIds, { data, loading: getScanIdByJobNumberLoading }] = useLazyQuery(GET_SCAN_ID_BY_JOB_NUMBER, {
    variables: { JobNumber: record?.JobNumber },
  });

  const [getFinalGoodsReceiptNote, { data: dataFinalGoods, loading: loadingFinalGoods }] = useLazyQuery(GET_PRINT_FINAL_GOODS_RECEIPT_NOTE, {
    variables: { Id: record?.Id },
  });

  const scanIds = get(data, 'results', []);
  const finalGoodsReceiptNote = get(dataFinalGoods, 'result', {});

  const handleBarcodeGenerated = useCallback((barcodeObj, barcodeRef, canvasRef) => {
    setBarcodes((prevState) => ({
      ...prevState,
      [barcodeObj.value]: barcodeObj.dataURL,
    }));

    if (barcodeRef) {
      barcodeRef.current.remove();
    }
    if (canvasRef) {
      canvasRef.current.remove();
    }
  }, []);

  const handleRadioChange = useCallback((e) => {
    setSelectedValue(e.target.value);
  }, []);

  const handleChangeUseParent = useCallback((e) => {
    setUseParentLogo(e.target.checked);
    setUserCustomerLogo(false);
  }, []);

  const handleChangeUseCustomer = useCallback((e) => {
    setUserCustomerLogo(e.target.checked);
    setUseParentLogo(false);
  }, []);

  const resizePdf = useCallback(() => {
    if (!cardRef) return;

    const { clientWidth, clientHeight } = cardRef.current;
    setDimensions({ width: clientWidth, height: clientHeight });
  }, []);

  const handleLoadPrintFile = useCallback((blob) => {
    setPrintBlob(blob);
  }, []);

  const handleFileDownload = useCallback((selectedValue) => {
    let filename = '';
    switch (selectedValue) {
      case 'Draft GRN Label(s)':
        filename = `Draft GRN ${record?.JobNumber}.pdf`;
        break;
      case 'Final GRN Label(s)':
        filename = `Final GRN ${record?.JobNumber}.pdf`;
        break;
      case 'Final Goods Received Note':
        filename = `Final Goods Receipt Note ${record?.JobNumber}.pdf`;
        try {
          handlePrintOrDownload({
            variables: {
              warehouseInId: record?.Id,
            },
          });
        } catch (error) {
          // console.error(error);
        }
        break;
      default:
        break;
    }

    if (printBlob?.blob) {
      saveAs(printBlob?.blob, filename);
    }
  }, [printBlob?.blob, record?.JobNumber]);

  const handleFilePrint = useCallback(() => {
    if (printBlob?.blob) {
      const blobURL = URL.createObjectURL(printBlob?.blob);
      printJS(blobURL);
    }

    try {
      handlePrintOrDownload({
        variables: {
          warehouseInId: record?.Id,
        },
      });
    } catch (error) {
      // console.error(error);
    }
  }, [printBlob?.blob]);

  const printTypes = [
    'Draft GRN Label(s)',
    'Final GRN Label(s)',
    'Final Goods Received Note',
  ];

  const showLabelDocument = ['Draft GRN Label(s)', 'Final GRN Label(s)'].includes(selectedValue) && Object.keys(barcodes).length > 0;
  const showFinalGoods = selectedValue === 'Final Goods Received Note' && finalGoodsReceiptNote;

  useEffect(() => {
    switch (selectedValue) {
      case 'Draft GRN Label(s)':
      case 'Final GRN Label(s)':
        getScanIds();
        break;
      case 'Final Goods Received Note':
        getFinalGoodsReceiptNote();
        break;
      default:
        break;
    }
  }, [selectedValue]);

  useEffect(() => {
    resizePdf();
  }, [cardRef]);

  useEffect(() => {
    if (currentTab === 'printDocument') {
      onSetModalActions([
        <Space key={1}>&nbsp;</Space>,
        <Space key={2}>
          <Button onClick={() => handleFilePrint()} disabled={!printBlob}>
            Print
          </Button>
          <Button onClick={() => handleFileDownload(selectedValue)} disabled={!printBlob}>
            Download
          </Button>
        </Space>,
      ]);
    }
  }, [selectedValue, currentTab, printBlob]);

  useEffect(() => {
    const handleResize = () => {
      resizePdf();
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Content style={{ padding: 16 }}>
      <Row gutter={16}>
        <Col xs={8}>
          <List
            header={<Typography.Text strong>Print Types</Typography.Text>}
            size="small"
            bordered
            dataSource={printTypes}
            renderItem={(item) => (
              <List.Item>
                <Space direction="vertical">
                  <Radio
                    value={item}
                    checked={selectedValue === item}
                    onChange={handleRadioChange}
                  >
                    {item}
                  </Radio>
                  {
                    item === 'Final Goods Received Note' && (
                      <>
                        <Checkbox onChange={handleChangeUseCustomer} checked={useCustomerLogo} style={{ marginLeft: 24 }}>
                          Use Customer Logo
                        </Checkbox>
                        <Checkbox onChange={handleChangeUseParent} checked={useParentLogo} style={{ marginLeft: 24 }}>
                          Use Parent Logo
                        </Checkbox>
                      </>
                    )
                  }

                </Space>
              </List.Item>
            )}
          />
        </Col>
        <Col xs={16}>
          <CardStyled ref={cardRef}>
            <Typography.Title level={4}>Preview</Typography.Title>
            {scanIds.map(({ Id, ScanId }) => (
              <Barcode key={Id} value={ScanId} onBarcodeGenerated={handleBarcodeGenerated} />
            ))}
            <Skeleton loading={getScanIdByJobNumberLoading || loadingFinalGoods} active>
              <PDFViewer width={dimensions.width - 32} height={600} showToolbar={false}>
                {showLabelDocument && (
                  <PrintGRNLabels
                    barcodes={barcodes}
                    list={scanIds}
                    inboundJob={record}
                    isDraft={selectedValue === 'Draft GRN Label(s)'}
                    onLoadPrintFile={handleLoadPrintFile}
                  />
                )}
                {showFinalGoods && (
                  <PrintFinalGoods
                    data={finalGoodsReceiptNote}
                    useParentLogo={useParentLogo}
                    useCustomerLogo={useCustomerLogo}
                    user={user}
                    onLoadPrintFile={handleLoadPrintFile}
                  />
                )}
              </PDFViewer>
            </Skeleton>
          </CardStyled>
        </Col>
      </Row>
    </Content>
  );
}

export default memo(InboundJobPrint);
