import { gql } from '@apollo/client';

export const GET_ALL_OUTBOUND_JOBS = gql`
    query GetAllWarehouseOut ($limit: Int, $offset: Int, $searchText: String) {
        getAllWarehouseOut (limit: $limit, offset: $offset, searchText: $searchText ) {
            code
            success
            message
            count
            results {
                Id
                JobNumber
                JobDate
                JobStatus
                FreightJobNumber
                Customer_Party_Fk
                CustomerParty {
                    Id
                    Name
                    Code
                }
                ModeOfTransport_Fk
                Remarks
                CreatedBy
                CreatedDate
                UpdatedBy
                UpdateDate
            }
        }
    }`;

export default GET_ALL_OUTBOUND_JOBS;
