import { gql } from '@apollo/client';

const GET_LOAD_PLAN_BY_ID = gql`
    query GetLoadPlanById($Id: Int) {
        getLoadPlanById(LoadPlanId: $Id) {
            code
            success
            message
            result {
                Id
                LoadPlanId
                ContainerId
                LoadedDate
            }
        }
    }
`;

export default GET_LOAD_PLAN_BY_ID;
