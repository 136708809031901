import { gql } from '@apollo/client';

export const ATTACH_PO_LINE_ITEM_TO_SCAN_ID = gql`
    mutation AttachPOLineItemToScanId($data: PurchaseOrderPartLocationDetailInput!) {
        attachPOLineItemToScanId(data: $data) {
            code
            success
            message
        }
    }`;

export default ATTACH_PO_LINE_ITEM_TO_SCAN_ID;
