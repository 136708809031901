const ONHAND_REPORT = [
  { title: 'Job Number', key: 'JobNumber' },
  { title: 'Job Date', key: 'JobDate' },
  { title: 'Job Status', key: 'JobStatus' },
  { title: 'Person In Charge', key: 'PersonInCharge' },
  { title: 'PO Number', key: 'PONumber' },
  { title: 'PO Date', key: 'PODate' },
  { title: 'Mode Of Transport', key: 'ModeOfTransport' },
  { title: 'Customer Name', key: 'CustomerName' },
  { title: 'Shipper Name', key: 'ShipperName' },
  { title: 'Supplier Name', key: 'SupplierName' },
  { title: 'Consignee Name', key: 'ConsigneeName' },
  { title: 'Port Of Loading', key: 'PortOfLoading' },
  { title: 'Port Of Discharge', key: 'PortOfDischarge' },
  { title: 'Final Destination', key: 'FinalDestination' },
  { title: 'Label Series', key: 'LabelSeries' },
  { title: 'Scan ID', key: 'ScanId' },
  { title: 'Line Number', key: 'LineNumber' },
  { title: 'Received Quantity', key: 'ReceivedQuantity' },
  { title: 'Length (Inch)', key: 'LengthInch' },
  { title: 'Width (Inch)', key: 'WidthInch' },
  { title: 'Height (Inch)', key: 'HeightInch' },
  { title: 'Length (Cm)', key: 'LengthCm' },
  { title: 'Width (Cm)', key: 'WidthCm' },
  { title: 'Height (Cm)', key: 'HeightCm' },
  { title: 'Weight (Lbs)', key: 'WeightLbs' },
  { title: 'Weight (Kg)', key: 'WeightKg' },
  { title: 'Volume (Cbm)', key: 'VolumeCbm' },
  { title: 'Volume (Cft)', key: 'VolumeCft' },
  { title: 'Warehouse Code', key: 'WarehouseCode' },
  { title: 'Storage Location Code', key: 'StorageLocationCode' },
  { title: 'Arrival Date', key: 'ArrivalDate' },
  { title: 'Putaway Date', key: 'PutawayDate' },
  { title: 'Confirmed Date', key: 'ConfirmedDate' },
  { title: 'First MR Printed Date', key: 'FirstMrPrintedDate' },
  { title: 'Outbound Job Number', key: 'OutboundJobNumber' },
  { title: 'Authorized Date', key: 'AuthorizedDate' },
  { title: 'Packed Date', key: 'PackedDate' },
  { title: 'Loaded Date', key: 'LoadedDate' },
  { title: 'Dispatched Date', key: 'DispatchedDate' },
  { title: 'Overpack ID', key: 'OverpackId' },
];

const ONHAND_REPORT_KERRY = [
  { title: 'Package Number', key: 'PackageNumber' },
  { title: 'Scan Id', key: 'ScanId' },
  { title: 'MR Number', key: 'MR_Number' },
  { title: 'Job Date', key: 'JobDate' },
  { title: 'AFS Reception Date', key: 'AFS_ReceptionDate' },
  { title: 'Person In Charge', key: 'PersonInCharge' },
  { title: 'AFS Number', key: 'AFS_Number' },
  { title: 'Shipping File', key: 'ShippingFile' },
  { title: 'Destination Vessel', key: 'DestinationVessel' },
  { title: 'PO Number', key: 'PONumber' },
  { title: 'Supplier CIPL Invoice Reference', key: 'Supplier_CIPLInvoice_Reference' },
  { title: 'Mode Of Transport', key: 'ModeOfTransport' },
  { title: 'Purchasing Company', key: 'PurchasingCompany' },
  { title: 'Supplier Name', key: 'SupplierName' },
  { title: 'Consignee Name', key: 'ConsigneeName' },
  { title: 'Part Number', key: 'Part_Number' },
  { title: 'Part Description', key: 'Part_Description' },
  { title: 'Unit Price', key: 'UnitPrice' },
  { title: 'Total Price', key: 'TotalPrice' },
  { title: 'HS Code Schedule B', key: 'HSCode_ScheduleB' },
  { title: 'Country Of Origin', key: 'CountryOfOrigin' },
  { title: 'Hazmat', key: 'Hazmat' },
  { title: 'UOM', key: 'UOM' },
  { title: 'Additional Description', key: 'AdditionalDescription' },
  { title: 'OS&D', key: 'OS&D' },
  { title: 'Received Quantity', key: 'ReceivedQuantity' },
  { title: 'Length (Inch)', key: 'LengthInch' },
  { title: 'Width (Inch)', key: 'WidthInch' },
  { title: 'Height (Inch)', key: 'HeightInch' },
  { title: 'Length (Cm)', key: 'LengthCm' },
  { title: 'Width (Cm)', key: 'WidthCm' },
  { title: 'Height (Cm)', key: 'HeightCm' },
  { title: 'Weight (Lbs)', key: 'WeightLbs' },
  { title: 'Weight (Kg)', key: 'WeightKg' },
  { title: 'Volume (Cbm)', key: 'VolumeCbm' },
  { title: 'Volume (Cft)', key: 'VolumeCft' },
  { title: 'Warehouse Code', key: 'WarehouseCode' },
  { title: 'Storage Location Code', key: 'StorageLocationCode' },
  { title: 'Arrival Date', key: 'ArrivalDate' },
  { title: 'MR Issued Date', key: 'MRIssuedDate' },
  { title: 'KPL Job File', key: 'KPLJobFile' },
  { title: 'Hazardous', key: 'Hazardous' },
  { title: 'Packing Status', key: 'PackingStatus' },
];

const DISPATCH_REPORT_KERRY = [
  { title: 'Package Number', key: 'PackageNumber' },
  { title: 'Scan Id', key: 'ScanId' },
  { title: 'MR Number', key: 'MR_Number' },
  { title: 'Job Date', key: 'JobDate' },
  { title: 'AFS Reception Date', key: 'AFS_ReceptionDate' },
  { title: 'Person In Charge', key: 'PersonInCharge' },
  { title: 'AFS Number', key: 'AFS_Number' },
  { title: 'Shipping File', key: 'ShippingFile' },
  { title: 'Destination Vessel', key: 'DestinationVessel' },
  { title: 'PO Number', key: 'PONumber' },
  { title: 'Supplier CIPL Invoice Reference', key: 'Supplier_CIPLInvoice_Reference' },
  { title: 'Mode Of Transport', key: 'ModeOfTransport' },
  { title: 'Purchasing Company', key: 'PurchasingCompany' },
  { title: 'Supplier Name', key: 'SupplierName' },
  { title: 'Consignee Name', key: 'ConsigneeName' },
  { title: 'Part Number', key: 'Part_Number' },
  { title: 'Part Description', key: 'Part_Description' },
  { title: 'Unit Price', key: 'UnitPrice' },
  { title: 'Total Price', key: 'TotalPrice' },
  { title: 'HS Code Schedule B', key: 'HSCode_ScheduleB' },
  { title: 'Country Of Origin', key: 'CountryOfOrigin' },
  { title: 'Hazmat', key: 'Hazmat' },
  { title: 'UOM', key: 'UOM' },
  { title: 'Additional Description', key: 'AdditionalDescription' },
  { title: 'OS&D', key: 'OS&D' },
  { title: 'Received Quantity', key: 'ReceivedQuantity' },
  { title: 'Length (Inch)', key: 'LengthInch' },
  { title: 'Width (Inch)', key: 'WidthInch' },
  { title: 'Height (Inch)', key: 'HeightInch' },
  { title: 'Length (Cm)', key: 'LengthCm' },
  { title: 'Width (Cm)', key: 'WidthCm' },
  { title: 'Height (Cm)', key: 'HeightCm' },
  { title: 'Weight (Lbs)', key: 'WeightLbs' },
  { title: 'Weight (Kg)', key: 'WeightKg' },
  { title: 'Volume (Cbm)', key: 'VolumeCbm' },
  { title: 'Volume (Cft)', key: 'VolumeCft' },
  { title: 'Warehouse Code', key: 'WarehouseCode' },
  { title: 'Storage Location Code', key: 'StorageLocationCode' },
  { title: 'Arrival Date', key: 'ArrivalDate' },
  { title: 'MR Issued Date', key: 'MRIssuedDate' },
  { title: 'KPL Job File', key: 'KPLJobFile' },
  { title: 'Hazardous', key: 'Hazardous' },
  { title: 'Packing Status', key: 'PackingStatus' },
  { title: 'Dispatched Date', key: 'DispatchedDate' },
];

const SCAN_ID_ONHAND_DISPATCH_KERRY = [
  { title: 'Package Number', key: 'PackageNumber' },
  { title: 'Scan Id', key: 'ScanId' },
  { title: 'MR Number', key: 'MR_Number' },
  { title: 'Job Date', key: 'JobDate' },
  { title: 'AFS Reception Date', key: 'AFS_ReceptionDate' },
  { title: 'Person In Charge', key: 'PersonInCharge' },
  { title: 'AFS Number', key: 'AFS_Number' },
  { title: 'Shipping File', key: 'ShippingFile' },
  { title: 'Destination Vessel', key: 'DestinationVessel' },
  { title: 'PO Number', key: 'PONumber' },
  { title: 'Supplier CIPL Invoice Reference', key: 'Supplier_CIPLInvoice_Reference' },
  { title: 'Mode Of Transport', key: 'ModeOfTransport' },
  { title: 'Purchasing Company', key: 'PurchasingCompany' },
  { title: 'Supplier Name', key: 'SupplierName' },
  { title: 'Consignee Name', key: 'ConsigneeName' },
  { title: 'Total Price', key: 'TotalPrice' },
  { title: 'UOM', key: 'UOM' },
  { title: 'OS&D', key: 'OS&D' },
  { title: 'Length (Inch)', key: 'LengthInch' },
  { title: 'Width (Inch)', key: 'WidthInch' },
  { title: 'Height (Inch)', key: 'HeightInch' },
  { title: 'Length (Cm)', key: 'LengthCm' },
  { title: 'Width (Cm)', key: 'WidthCm' },
  { title: 'Height (Cm)', key: 'HeightCm' },
  { title: 'Weight (Lbs)', key: 'WeightLbs' },
  { title: 'Weight (Kg)', key: 'WeightKg' },
  { title: 'Volume (Cbm)', key: 'VolumeCbm' },
  { title: 'Volume (Cft)', key: 'VolumeCft' },
  { title: 'Warehouse Code', key: 'WarehouseCode' },
  { title: 'Storage Location Code', key: 'StorageLocationCode' },
  { title: 'Arrival Date', key: 'ArrivalDate' },
  { title: 'MR Issued Date', key: 'MRIssuedDate' },
  { title: 'KPL Job File', key: 'KPLJobFile' },
  { title: 'Dispatched Date', key: 'DispatchedDate' },
];

export {
  ONHAND_REPORT, ONHAND_REPORT_KERRY, DISPATCH_REPORT_KERRY, SCAN_ID_ONHAND_DISPATCH_KERRY,
};
