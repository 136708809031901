import { gql } from '@apollo/client';

export const ADD_TO_OVERPACK_SCAN_ID = gql`
    mutation AddToOverPack($data: AddToOverPackInput!) {
        addToOverPack(data: $data) {
            code
            success
            message
        }
    }`;

export default ADD_TO_OVERPACK_SCAN_ID;
