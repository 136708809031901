import { memo } from 'react';

import GET_ALL_COMMODITY from '@graphql/getAllCommodity';

import SelectLazy from '../SelectLazy';

function SelectCommodity(props) {
  const renderOptions = (data) => (data || []).map((d) => ({
    value: d.Commodity,
    label: `[${d.Commodity}] ${d.Descrip_1}`,
  }));

  const customFilter = (data) => data
    .sort((a, b) => a.Descrip_1.localeCompare(b.Descrip_1));

  return (
    <SelectLazy
      {...props}
      placeholder="Select Code"
      customRender={renderOptions}
      customFilter={customFilter}
      query={GET_ALL_COMMODITY}
    />
  );
}

export default memo(SelectCommodity);
