import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import {
  Button,
  Col, DatePicker, Form, Row, Space,
  Spin,
  Tooltip,
} from 'antd';
import styled from 'styled-components';
import { get } from 'lodash';
import {
  DeleteFilled, EditFilled, LoadingOutlined, PlusOutlined,
} from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';

import GET_PO_OSD_REASONS_BY_PO_DETAIL_ID from '@graphql/getPurchaseOrderOSDReasonsByPurchaseOrderDetailId';
import CREATE_PURCHASE_ORDER_OSD_REASON from '@graphql/createPurchaseOrderOSDReason';
import DELETE_PURCHASE_ORDER_OSD_REASON from '@graphql/deleteOSDReason';
import UPDATE_PURCHASE_ORDER_OSD_REASON from '@graphql/updatePurchaseOrderOSDReason';

import { dateToString, timestampToDate } from '@utils/helpers/date';
import useCreateUpdateRecord from '@hooks/useCreateUpdateRecord';

import { ContentStyled } from '@components/Styles/Global';
import { FormStyled } from '@components/Styles/FormStyles';
import SelectOsd from '@components/Select/SelectOsd';
import { TableSmall } from '@components/Styles/TableStyles';

const SpaceStyled = styled(Space)`
  width: 100%;

  .ant-space-item:first-child {
    width: 100%;
  }
`;

function SelectOSDAdd(props) {
  const { onAdd, loading, ...selectProps } = props;
  return (
    <SpaceStyled>
      <SelectOsd {...selectProps} />
      <Tooltip title="Add OSD Reason">
        <Button icon={<PlusOutlined />} onClick={onAdd} loading={loading} />
      </Tooltip>
    </SpaceStyled>
  );
}

function OsdReasonList({ detailId }) {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState(null);
  const [formDataDelete, setFormDataDelete] = useState(null);
  const [formDataUpdate, setFormDataUpdate] = useState(null);
  const [editableRowId, setEditableRowId] = useState(null);

  const { loading: osdLoading } = useCreateUpdateRecord({
    formData,
    mutation: !editableRowId ? CREATE_PURCHASE_ORDER_OSD_REASON : UPDATE_PURCHASE_ORDER_OSD_REASON,
    variables: Boolean(editableRowId) && { id: editableRowId },
    callback: () => {
      getOsdReasons();

      if (editableRowId) {
        setEditableRowId(null);
        setFormDataUpdate(null);
      }
    },
  });

  useCreateUpdateRecord({
    formData: formDataDelete,
    variables: { id: formDataDelete?.id },
    mutation: DELETE_PURCHASE_ORDER_OSD_REASON,
    isDelete: true,
    callback: () => {
      getOsdReasons();
    },
  });

  const [getOsdReasons, { data, loading: osdReasonsLoading }] = useLazyQuery(GET_PO_OSD_REASONS_BY_PO_DETAIL_ID, {
    variables: { Id: detailId },
    fetchPolicy: 'network-only',
  });

  const osdReasons = get(data, 'results', []);

  const handleAddOsd = useCallback(() => {
    const values = form.getFieldValue();
    setFormData({
      PurchaseOrderDetail_Fk: detailId,
      Osd_Fk: values.Osd,
    });
  }, [detailId]);

  const handleDeleteOsd = useCallback((id) => {
    setFormDataDelete({ id });
  }, []);

  const handleUpdateOsd = useCallback((id) => {
    setEditableRowId(id);
  }, []);

  const handleCancelUpdate = useCallback(() => {
    setEditableRowId(null);
  }, []);

  const handleChangeResolveDate = useCallback((value) => {
    setFormDataUpdate({
      ResolvedDate: dateToString(value),
    });
  }, []);

  const handleSaveUpdate = useCallback(() => {
    setFormData({ ...formDataUpdate });
  }, [formDataUpdate]);

  useEffect(() => {
    if (detailId) {
      getOsdReasons();
    }
  }, [detailId]);

  const osdColumns = [
    {
      title: 'OSD Reason',
      dataIndex: 'Osd',
      key: 'Osd',
      render: (_, record) => (
        <span style={{ userSelect: 'text' }}>
          {`[${record?.Osd?.Id}] ${record?.Osd?.Osd_Reason}`}
        </span>
      ),
    },
    {
      title: 'Created Date',
      dataIndex: 'OsdDate',
      key: 'OsdDate',
      render: (text) => timestampToDate(text, true),
    },
    {
      title: 'Resolved Date',
      dataIndex: 'ResolvedDate',
      key: 'ResolvedDate',
      render: (text, record) => {
        if (editableRowId === record?.Id) {
          return (<DatePicker onChange={handleChangeResolveDate} />);
        }
        return timestampToDate(text, true);
      },
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 140,
      render: (_, record) => {
        if (editableRowId === record?.Id) {
          if (osdLoading) {
            return <Spin indicator={<LoadingOutlined spin />} />;
          }
          return (
            <Space>
              <a onClick={() => handleSaveUpdate(record?.Id)}>
                <EditFilled />
                {' '}
                Save
              </a>
              <div style={{ width: 4 }}>&nbsp;</div>
              <a onClick={() => handleCancelUpdate()}>
                <DeleteFilled />
                {' '}
                Cancel
              </a>
            </Space>
          );
        }
        return (
          <Space>
            <a onClick={() => handleUpdateOsd(record?.Id)}>
              <EditFilled />
              {' '}
              Update
            </a>
            <div style={{ width: 4 }}>&nbsp;</div>
            <a onClick={() => handleDeleteOsd(record?.Id)}>
              <DeleteFilled />
              {' '}
              Delete
            </a>
          </Space>
        );
      },
    },
  ];

  return (
    <ContentStyled style={{ margin: 12 }}>
      <FormStyled
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="OS&D Reason"
              name="Osd"
            >
              <SelectOSDAdd onAdd={handleAddOsd} loading={osdLoading && !editableRowId} />
            </Form.Item>
          </Col>
        </Row>
      </FormStyled>
      <TableSmall
        style={{ marginTop: 24 }}
        size="small"
        loading={osdReasonsLoading}
        dataSource={osdReasons}
        columns={osdColumns}
        pagination={false}
      />
    </ContentStyled>
  );
}

export default memo(OsdReasonList);
