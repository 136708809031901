import { memo } from 'react';

import GET_ALL_OSD_REASON from '@graphql/getallOsdReason';

import SelectLazy from '../SelectLazy';

function SelectOsd(props) {
  const renderOptions = (data) => (data || []).map((d) => ({
    value: d.Id,
    label: `[${d.Id}] ${d.Osd_Reason}`,
  }));

  return (
    <SelectLazy
      {...props}
      placeholder="Select OSD Reason"
      customRender={renderOptions}
      query={GET_ALL_OSD_REASON}
    />
  );
}

export default memo(SelectOsd);
