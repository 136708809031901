export const ACCOUNT_STATUS = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  RESET: 'RESET',
  DELETED: 'DELETED',
  LOCKED: 'LOCKED',
};

export const ACCOUNT_STATUS_COLORS = {
  ACTIVE: '#89E187',
  PENDING: '#EFA774',
  RESET: '#EBC12B',
  DELETED: '#FC4F4F',
  LOCKED: '#858585',
};
