import { Card } from 'antd';
import styled from 'styled-components';

export const CardStyled = styled(Card)`
  &.ant-card-bordered {
    border-color: #d9d9d9 !important;
    margin-bottom: 24px;
  }

  & .ant-card-body {
    padding: 16px;
  }
`;

export const CardLeftStyled = styled(CardStyled)`
  text-align: left;

  & .ant-card-body {
    height: 100%;
  }
`;
