import { gql } from '@apollo/client';

const GET_ALL_LOAD_PLAN = gql`
  query GetAllLoadPlans($WarehouseOutId: Int!) {
    getAllLoadPlans(WarehouseOutId: $WarehouseOutId) {
      code
      success
      message
      results {
        Id
        LoadPlanId
        ContainerId
      }
    }
  }
`;

export default GET_ALL_LOAD_PLAN;
