import {
  Button,
  Table,
  Tag,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import styled from 'styled-components';

export const TagStyled = styled(Tag)`
  border-radius: 32px !important;
  padding-left: 12px;
  padding-right: 12px;
`;

export const ContentOverlayBg = styled.div`
  background: ${({ $isSuperAdmin }) => ($isSuperAdmin
    ? 'linear-gradient(118.12deg, #FA902E -11.11%, #FAA556 86.14%)'
    : 'linear-gradient(118.12deg, rgb(68, 159, 224) -11.11%, rgb(12, 100, 154) 86.14%)')};
  height: 320px;
  width: 100%;
  position: absolute;
  top: 160px;
  z-index: 0;
  clip-path: polygon(0% 0%, 0% 100%, 320px 0%, 100% 0%);
`;

export const ContentCentered = styled(Content)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const ButtonRounded = styled(Button)`
    border-radius: 24px !important;
`;

export const TableStyled = styled(Table)`
    th.ant-table-cell {
      font-size: 12px;
      background: #fff !important;
    }

    td.ant-table-cell {
      vertical-align: top;
    }
`;

export const ContentStyled = styled(Content)`
  margin: 0px 25px 25px;
`;
