import {
  Table,
} from 'antd';
import React from 'react';
import { get } from 'lodash';
import { Content } from 'antd/es/layout/layout';
import styled from 'styled-components';

import { useQuery } from '@apollo/client';

import GET_ALL_LOCATION from '@graphql/getAllLocations';

const TableStyled = styled(Table)`
  table  {
    color: #666666;
    .ant-table-thead .ant-table-column-has-sorters:hover {
      background: #4c83a7;
    }
    .ant-table-thead > tr th {
      background: #22577A;
      color: #fff;
      padding: 12px !important;
      .anticon-caret-up.active, .anticon-caret-down.active {
        color: #fff;
      }
    }
    .ant-table-thead > tr th:first-child, .ant-table-thead > tr th:last-child {
      border-radius: 0 !important;
    }
    .ant-table-thead {
      .ant-table-column-has-sorters {
        background: #22577A;
      }
    }
    a {
      color: #22577A;
    }
  }
`;

function Location() {
  const { data } = useQuery(GET_ALL_LOCATION);
  const users = get(data, 'results', []);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'Id',
      key: 'Id',
      defaultSortOrder: 'none',
      render: (text) => (
        <a style={{ userSelect: 'text' }}>
          {text}
        </a>
      ),
    },
    {
      title: 'City Code',
      dataIndex: 'City_Code',
      key: 'City_Code',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Country Code',
      dataIndex: 'Country_Code',
      key: 'Country_Code',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',

    },
    {
      title: 'Function',
      dataIndex: 'Function',
      key: 'Function',

    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',

    },
    {
      title: 'Coordinates',
      dataIndex: 'Coordinates',
      key: 'Coordiantes',
    },
  ];

  return (
    <Content style={{ margin: '0px 25px 25px' }}>
      <TableStyled size="small" dataSource={users} columns={columns} pagination />
    </Content>
  );
}

export default Location;
