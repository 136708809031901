import { gql } from '@apollo/client';

export const LOAD_GOODS_TO_LOAD_PLAN = gql`
    mutation LoadGoods($data: LoadGoodsInput!) {
        loadGoods(data: $data) {
            code
            success
            message
        }
    }`;

export default LOAD_GOODS_TO_LOAD_PLAN;
