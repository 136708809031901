import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import {
  Button, Col, Divider, Row, Space, Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';

import GET_PICKED_SCAN_IDS from '@graphql/TH_getPickScanIdsByWarehouseOutAndCustomer';
import GET_AUTHORIZED_SCAN_IDS from '@graphql/TH_getAuthorizedScanIds';
import PICK_SCAN_ID from '@graphql/TH_pickScanId';
import UNPICK_SCAN_ID from '@graphql/TH_unpickScanId';

import useList from '@hooks/useList';
import useCreateUpdateRecord from '@hooks/useCreateUpdateRecord';
import { timestampToDate } from '@utils/helpers/date';

import { CardStyled } from '@components/Styles/CardStyles';
import { TableSmall, TableSubSmall } from '@components/Styles/TableStyles';
import { parseAndToFixed } from '@utils/helpers/helpers';

function OutboundJobPick({
  currentTab,
  onSetModalActions,
  record,
}) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowPickedKeys, setSelectedRowPickedKeys] = useState([]);
  const [formDataPick, setFormDataPick] = useState(null);
  const [formDataUnpick, setFormDataUnpick] = useState(null);

  const { loading: listAuthorizedLoading, data: authorizedScanIdList, refetch: refetchAuthorized } = useList({
    query: GET_AUTHORIZED_SCAN_IDS,
    variables: {
      CustomerPartyFk: record?.Customer_Party_Fk,
      WarehouseOutId: record?.Id,
    },
  });

  const { loading: listPickedLoading, data: pickedScanIdList, refetch: refetchAvailable } = useList({
    query: GET_PICKED_SCAN_IDS,
    variables: {
      CustomerPartyFk: record?.Customer_Party_Fk,
      WarehouseOutId: record?.Id,
    },
  });

  const { loading: loadingPick } = useCreateUpdateRecord({
    formData: formDataPick,
    mutation: PICK_SCAN_ID,
    callback: () => handleSuccess(),
  });

  const { loading: loadingUnpick } = useCreateUpdateRecord({
    formData: formDataUnpick,
    mutation: UNPICK_SCAN_ID,
    callback: () => handleSuccess(),
  });

  const handleSuccess = useCallback(() => {
    refetchAvailable();
    refetchAuthorized();
  }, [record?.Id]);

  useEffect(() => {
    if (currentTab === 'pick') {
      const isDisabledAllButton = selectedRowPickedKeys.length === 0 && selectedRowKeys.length === 0;
      onSetModalActions([
        <div key={1}>&nbsp;</div>,
        <Space key={2}>
          <Button type="default" onClick={handlePick} disabled={loadingPick || selectedRowPickedKeys.length > 0 || isDisabledAllButton} loading={loadingPick}>
            Pick
          </Button>
          <Button type="default" onClick={handleUnpick} disabled={loadingUnpick || selectedRowKeys.length > 0 || isDisabledAllButton} loading={loadingUnpick}>
            Unpick
          </Button>
        </Space>,
      ]);
    }
  }, [currentTab, selectedRowKeys, selectedRowPickedKeys, loadingPick, loadingUnpick]);

  useEffect(() => {
    if (currentTab === 'pick') {
      refetchAvailable();
      refetchAuthorized();
    }
  }, [currentTab]);

  const rowSelectionPicked = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys([]);
      setSelectedRowPickedKeys(selectedRowKeys);
    },
    getCheckboxProps: ({ AttachedPOLineItemToScanId }) => ({
      disabled: AttachedPOLineItemToScanId && AttachedPOLineItemToScanId.length <= 0,
    }),
  };

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRowPickedKeys([]);
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: ({ AttachedPOLineItemToScanId }) => ({
      disabled: AttachedPOLineItemToScanId && AttachedPOLineItemToScanId.length <= 0,
    }),
  };

  const handlePick = useCallback(() => {
    setFormDataPick({
      ScanId_POPartLocation_Fks: selectedRowKeys,
    });
  }, [selectedRowKeys, record?.Id]);

  const handleUnpick = useCallback(() => {
    setFormDataUnpick({
      ScanId_POPartLocation_Fks: selectedRowPickedKeys,
    });
  }, [selectedRowPickedKeys, record?.Id]);

  const scanIdColumns = [
    {
      title: 'Scan ID',
      dataIndex: 'ScanId',
      key: 'ScanId',
    },
    {
      title: 'Inbound Job Number',
      dataIndex: 'WarehouseIn_JobNumber',
      key: 'WarehouseIn_JobNumber',
    },
    {
      title: 'Total Quantity',
      dataIndex: 'TotalQuantity',
      key: 'TotalQuantity',
    },
    {
      title: 'Warehouse',
      dataIndex: 'Warehouse',
      key: 'Warehouse',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Code && `[${text?.Code}] ${text?.Name}`}
        </span>
      ),
    },
    {
      title: 'Storage Location',
      dataIndex: 'StorageLocation',
      key: 'StorageLocation',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Code}
        </span>
      ),
    },
    {
      title: 'Dimensions (INCH) L x W x H',
      dataIndex: 'DimensionsIn',
      key: 'DimensionsIn',
      render: (_, record) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(record?.LengthInch) || 0} x ${parseAndToFixed(record?.WidthInch) || 0} x ${parseAndToFixed(record?.HeightInch) || 0}`}
        </span>
      ),
    },
    {
      title: 'Dimensions (CM) L x W x H',
      dataIndex: 'DimensionsCm',
      key: 'DimensionsCm',
      render: (_, record) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(record?.LengthCm) || 0} x ${parseAndToFixed(record?.WidthCm) || 0} x ${parseAndToFixed(record?.HeightCm) || 0}`}
        </span>
      ),
    },
    {
      title: 'Volume (CBM)',
      dataIndex: 'VolumeCbm',
      key: 'VolumeCbm',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(text, 3) || ''}`}
        </span>
      ),
    },
    {
      title: 'Volume (CFT)',
      dataIndex: 'VolumeCft',
      key: 'VolumeCft',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(text, 3) || ''}`}
        </span>
      ),
    },
    {
      title: 'Weight (KG)',
      dataIndex: 'WeightKg',
      key: 'WeightKg',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(text) || ''}`}
        </span>
      ),
    },
    {
      title: 'Weight (LBS)',
      dataIndex: 'WeightLbs',
      key: 'WeightLbs',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(text) || ''}`}
        </span>
      ),
    },
  ];

  const availableScanIdColumns = [...scanIdColumns];

  availableScanIdColumns.splice(1, 0, {
    title: 'Authorized Date',
    dataIndex: 'AuthorizedDate',
    key: 'AuthorizedDate',
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <span style={{ userSelect: 'text' }}>
        {timestampToDate(text, true)}
      </span>
    ),
  });

  availableScanIdColumns.splice(1, 0, {
    title: 'Label Series',
    dataIndex: 'LabelSeries',
    key: 'LabelSeries',
    render: (text) => (
      <span style={{ userSelect: 'text' }}>
        {`${text || ''} of ${availableScanIdColumns.length}`}
      </span>
    ),
  });

  const pickedScanIdColumns = [...scanIdColumns];

  pickedScanIdColumns.splice(1, 0, {
    title: 'Picked Date',
    dataIndex: 'PickedDate',
    key: 'PickedDate',
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <span style={{ userSelect: 'text' }}>
        {timestampToDate(text, true)}
      </span>
    ),
  });

  pickedScanIdColumns.splice(1, 0, {
    title: 'Label Series',
    dataIndex: 'LabelSeries',
    key: 'LabelSeries',
    render: (text) => (
      <span style={{ userSelect: 'text' }}>
        {`${text || ''} of ${pickedScanIdList.length}`}
      </span>
    ),
  });

  const lineItemColumns = [
    {
      title: 'Line Item No.',
      dataIndex: 'LineNumber',
      key: 'LineNumber',
    },
    {
      title: 'Part No.',
      dataIndex: 'Part_Number',
      key: 'Part_Number',
    },
    {
      title: 'Part Description.',
      dataIndex: 'Part_Description',
      key: 'Part_Description',
    },
    {
      title: 'Assigned Quantity',
      dataIndex: 'AssignedQuantity',
      key: 'AssignedQuantity',
    },
  ];

  const expandedRowRender = ({ ScanId, AttachedPOLineItemToScanId }) => (
    <>
      {AttachedPOLineItemToScanId.length > 0 ? (
        <div style={{ padding: '18px 12px', backgroundColor: '#eee' }}>
          <TableSubSmall
            size="small"
            dataSource={AttachedPOLineItemToScanId}
            columns={lineItemColumns}
            pagination={false}
            rowKey={(record) => `${ScanId}-${record?.Id}`}
            scroll={{
              x: true,
            }}
          />
        </div>
      ) : (<div>No Items</div>)}
    </>
  );

  return (
    <Content style={{ padding: 16 }}>
      <Row gutter={16}>
        <Col xs={24}>
          <CardStyled>
            <Typography.Title level={5}>Authorized Scan IDs</Typography.Title>
            <TableSmall
              style={{ marginTop: 24 }}
              size="small"
              dataSource={authorizedScanIdList}
              loading={listAuthorizedLoading}
              columns={availableScanIdColumns}
              pagination={false}
              scroll={{
                x: true,
              }}
              rowKey="Id"
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys,
                ...rowSelection,
              }}
              expandable={{
                rowExpandable: ({ AttachedPOLineItemToScanId }) => AttachedPOLineItemToScanId && AttachedPOLineItemToScanId.length > 0,
                defaultExpandAllRows: true,
                expandedRowRender,
              }}
            />
          </CardStyled>
          <Divider />
          <CardStyled>
            <Typography.Title level={5}>Picked Scan IDs</Typography.Title>
            <TableSmall
              style={{ marginTop: 24 }}
              size="small"
              dataSource={pickedScanIdList}
              loading={listPickedLoading}
              columns={pickedScanIdColumns}
              pagination={false}
              scroll={{
                x: true,
              }}
              rowKey="Id"
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedRowPickedKeys,
                ...rowSelectionPicked,
              }}
              expandable={{
                rowExpandable: ({ AttachedPOLineItemToScanId }) => AttachedPOLineItemToScanId && AttachedPOLineItemToScanId.length > 0,
                defaultExpandAllRows: true,
                expandedRowRender,
              }}
            />
          </CardStyled>
        </Col>
      </Row>
    </Content>
  );
}

export default memo(OutboundJobPick);
