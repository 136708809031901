import { gql } from '@apollo/client';

export const CREATE_SCAN_ID = gql`
    mutation CreateScanId($data: PurchaseOrderPartLocationInput!) {
        createScanId(data: $data) {
            code
            success
            message
        }
    }`;

export default CREATE_SCAN_ID;
