import { gql } from '@apollo/client';

const GET_ALL_TARIFF = gql`
    query Tariff{
    getAllTariff {
        code
        success
        message
        results {
        Id
        Buy_Rate
        Sell_Rate
        }
    }
    }
`;

export default GET_ALL_TARIFF;
