import { gql } from '@apollo/client';

export const CREATE_OUTBOUND_JOB = gql`
    mutation CreateWarehouseOutboundJob($data: WarehouseOutInput!) {
        createWarehouseOutboundJob(data: $data) {
            code
            success
            message
        }
    }`;

export default CREATE_OUTBOUND_JOB;
