import { memo } from 'react';

import GET_ALL_STORAGE_LOCATION from '@graphql/getAllStorageLocation';

import SelectLazy from '../SelectLazy';

function SelectStorageLocation(props) {
  const renderOptions = (data) => (data || []).map((d) => ({
    value: d.Id,
    label: `[${d.Id}] ${d.Code}`,
  }));

  return (
    <SelectLazy
      {...props}
      placeholder="Select Storage Location"
      customRender={renderOptions}
      query={GET_ALL_STORAGE_LOCATION}
    />
  );
}

export default memo(SelectStorageLocation);
