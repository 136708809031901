import React, {
  memo, useCallback, useMemo,
  useState,
} from 'react';
import { Button } from 'antd';
import Search from 'antd/es/input/Search';
import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { RefreshSharp } from '@mui/icons-material';

import GET_ALL_OUTBOUND_JOBS from '@graphql/TH_getAllOutboundJobs';

import { timestampToDate } from '@utils/helpers/date';
import useList from '@hooks/useList';

import { ContentStyled } from '@components/Styles/Global';
import { TablePaginationStyled, TableStyled } from '@components/Styles/TableStyles';
import { ModalStyled } from '@components/Styles/ModalStyles';
import { SpaceActionButton } from '@components/Styles/ButtonStyles';
import ButtonTooltip from '@components/Button/ButtonTooltip';
import WarehouseOutOutboundJob from '../WarehouseOutOutboundJob';

function WarehouseOutList() {
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const [addOutboundJobModal, setAddOutboundJobModal] = useState(false);
  const [modalActions, setModalActions] = useState([]);

  const {
    data, loading, refetch, count: total, filters,
  } = useList({ query: GET_ALL_OUTBOUND_JOBS });
  const {
    pageSize, currentPage, setCurrentPage, setSearchQuery,
  } = filters;

  const columns = useMemo(() => [
    {
      title: 'Job No.',
      dataIndex: 'JobNumber',
      key: 'JobNumber',
      render: (text, record) => (
        <Button
          type="link"
          icon={<EyeOutlined />}
          onClick={() => handleRowClick(record?.Id)}
        >
          {' '}
          {text}
        </Button>
      ),
    },
    {
      title: 'Customer',
      dataIndex: 'CustomerParty',
      key: 'CustomerParty',
      sorter: (a, b) => (a.CustomerParty || '') - (b.CustomerParty || ''),
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Name}
        </span>
      ),
    },
    {
      title: 'Job Date',
      dataIndex: 'JobDate',
      key: 'JobDate',
      render: (text) => timestampToDate(text, true),
    },
    {
      title: 'Job Status',
      dataIndex: 'JobStatus',
      key: 'JobStatus',
    },
    {
      title: 'Created By',
      dataIndex: 'CreatedBy',
      key: 'CreatedBy',
    },
  ], []);

  const handleRefresh = useCallback(() => {
    refetch();
  }, []);

  const handleRowClick = useCallback((id) => {
    setSelectedRecordId(id);
    setAddOutboundJobModal(true);
  }, []);

  const handleSetModalActions = (actions) => {
    setModalActions(actions);
  };

  const handleAddClick = useCallback(() => {
    setSelectedRecordId(null);
    setAddOutboundJobModal(true);
  }, []);

  const handleCancel = useCallback(() => {
    setSelectedRecordId(null);
    setAddOutboundJobModal(false);
  }, []);

  const handleSuccess = useCallback((closeModal) => {
    refetch();

    if (closeModal) {
      setAddOutboundJobModal(false);
    }
  }, []);

  const handleChangePage = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  const handleSearch = useCallback((query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  }, []);

  return (
    <ContentStyled>
      <SpaceActionButton>
        <Search
          placeholder="Search..."
          allowClear
          onSearch={handleSearch}
          style={{ width: 304 }}
        />
        <ButtonTooltip
          type="default"
          onClick={handleRefresh}
          icon={<RefreshSharp />}
          tooltipProps={{ title: 'Refresh' }}
        />
        <ButtonTooltip
          type="default"
          onClick={handleAddClick}
          icon={<PlusOutlined />}
          tooltipProps={{ title: 'Add Outbound Job' }}
        />
      </SpaceActionButton>
      <TableStyled
        size="small"
        loading={loading}
        dataSource={data}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
      />
      <TablePaginationStyled current={currentPage} defaultCurrent={1} pageSize={pageSize} total={total} onChange={handleChangePage} showSizeChanger={false} />
      {addOutboundJobModal && (
        <ModalStyled
          title={!selectedRecordId ? 'Add New Outbound Job' : 'Outbound Job Details'}
          open={addOutboundJobModal}
          onCancel={handleCancel}
          maskClosable={false}
          keyboard={false}
          style={{ top: '54px' }}
          styles={{ body: { height: 'calc(90vh - 108px)', overflow: 'auto' } }}
          width="95%"
          footer={modalActions}
        >
          <WarehouseOutOutboundJob
            onSetActions={handleSetModalActions}
            recordId={selectedRecordId}
            onCancel={handleCancel}
            onSuccess={handleSuccess}
          />
        </ModalStyled>
      )}
    </ContentStyled>
  );
}

export default memo(WarehouseOutList);
