import { gql } from '@apollo/client';

const GET_OUTBOUND_JOB_BY_ID = gql`
    query GetWarehouseOutById($Id: Int!) {
        getWarehouseOutById(Id: $Id) {
            code
            success
            message
            result {
                Id
                JobNumber
                JobDate
                JobStatus
                FreightJobNumber
                Customer_Party_Fk
                ModeOfTransport_Fk
                Remarks
                CreatedBy
                CreatedDate
                UpdatedBy
                UpdateDate
                CustomerParty {
                    Id
                    Name
                    Code
                }
                ModeOfTransport {
                    Id
                    Code
                    Description
                }
            }
        }
    }
`;

export default GET_OUTBOUND_JOB_BY_ID;
