import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import { Tabs } from 'antd';

import GET_PO_LINE_ITEMS_BY_PURCHASE_ORDER_ID from '@graphql/getPOLineItemsByPurchaseOrderId';
import GET_INBOUND_JOB_BY_ID from '@graphql/TH_getInboundJobById';

import useListRelatedId from '@hooks/useListRelatedId';
import useDetail from '@hooks/useDetail';

import SectionLoading from '@components/SectionLoading';
import InboundJob from './Tabs/InboundJob';
import InboundJobPrint from './Tabs/InboundJobPrint';
import InboundJobReceivedGoods from './Tabs/InboundJobReceivedGoods';

function WarehouseInInboundJob({
  recordId,
  onSetActions,
  onSuccess,
}) {
  const [currentTab, setCurrentTab] = useState('inboundJobs');

  const {
    data: list, refetch, loading: listLoading, filters: listFilters,
  } = useListRelatedId({ query: GET_PO_LINE_ITEMS_BY_PURCHASE_ORDER_ID });

  const { data: detail, loading: detailLoading, refetch: refetchInboundJobById } = useDetail({ query: GET_INBOUND_JOB_BY_ID, id: recordId });

  useEffect(() => {
    if (detail?.PurchaseOrder_Fk) {
      listFilters.setValue(detail?.PurchaseOrder_Fk);
    }
  }, [detail]);

  const handleSuccessConfirmUnconfirmGoods = useCallback(() => {
    refetchInboundJobById();
  }, []);

  const handleSetModalActions = useCallback((modalActions) => {
    onSetActions(modalActions);
  }, []);

  const handleChangeTab = useCallback((key) => {
    setCurrentTab(key);
  }, []);

  const handleSuccessDistributeQuantity = useCallback(() => {
    refetch();
  }, []);

  const poLineItemColumns = [
    {
      title: 'Line No.',
      dataIndex: 'LineNumber',
      key: 'LineNumber',
    },
    {
      title: 'Line Status',
      dataIndex: 'LineStatus',
      key: 'LineStatus',
    },
    {
      title: 'Part No.',
      dataIndex: 'Part.Part_Number',
      key: 'Part.Part_Number',
      render: (_, record) => (
        <span style={{ userSelect: 'text' }}>
          {record?.Part?.Part_Number}
        </span>
      ),
    },
    {
      title: 'Country of Origin',
      dataIndex: 'CountryOfOrigin',
      key: 'CountryOfOrigin',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Code && `[${text?.Code}] ${text?.Name}`}
        </span>
      ),
    },
  ];

  const items = useMemo(() => [
    {
      key: 'inboundJobs',
      label: 'Inbound Job',
      children: (
        <SectionLoading loading={detailLoading}>
          <InboundJob
            record={detail}
            poLineItems={list}
            poLineItemsLoading={listLoading}
            poLineItemColumns={poLineItemColumns}
            listFilters={listFilters}
            onSuccess={onSuccess}
            onSetModalActions={handleSetModalActions}
            currentTab={currentTab}
          />
        </SectionLoading>
      ),
    },
    recordId && {
      key: 'receivedGoods',
      label: 'Receive Goods',
      children: (
        <SectionLoading loading={detailLoading}>
          <InboundJobReceivedGoods
            record={detail}
            poLineItems={list}
            poLineItemsLoading={listLoading}
            poLineItemColumns={poLineItemColumns}
            onSetModalActions={handleSetModalActions}
            currentTab={currentTab}
            onSuccess={onSuccess}
            onSuccessDistributeQuantity={handleSuccessDistributeQuantity}
            onSuccessConfirmUnconfirmGoods={handleSuccessConfirmUnconfirmGoods}
          />
        </SectionLoading>
      ),
    },
    recordId && {
      key: 'printDocument',
      label: 'Print Document',
      children: (
        <SectionLoading loading={detailLoading}>
          <InboundJobPrint
            record={detail}
            onSetModalActions={handleSetModalActions}
            currentTab={currentTab}
          />
        </SectionLoading>
      ),
    },
  ].filter(Boolean), [detailLoading, detail, currentTab, list, listLoading]);

  return (
    <Tabs defaultActiveKey="inbound-jobs" items={items} onChange={handleChangeTab} />
  );
}

export default memo(WarehouseInInboundJob);
