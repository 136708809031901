import React, {
  useState, useRef, useEffect,
  useCallback,
} from 'react';
import {
  Button, Form, Row, Col, Input, Skeleton, Radio, Select, Flex,
  Upload,
  Image,
} from 'antd';
import { get } from 'lodash';
import { useFormik } from 'formik';
import { useLazyQuery } from '@apollo/client';
import * as Yup from 'yup';
import { UploadOutlined } from '@ant-design/icons';

import GET_ALL_PARTIES from '@graphql/TH_getAllParties';
import UPDATE_PARTY from '@graphql/updateParty';
import GET_PARTY_BY_ID from '@graphql/getPartyById';

import useDropdownList from '@hooks/useDropdownList';
import useCreateUpdateRecord from '@hooks/useCreateUpdateRecord';

import { ContentStyled } from '@components/Styles/Global';
import { getBase64 } from '@utils/helpers/image';
import { showNotification } from '@utils/helpers/notifications';

function PartyUpdateForm({ selectedRow, onSuccess }) {
  const formikRef = useRef();

  const rowId = get(selectedRow, 'Id', '');
  const [formData, setFormData] = useState(null);
  const [selectedNewLogo, setSelectedNewLogo] = useState(false);

  const { data: parties } = useDropdownList({ query: GET_ALL_PARTIES });

  const [getPartyById, { data, loading: getByIdLoading }] = useLazyQuery(GET_PARTY_BY_ID, {
    variables: { Id: rowId },
    fetchPolicy: 'network-only',
  });

  const partyDetails = get(data, 'result', {});

  const { loading } = useCreateUpdateRecord({
    formData,
    mutation: UPDATE_PARTY,
    variables: { id: rowId },
    callback: () => onSuccess(),
  });

  const validationSchema = Yup.object().shape({
    Code: Yup.string().required('Code is required'),
    Name: Yup.string().required('Name is required'),
    Salesperson_User_Fk: Yup.number().required('Salesperson User Fk is required'),
    Is_Customer: Yup.number().required('Is Customer is required'),
    Is_Billing_Party: Yup.number().required('Is Billing Party is required'),
    Is_Supplier: Yup.number().required('Is Supplier is required'),
    Is_Shipper: Yup.number().required('Is Shipper is required'),
    Is_Consignee: Yup.number().required('Is Consignee is required'),
    Is_Carrier: Yup.number().required('Is Carrier is required'),
    Is_NotifyParty: Yup.number().required('Is Notify Party is required'),
    Active: Yup.number().required('Active is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Logo: partyDetails.Logo,
      Code: partyDetails.Code || '',
      Name: partyDetails.Name || '',
      Website: partyDetails.Website || '',
      Salesperson_User_Fk: partyDetails.Salesperson_User_Fk || 0,
      Is_Customer: partyDetails.Is_Customer || 0,
      Is_Billing_Party: partyDetails.Is_Billing_Party || 0,
      Is_Supplier: partyDetails.Is_Supplier || 0,
      Is_Shipper: partyDetails.Is_Shipper || 0,
      Is_Consignee: partyDetails.Is_Consignee || 0,
      Is_Carrier: partyDetails.Is_Carrier || 0,
      Is_NotifyParty: partyDetails.Is_NotifyParty || 0,
      Parent_Party_Fk: partyDetails.Parent_Party_Fk || '',
      Active: partyDetails.Active || 0,
    },
    innerRef: formikRef,
    validationSchema,
    onSubmit: async (values) => {
      const logo = typeof values.Logo !== 'string' ? values.Logo : null;
      setFormData({
        Code: values.Code,
        Name: values.Name,
        Logo: logo,
        Website: values.Website,
        Salesperson_User_Fk: values.Salesperson_User_Fk,
        Is_Customer: values.Is_Customer,
        Is_Billing_Party: values.Is_Billing_Party,
        Is_Supplier: values.Is_Supplier,
        Is_Shipper: values.Is_Shipper,
        Is_Consignee: values.Is_Consignee,
        Is_Carrier: values.Is_Carrier,
        Is_NotifyParty: values.Is_NotifyParty,
        Parent_Party_Fk: values.Parent_Party_Fk === '' ? null : values.Parent_Party_Fk,
        Active: values.Active,
      });
    },
  });

  const handleBeforeUpload = useCallback((file) => {
    const isPNG = file.type === 'image/png';
    const isJPG = file.type === 'image/jpg';
    const isJPEG = file.type === 'image/jpeg';

    if (!isPNG && !isJPG && !isJPEG) {
      showNotification('error', 'Error', 'Invalid file type');
      return false;
    }

    getBase64(file, (url) => {
      const data = url.split('base64,');
      const logo = {
        filename: file.name,
        mimetype: file.type,
        encoding: 'base64',
        data: data[1],
      };
      formik.setFieldValue('Logo', logo);
      setSelectedNewLogo(true);
    });
    return false;
  }, []);

  const handleRemoveLogo = useCallback(() => {
    formik.setFieldValue('Logo', null);
  }, []);

  useEffect(() => {
    if (rowId) {
      getPartyById();
    }
  }, [selectedRow]);

  const initialValues = {
    Code: partyDetails.Code || '',
    Name: partyDetails.Name || '',
    Website: partyDetails.Website || '',
    Salesperson_User_Fk: partyDetails.Salesperson_User_Fk || 0,
    Is_Customer: partyDetails.Is_Customer || 0,
    Is_Billing_Party: partyDetails.Is_Billing_Party || 0,
    Is_Supplier: partyDetails.Is_Supplier || 0,
    Is_Shipper: partyDetails.Is_Shipper || 0,
    Is_Consignee: partyDetails.Is_Consignee || 0,
    Is_Carrier: partyDetails.Is_Carrier || 0,
    Is_NotifyParty: partyDetails.Is_NotifyParty || 0,
    Parent_Party_Fk: partyDetails.Parent_Party_Fk || '',
    Active: partyDetails.Active || 0,
  };

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <ContentStyled style={{ marginTop: 25 }}>
      <Skeleton loading={getByIdLoading} active>
        <Form initialValues={initialValues} onFinish={formik.handleSubmit}>
          <Row>
            <Col xs={24} sm={24}>
              <Form.Item
                label="Logo"
                name="Logo"
              >
                <Upload
                  accept=".png, .jpg, .jpeg"
                  maxCount={1}
                  multiple={false}
                  onRemove={handleRemoveLogo}
                  beforeUpload={handleBeforeUpload}
                >
                  <Button
                    icon={<UploadOutlined />}
                  >
                    Upload File
                  </Button>
                </Upload>
              </Form.Item>
              {(partyDetails.Logo && !selectedNewLogo) && (
              <div style={{ marginBottom: 24 }}>
                <Image
                  height={80}
                  src={partyDetails.Logo}
                  preview={false}
                />
              </div>
              )}
              <Form.Item
                label="Code"
                name="Code"
                validateStatus={formik.errors.Code ? 'error' : 'success'}
                help={formik.errors.Code}
              >
                <Input
                  disabled
                  name="Code"
                  value={formik.values.Code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
              <Form.Item
                label="Name"
                name="Name"
                validateStatus={formik.errors.Name ? 'error' : 'success'}
                help={formik.errors.Name}
              >
                <Input
                  name="Name"
                  value={formik.values.Name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
              <Form.Item
                label="Website"
                name="Website"
              >
                <Input
                  name="Website"
                  value={formik.values.Website}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} hidden>
              <Form.Item
                label="Salesperson User Fk"
                name="Salesperson_User_Fk"
              >
                <Radio.Group
                  value={formik.values.Salesperson_User_Fk}
                  onChange={(e) => {
                    formik.setFieldValue('Salesperson_User_Fk', e.target.value);
                    formik.setFieldTouched('Salesperson_User_Fk', true);
                  }}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
              <Form.Item
                label="Is Customer"
                name="Is_Customer"
                validateStatus={formik.errors.Is_Customer ? 'error' : 'success'}
                help={formik.errors.Is_Customer}
              >
                <Radio.Group
                  value={formik.values.Is_Customer}
                  onChange={(e) => {
                    formik.setFieldValue('Is_Customer', e.target.value);
                    formik.setFieldTouched('Is_Customer', true);
                  }}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
              <Form.Item
                label="Is Billing Party"
                name="Is_Billing_Party"
                validateStatus={formik.errors.Is_Billing_Party ? 'error' : 'success'}
                help={formik.errors.Is_Billing_Party}
              >
                <Radio.Group
                  value={formik.values.Is_Billing_Party}
                  onChange={(e) => {
                    formik.setFieldValue('Is_Billing_Party', e.target.value);
                    formik.setFieldTouched('Is_Billing_Party', true);
                  }}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
              <Form.Item
                label="Is Supplier"
                name="Is_Supplier"
                validateStatus={formik.errors.Is_Supplier ? 'error' : 'success'}
                help={formik.errors.Is_Supplier}
              >
                <Radio.Group
                  value={formik.values.Is_Supplier}
                  onChange={(e) => {
                    formik.setFieldValue('Is_Supplier', e.target.value);
                    formik.setFieldTouched('Is_Supplier', true);
                  }}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
              <Form.Item
                label="Is Shipper"
                name="Is_Shipper"
                validateStatus={formik.errors.Is_Shipper ? 'error' : 'success'}
                help={formik.errors.Is_Shipper}
              >
                <Radio.Group
                  value={formik.values.Is_Shipper}
                  onChange={(e) => {
                    formik.setFieldValue('Is_Shipper', e.target.value);
                    formik.setFieldTouched('Is_Shipper', true);
                  }}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
              <Form.Item
                label="Is Consignee"
                name="Is_Consignee"
                validateStatus={formik.errors.Is_Consignee ? 'error' : 'success'}
                help={formik.errors.Is_Consignee}
              >
                <Radio.Group
                  value={formik.values.Is_Consignee}
                  onChange={(e) => {
                    formik.setFieldValue('Is_Consignee', e.target.value);
                    formik.setFieldTouched('Is_Consignee', true);
                  }}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
              <Form.Item
                label="Is Carrier"
                name="Is_Carrier"
                validateStatus={formik.errors.Is_Carrier ? 'error' : 'success'}
                help={formik.errors.Is_Carrier}
              >
                <Radio.Group
                  value={formik.values.Is_Carrier}
                  onChange={(e) => {
                    formik.setFieldValue('Is_Carrier', e.target.value);
                    formik.setFieldTouched('Is_Carrier', true);
                  }}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
              <Form.Item
                label="Is Notify Party"
                name="Is_NotifyParty"
                validateStatus={formik.errors.Is_NotifyParty ? 'error' : 'success'}
                help={formik.errors.Is_NotifyParty}
              >
                <Radio.Group
                  value={formik.values.Is_NotifyParty}
                  onChange={(e) => {
                    formik.setFieldValue('Is_NotifyParty', e.target.value);
                    formik.setFieldTouched('Is_NotifyParty', true);
                  }}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
              <Form.Item
                label="Parent Party"
                name="Parent_Party_Fk"
                validateStatus={formik.errors.Parent_Party_Fk ? 'error' : 'success'}
                help={formik.errors.Parent_Party_Fk}
              >
                <Select
                  showSearch
                  filterOption={filterOption}
                  onChange={(value) => {
                    formik.setFieldValue('Parent_Party_Fk', value);
                    formik.setFieldTouched('Parent_Party_Fk', true);
                  }}
                >
                  <Select.Option value="">N/A</Select.Option>
                  {parties.map((party) => (
                    <Select.Option key={party.Id} value={party.Id}>
                      {`[${party.Code}] ${party.Name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
              <Form.Item
                label="Active"
                name="Active"
                validateStatus={formik.errors.Active ? 'error' : 'success'}
                help={formik.errors.Active}
              >
                <Radio.Group
                  value={formik.values.Active}
                  onChange={(e) => {
                    formik.setFieldValue('Active', e.target.value);
                    formik.setFieldTouched('Active', true);
                  }}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} />
            <Col xs={24} sm={24}>
              <Row gutter={[24, 24]}>
                <Flex
                  gap="middle"
                  horizontal
                  style={{
                    width: '100%',
                    height: 20,
                    borderRadius: 6,
                  }}
                  justify="flex-end"
                  align="center"
                >
                  <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      Update Party
                    </Button>
                  </Form.Item>
                </Flex>
              </Row>
            </Col>

          </Row>
        </Form>
      </Skeleton>
    </ContentStyled>
  );
}

export default PartyUpdateForm;
