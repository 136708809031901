import { gql } from '@apollo/client';

const GET_PARTY_BY_ID = gql`
    query GetPartyById($Id: Int!) {
        getPartyById(Id: $Id) {
            code
            success
            message
            result {
                Id
                Code
                Name
                Website
                Logo
                Salesperson_User_Fk
                Is_Customer
                Is_Billing_Party
                Is_Supplier
                Is_Shipper
                Is_Consignee
                Is_NotifyParty
                Parent_Party_Fk
                Active
                CreatedBy
                CreatedDate
                UpdatedBy
                UpdateDate
            }
        }
    }
`;

export default GET_PARTY_BY_ID;
