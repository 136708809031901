import React, { memo, useCallback } from 'react';
import dayjs from 'dayjs';
import { get } from 'lodash';
import {
  Page, Document,
  View,
  Image,
  Text,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  TD, TR, Table,
} from '@ag-media/react-pdf-table';

import useLogo from '@hooks/useLogo';

import { parseAndToFixed } from '@utils/helpers/helpers';
import { timestampToDate } from '@utils/helpers/date';
import getDefaultLogo from '@config/defaultLogo';

import '@components/Font/Roboto';
import styles from '@components/Styles/PDFStyles';

const stylesLocal = StyleSheet.create({
  barcodeImg: {
    width: 250,
    height: 50,
    marginBottom: 4,
  },
});

function PrintPack({
  data,
  onLoadPrintFile,
  user,
  barcodes,
}) {
  const defaultLogo = getDefaultLogo();
  const logo = useLogo({ logoUrl: defaultLogo });

  const handleRender = useCallback((blob) => {
    onLoadPrintFile(blob);
  }, []);

  return (
    <Document onRender={handleRender}>
      <Page size="A4" style={styles.page}>
        <View style={styles.header} fixed>
          <View>
            <Image
              style={styles.headerImage}
              src={logo}
            />
          </View>
          <View>
            <Table
              style={{ ...styles.tableNoBorder, ...{ width: '140px' } }}
              weightings={[0.4, 0.6]}
            >
              <TR>
                <TD style={styles.cellLabel}>
                  <Text>Page</Text>
                  <Text>{' : '}</Text>
                </TD>
                <TD>
                  <Text
                    render={({ pageNumber, totalPages }) => (
                      `${pageNumber} / ${totalPages}`
                    )}
                  />
                </TD>
              </TR>
              <TR>
                <TD style={styles.cellLabel}>
                  <Text>Printed By</Text>
                  <Text>{' : '}</Text>
                </TD>
                <TD>
                  {user?.Full_Name}
                </TD>
              </TR>
              <TR>
                <TD style={styles.cellLabel}>
                  <Text>Printed Date</Text>
                  <Text>{' : '}</Text>
                </TD>
                <TD>
                  {dayjs().format('DD MMM YYYY')}
                </TD>
              </TR>
            </Table>
          </View>
        </View>
        <View style={styles.headerSection} fixed>
          <Text style={styles.headerSectionText}>Packed / Unpacked Report</Text>
        </View>
        <View style={styles.barcode} fixed>
          <Image src={barcodes[Object.keys(barcodes)[0]]} style={stylesLocal.barcodeImg} />
        </View>
        <View fixed>
          <Table
            style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
            tdStyle={{ alignItems: 'start', fontWeight: 600, padding: 2 }}
            weightings={[0.25, 0.25, 0.2, 0.3]}
          >
            <TR>
              <TD style={styles.cellLabel}>
                <Text>Outbound Job No.</Text>
                <Text>{' : '}</Text>
              </TD>
              <TD>
                <Text>{get(data, 'WarehouseOutDetails.JobNumber', '')}</Text>
              </TD>
            </TR>
            <TR>
              <TD style={styles.cellLabel}>
                <Text>Job Date</Text>
                <Text>{' : '}</Text>
              </TD>
              <TD>
                <Text>{timestampToDate(get(data, 'WarehouseOutDetails.JobDate', ''), false, false, 'DD MMM YYYY')}</Text>
              </TD>
            </TR>
            <TR>
              <TD style={styles.cellLabel}>
                <Text>Customer</Text>
                <Text>{' : '}</Text>
              </TD>
              <TD>
                <Text>{get(data, 'WarehouseOutDetails.CustomerParty.Name', '')}</Text>
              </TD>
              <TD style={styles.cellLabel}>
                <Text>Remarks</Text>
                <Text>{' : '}</Text>
              </TD>
              <TD>
                <Text>{get(data, 'WarehouseOutDetails.Remarks', '')}</Text>
              </TD>
            </TR>
          </Table>
          <View style={{ height: 4 }} />
          <View style={styles.borderSmall} />
          <View style={{ height: 4 }} />
          <Table
            style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
            tdStyle={{ alignItems: 'start', fontWeight: 600, padding: 2 }}
          >
            <TR>
              <TD style={styles.cellLabel}>
                <Text>Label Series</Text>
              </TD>
              <TD style={styles.cellLabel}>
                <Text>Doc Ref.</Text>
              </TD>
              <TD style={styles.cellLabel}>
                <Text>Local Cust Ref.</Text>
              </TD>
              <TD style={styles.cellLabel}>
                <Text>Lot No.</Text>
              </TD>
              <TD style={styles.cellLabel}>
                <Text>Mfg. Date</Text>
              </TD>
              <TD style={styles.cellLabel}>
                <Text>Exp. Date</Text>
              </TD>
              <TD style={styles.cellLabel}>
                <Text>UOM</Text>
              </TD>
            </TR>
          </Table>
          <View style={{ height: 2 }} />
          <View style={styles.borderSmall} />
          <View style={{ height: 4 }} />
        </View>
        {(data?.PackedScanIds || []).map((scanIdData, scanIdIndex) => (
          <View key={scanIdData.ScanId}>
            <View>
              <Table
                style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
                tdStyle={{ alignItems: 'start', fontWeight: 600, padding: 2 }}
                weightings={[0.15, 0.35, 0.10, 0.15, 0.10, 0.15]}
              >
                <TR>
                  <TD style={styles.cellLabel}>
                    <Text>Overpack / Scan ID</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{get(scanIdData, 'ScanId', '')}</Text>
                  </TD>
                  <TD style={styles.cellLabel}>
                    <Text>Warehouse</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{get(scanIdData, 'Warehouse.Name', '')}</Text>
                  </TD>
                  <TD style={styles.cellLabel}>
                    <Text>Location</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    {/* <Text>{get(scanIdData, 'StorageLocation.Code', '')}</Text> */}
                    <Text> STGPK</Text>
                  </TD>
                </TR>
              </Table>
              <Table
                style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
                tdStyle={{ alignItems: 'start', padding: 2 }}
                weightings={[0.2, 0.3, 0.10, 0.15, 0.10, 0.15]}
              >
                <TR>
                  <TD>
                    <Text>{`${scanIdIndex + 1}/${get(data, 'TotalScanIds')}`}</Text>
                  </TD>
                </TR>
                <TR>
                  <TD style={styles.cellLabel}>
                    <Text style={styles.fontBold}>Dims. CM (LxWxH)</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{`${parseAndToFixed(scanIdData?.LengthCm) || 0} x ${parseAndToFixed(scanIdData?.WidthCm) || 0} x ${parseAndToFixed(scanIdData?.HeightCm) || 0}`}</Text>
                  </TD>
                  <TD style={styles.cellLabel}>
                    <Text style={styles.fontBold}>CBM</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{parseAndToFixed(get(scanIdData, 'VolumeCbm', ''), 3)}</Text>
                  </TD>
                  <TD style={styles.cellLabel}>
                    <Text style={styles.fontBold}>W. Kgs</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{parseAndToFixed(get(scanIdData, 'WeightKg', ''), 2)}</Text>
                  </TD>
                </TR>
                <TR>
                  <TD style={styles.cellLabel}>
                    <Text style={styles.fontBold}>Dims. INCH (LxWxH)</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{`${parseAndToFixed(scanIdData?.LengthInch) || 0} x ${parseAndToFixed(scanIdData?.WidthInch) || 0} x ${parseAndToFixed(scanIdData?.HeightInch) || 0}`}</Text>
                  </TD>
                  <TD style={styles.cellLabel}>
                    <Text style={styles.fontBold}>CFT</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{parseAndToFixed(get(scanIdData, 'VolumeCft', ''), 3)}</Text>
                  </TD>
                  <TD style={styles.cellLabel}>
                    <Text style={styles.fontBold}>W. Lbs</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{parseAndToFixed(get(scanIdData, 'WeightLbs', ''), 2)}</Text>
                  </TD>
                </TR>
              </Table>
              <View style={{ border: '1px solid #333333', padding: 4, marginTop: 12 }}>
                {(scanIdData?.AttachedScanIds || []).map((lineItemData) => (
                  <View key={get(lineItemData, 'ScanId')} wrap={false}>
                    <View style={{ ...styles.scanIdSection, marginBottom: 4, marginTop: 4 }}>
                      <Table
                        style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
                        tdStyle={{ alignItems: 'start', fontWeight: 600, padding: 2 }}
                        weightings={[0.15, 0.35, 0.10, 0.15, 0.10, 0.15]}
                      >
                        <TR>
                          <TD style={styles.cellLabel}>
                            <Text>Scan ID</Text>
                            <Text>{' : '}</Text>
                          </TD>
                          <TD>
                            <Text>{get(lineItemData, 'ScanId', '')}</Text>
                          </TD>
                          <TD style={styles.cellLabel}>
                            <Text>Warehouse</Text>
                            <Text>{' : '}</Text>
                          </TD>
                          <TD>
                            <Text>{get(lineItemData, 'Warehouse.Name', '')}</Text>
                          </TD>
                          <TD style={styles.cellLabel}>
                            <Text>Location</Text>
                            <Text>{' : '}</Text>
                          </TD>
                          <TD>
                            {/* <Text>{get(lineItemData, 'StorageLocation.Code', '')}</Text> */}
                            <Text> STGPK</Text>
                          </TD>
                        </TR>
                      </Table>
                      <Table
                        style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
                        tdStyle={{ alignItems: 'start', padding: 2 }}
                        weightings={[0.2, 0.3, 0.10, 0.15, 0.10, 0.15]}
                      >
                        <TR>
                          <TD>
                            <Text>{`${scanIdIndex + 1}/${get(data, 'TotalScanIds')}`}</Text>
                          </TD>
                        </TR>
                        <TR>
                          <TD style={styles.cellLabel}>
                            <Text style={styles.fontBold}>Dims. CM (LxWxH)</Text>
                            <Text>{' : '}</Text>
                          </TD>
                          <TD>
                            <Text>
                              {`${parseAndToFixed(lineItemData?.LengthCm) || 0} x ${parseAndToFixed(lineItemData?.WidthCm) || 0} x ${parseAndToFixed(lineItemData?.HeightCm) || 0}`}
                            </Text>
                          </TD>
                          <TD style={styles.cellLabel}>
                            <Text style={styles.fontBold}>CBM</Text>
                            <Text>{' : '}</Text>
                          </TD>
                          <TD>
                            <Text>{parseAndToFixed(get(lineItemData, 'VolumeCbm', ''), 3)}</Text>
                          </TD>
                          <TD style={styles.cellLabel}>
                            <Text style={styles.fontBold}>W. Kgs</Text>
                            <Text>{' : '}</Text>
                          </TD>
                          <TD>
                            <Text>{parseAndToFixed(get(lineItemData, 'WeightKg', ''), 2)}</Text>
                          </TD>
                        </TR>
                        <TR>
                          <TD style={styles.cellLabel}>
                            <Text style={styles.fontBold}>Dims. INCH (LxWxH)</Text>
                            <Text>{' : '}</Text>
                          </TD>
                          <TD>
                            <Text>
                              {`${parseAndToFixed(lineItemData?.LengthInch) || 0} x ${parseAndToFixed(lineItemData?.WidthInch) || 0} x ${parseAndToFixed(lineItemData?.HeightInch) || 0}`}
                            </Text>
                          </TD>
                          <TD style={styles.cellLabel}>
                            <Text style={styles.fontBold}>CFT</Text>
                            <Text>{' : '}</Text>
                          </TD>
                          <TD>
                            <Text>{parseAndToFixed(get(lineItemData, 'VolumeCft', ''), 3)}</Text>
                          </TD>
                          <TD style={styles.cellLabel}>
                            <Text style={styles.fontBold}>W. Lbs</Text>
                            <Text>{' : '}</Text>
                          </TD>
                          <TD>
                            <Text>{parseAndToFixed(get(lineItemData, 'WeightLbs', ''), 2)}</Text>
                          </TD>
                        </TR>
                      </Table>
                    </View>
                  </View>
                ))}
              </View>
            </View>
            <View style={{ height: 12 }} />
          </View>
        ))}
        <View style={{ height: 4 }} />
        <View wrap={false}>
          <Text style={styles.headerSectionTextSmall}>{`Total Count of Scan ID: ${get(data, 'TotalScanIds')}`}</Text>
          <View style={{ height: 24 }} />
          <Table
            style={{ ...styles.tableNoBorder, ...{ width: '60%', margin: '0 auto' } }}
            tdStyle={{ alignItems: 'start', padding: '8px 2px' }}
            weightings={[0.4, 0.2, 0.4]}
          >
            <TR>
              <TD style={styles.tdBottomBordered}>Prepared:</TD>
              <TD />
              <TD style={styles.tdBottomBordered}>Picked By:</TD>
            </TR>
            <TR>
              <TD style={styles.tdBottomBordered}>Date:</TD>
              <TD />
              <TD style={styles.tdBottomBordered}>Date:</TD>
            </TR>
            <TR>
              <TD style={styles.tdBottomBordered}>Signature:</TD>
              <TD />
              <TD style={styles.tdBottomBordered}>Signature:</TD>
            </TR>
          </Table>
        </View>
      </Page>
    </Document>
  );
}

export default memo(PrintPack);
