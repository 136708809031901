import { gql } from '@apollo/client';

const CREATE_PART = gql`
    mutation CreatePart($data: PartInput!) {
        createPart(data: $data) {
            code
            success
            message
        }
    }
`;

export default CREATE_PART;
