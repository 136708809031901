import { gql } from '@apollo/client';

export const GET_ALL_COUNTRIES = gql`
    query GetAllCountry($limit: Int, $offset: Int, $searchText: String) {
        getAllCountry (limit: $limit, offset: $offset, searchText: $searchText) {
            code
            success
            message
            results {
                Id
                Code
                Name
            }
        }
    }
`;

export default GET_ALL_COUNTRIES;
