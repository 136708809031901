import { gql } from '@apollo/client';

const UPDATE_PURCHASE_ORDER_OSD_REASON = gql`
    mutation UpdatePurchaseOrderOSDReason($data: PurchaseOrderOSDReasonInput!, $id: Int!) {
        updatePurchaseOrderOSDReason(data: $data, id: $id) {
            code
            success
            message
        }
    }
`;

export default UPDATE_PURCHASE_ORDER_OSD_REASON;
