import { gql } from '@apollo/client';

const GET_PO_OSD_REASONS_BY_PO_DETAIL_ID = gql`
    query GetPurchaseOrderOSDReasonsByPurchaseOrderDetailId($Id: Int!) {
        getPurchaseOrderOSDReasonsByPurchaseOrderDetailId(Id: $Id) {
            code
            success
            message
            count
            results {
                Id
                OsdDate
                ResolvedDate
                Osd {
                    Id
                    Osd_Reason
                }
            }
        }
    }
`;

export default GET_PO_OSD_REASONS_BY_PO_DETAIL_ID;
