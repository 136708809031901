import { gql } from '@apollo/client';

const GET_ALL_PURCHASE_ORDERS_FOR_TABLE_DATA = gql`
    query GetAllPurchaseOrders ($limit: Int, $offset: Int, $searchText: String) {
        getAllPurchaseOrders (limit: $limit, offset: $offset, searchText: $searchText) {
            code
            success
            message
            count
            results {
                Id
                PONumber
                PODate
                POStatus
                POType
                ModeOfTransport_Fk
                Customer_Party_Fk
                Supplier_Party_Fk
                Consignee_Party_Fk
                RequiredDate
                CreatedBy

                ModeOfTransport {
                    Id
                    Code
                    Description
                }
                CustomerParty {
                    Id
                    Name
                    Code
                }
                SupplierParty {
                    Id
                    Name
                    Code
                }
                ConsigneeParty {
                    Id
                    Name
                    Code
                }
                ShipperParty {
                    Id
                    Name
                    Code
                }
            }
        }
    }
`;

export default GET_ALL_PURCHASE_ORDERS_FOR_TABLE_DATA;
