import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import {
  Form,
  Col,
  Row,
  InputNumber,
  Divider,
  Input,
  Checkbox,
} from 'antd';

import { FormStyled } from '@components/Styles/FormStyles';
import SelectWarehouse from '@components/Select/SelectWarehouse';
import SelectStorageLocation from '@components/Select/SelectStorageLocation';
import SelectUnitOfMeasure from '@components/Select/SelectUnitOfMeasure';

function ScanIdForm({
  record, form, onFinish, onFinishFailed, onValuesChange,
}) {
  const isCreate = !record?.Id;

  let initialValues = isCreate ? {
    LengthInch: null,
    WidthInch: null,
    HeightInch: null,
    WeightLbs: null,
    VolumeCft: null,
    WidthCm: null,
    LengthCm: null,
    HeightCm: null,
    WeightKg: null,
    VolumeCbm: null,
    Warehouse_Fk: null,
    StorageLocation_Fk: null,
    LotNumber: null,
    Is_For_Packing: null,
  } : record;

  const [isForPackingCheck, setIsForPackingCheck] = useState(false);

  const onChangeForPacking = useCallback((e) => {
    const isForPacking = e.target.checked;
    form.setFieldsValue({
      Is_For_Packing: isForPacking ? 1 : 0,
    });
    setIsForPackingCheck(isForPacking);
  }, []);

  if (!isCreate) {
    initialValues = {
      ...initialValues,
      Warehouse_Fk: record.Warehouse_Fk ? {
        label: `[${record.Warehouse.Code}] ${record.Warehouse.Name}`,
        value: record.Warehouse.Id,
      } : null,
      StorageLocation_Fk: record.StorageLocation_Fk ? {
        label: `[${record.StorageLocation.Id}] ${record.StorageLocation.Code}`,
        value: record.StorageLocation.Id,
      } : null,
      UnitOfMeasure_Fk: record.UnitOfMeasure_Fk ? {
        label: `[${record.UnitOfMeasure.Code}] ${record.UnitOfMeasure.Description}`,
        value: record.UnitOfMeasure.Id,
      } : null,
      Is_For_Packing: record.Is_For_Packing ? 1 : 0,
    };
  }

  useEffect(() => {
    if (record?.Is_For_Packing) {
      setIsForPackingCheck(true);
    }
  }, [record]);

  useEffect(() => {
    form.resetFields();
  }, []);

  return (
    <FormStyled
      form={form}
      layout="horizontal"
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
    >
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            label="Warehouse"
            name="Warehouse_Fk"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <SelectWarehouse />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Storage Location"
            name="StorageLocation_Fk"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <SelectStorageLocation />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Unit of Measure"
            name="UnitOfMeasure_Fk"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <SelectUnitOfMeasure />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Lot Number"
            name="LotNumber"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="For Packing?"
            name="Is_For_Packing"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <Checkbox onChange={onChangeForPacking} checked={isForPackingCheck} />
          </Form.Item>
        </Col>
        <Divider style={{ marginTop: 16, marginBottom: 24 }} />
        <Col span={12}>
          <Col>
            <Form.Item
              label="Length (Inch)"
              name="LengthInch"
            >
              <InputNumber precision={2} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Width (Inch)"
              name="WidthInch"
            >
              <InputNumber precision={2} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Height (Inch)"
              name="HeightInch"
            >
              <InputNumber precision={2} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Weight (LBS)"
              name="WeightLbs"
            >
              <InputNumber precision={2} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="CFT"
              name="VolumeCft"
            >
              <InputNumber
                precision={3}
              />
            </Form.Item>
          </Col>
        </Col>
        <Col span={12}>
          <Col>
            <Form.Item
              label="Length (Cm)"
              name="LengthCm"
            >
              <InputNumber precision={2} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Width (Cm)"
              name="WidthCm"
            >
              <InputNumber precision={2} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Height (Cm)"
              name="HeightCm"
            >
              <InputNumber precision={2} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Weight (KG)"
              name="WeightKg"
            >
              <InputNumber precision={2} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="CBM"
              name="VolumeCbm"
            >
              <InputNumber
                precision={3}
              />
            </Form.Item>
          </Col>
        </Col>
      </Row>
    </FormStyled>
  );
}

export default memo(ScanIdForm);
