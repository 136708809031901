import { gql } from '@apollo/client';

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      code
      success
      token
      message
      user {
        Id
        First_Name
        Last_Name
        Full_Name
        Email
        Role
        Account_Status
        BranchCode
    }
    }
  }
`;

export default LOGIN_MUTATION;
