import { gql } from '@apollo/client';

const GET_ALL_UNIT_OF_MEASURE = gql`
    query GET_UOM {
        getAllUnitofMeasure {
            code
            success
            message
            results {
                Id
                Code
                Description
                Remarks
            }
        }
    }
  `;

export default GET_ALL_UNIT_OF_MEASURE;
