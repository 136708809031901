const getDefaultAddress = () => {
  const company = import.meta.env.VITE_DEFAULT_COMPANY;

  switch (company) {
    case 'kerry':
      return {
        name: 'Kerry Project Logistics (USA)',
        address: 'LLC 2506 Twinwood Pkwy, Bldg 3, Brookshire, TX 77423',
        telephone: '',
        fax: '',
        email: '',
        web: '',
      };
    default:
      return {
        name: 'Star Global Logistics Group',
        address: '5244 N Sam Houston Pkwy E Houston, TX 77032',
        telephone: '(832)-295-3609',
        fax: '',
        email: '',
        web: 'https://www.star-glg.com',
      };
  }
};

export default getDefaultAddress;
