export const filterDataByScanIdJobNumber = (data, searchText) => {
  if (!searchText) return data;

  const lowerCaseSearchText = searchText.toLowerCase();

  return data.filter((item) => {
    const scanIdMatch = item.ScanId && item.ScanId.toLowerCase().includes(lowerCaseSearchText);
    const jobNumberMatch = item.WarehouseIn_JobNumber && item.WarehouseIn_JobNumber.toString().includes(lowerCaseSearchText);
    return scanIdMatch || jobNumberMatch;
  });
};
