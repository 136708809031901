import { gql } from '@apollo/client';

export const MODE_OF_TRANSPORT = gql`
    query MODE_OF_TRANSPORT {
        getAllModeOfTransport {
            code
            success
            message
            results {
                Id
                Code
                Description
                # Country_Fk
            }
        }
  }`;

export default MODE_OF_TRANSPORT;
