import { gql } from '@apollo/client';

const RESET_PASSWORD = gql`
    mutation ResetPassword($id: ID!) {
        resetPassword(id: $id,) {
            code
            success
            message
        }
    }
`;

export default RESET_PASSWORD;
