import React, { memo, useCallback } from 'react';
import {
  Empty,
  Flex, List, Skeleton, Space, Tag,
  Typography,
} from 'antd';
import VirtualList from 'rc-virtual-list';
import { PlusOutlined } from '@ant-design/icons';

import { ListStyled } from '@components/Styles/ListStyles';
import ButtonTooltip from '@components/Button/ButtonTooltip';
import { ClearAllOutlined } from '@mui/icons-material';

function POLineItems({
  data,
  loading,
  onAddClick,
  onSelect,
  onClear,
  parts,
  onSelectRowKeys,
  selectedRowKeys,
}) {
  const handleSelect = useCallback((partId, key) => {
    onSelect(partId);
    onSelectRowKeys([key]);
  }, []);

  const newData = (data || []).map((d, i) => ({
    ...d,
    key: i,
  }));

  const getDesc = (value) => {
    const partNumber = parts?.find((part) => part.Id === value);
    return partNumber?.Part_Description;
  };

  return (
    <Flex vertical gap={16}>
      <Flex justify="space-between">
        <div>&nbsp;</div>
        <Space>
          <ButtonTooltip
            icon={<ClearAllOutlined />}
            onClick={onClear}
            tooltipProps={{
              title: 'Clear Form',
            }}
          />
          <ButtonTooltip
            icon={<PlusOutlined />}
            onClick={onAddClick}
            tooltipProps={{
              title: 'Add PO Line Item',
            }}
          />
        </Space>
      </Flex>
      <Skeleton loading={loading} active>
        <ListStyled
          bordered
          header={<Typography><strong>PO Line Items</strong></Typography>}
        >
          {newData.length > 0 ? (
            <VirtualList
              data={newData}
              height={400}
              itemHeight={47}
              itemKey="Id"
            >
              {(item) => (
                <List.Item
                  key={item.email}
                  onClick={() => handleSelect(item?.Id, item?.key)}
                  className={selectedRowKeys.includes(item.key) ? 'selected' : ''}
                >
                  <List.Item.Meta
                    description={`Line Number: ${item.LineNumber}`}
                    title={`${getDesc(item.PartNumber_Part_Fk)}`}
                  />
                  <Tag>{item.LineStatus}</Tag>
                </List.Item>
              )}
            </VirtualList>
          ) : (
            <Empty description={false} imageStyle={{ height: 64 }} style={{ padding: 12 }} />
          )}
        </ListStyled>
      </Skeleton>
    </Flex>
  );
}

export default memo(POLineItems);
