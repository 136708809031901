import { gql } from '@apollo/client';

export const AUTHORIZE_TO_PICK_SCAN_ID = gql`
    mutation AuthorizeToPick($data: AuthorizeToPickInput!) {
        authorizeToPick(data: $data) {
            code
            success
            message
        }
    }`;

export default AUTHORIZE_TO_PICK_SCAN_ID;
