import { memo, useEffect, useRef } from 'react';
import Barcode from 'react-barcode';

function BarcodeToDataURL({
  value, onBarcodeGenerated, uniqueKeyIdentifier, barcodeProps,
}) {
  const barcodeRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const generateBarcodeDataURL = () => {
      const svg = barcodeRef.current.querySelector('svg');

      if (svg && value) {
        const canvas = canvasRef.current;
        const xml = new XMLSerializer().serializeToString(svg);
        const svg64 = btoa(xml);
        const b64Start = 'data:image/svg+xml;base64,';
        const image64 = b64Start + svg64;

        const image = new Image();
        image.onload = () => {
          canvas.width = image.width;
          canvas.height = image.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0);
          const dataURL = canvas.toDataURL('image/png');
          const newValue = uniqueKeyIdentifier ? `${uniqueKeyIdentifier}-${value}` : value;
          onBarcodeGenerated({ value: newValue, dataURL }, barcodeRef, canvasRef);
        };
        image.src = image64;
      }
    };

    generateBarcodeDataURL();
  }, [value, uniqueKeyIdentifier]);

  return (
    <>
      <div ref={barcodeRef} style={{ display: 'none' }}>
        <Barcode value={value} {...barcodeProps} />
      </div>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </>
  );
}

export default memo(BarcodeToDataURL);
