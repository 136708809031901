import { useState } from 'react';
import { useQuery } from '@apollo/client';

import { handleApolloResponse } from '@utils/helpers/apolloResponse';

const useList = ({
  query,
  variables = {},
  ...props
}) => {
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const response = useQuery(query, {
    ...props,
    notifyOnNetworkStatusChange: true,
    variables: {
      ...variables,
      limit: pageSize,
      offset: (currentPage - 1) * pageSize,
      searchText: searchQuery,
    },
  });

  const data = handleApolloResponse(response, 'results');
  const count = handleApolloResponse(response, 'count');

  return {
    ...response,
    count,
    data,
    filters: {
      pageSize, currentPage, searchQuery, setPageSize, setCurrentPage, setSearchQuery,
    },
  };
};

export default useList;
