import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import useAuthStore from '@store/authStore';

function Root() {
  const { pathname } = useLocation();
  const token = useAuthStore((state) => state.token);

  if (pathname === '/' && !token) {
    return <Navigate to="/login" />;
  }

  if (token && pathname === '/') {
    return <Navigate to="/po-parts-labs" />;
  }

  return (
    <Outlet />
  );
}

export default Root;
