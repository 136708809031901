import React, { memo, useState, useEffect } from 'react';
import {
  Form, Row, Col, Input, Skeleton, Radio,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { get } from 'lodash';
import { useFormik } from 'formik';
import { useLazyQuery } from '@apollo/client';

import UPDATE_PARTS from '@graphql/updateParts';
import GET_PARTS_BY_ID from '@graphql/getPartsById';

import useCreateUpdateRecord from '@hooks/useCreateUpdateRecord';

import { ContentStyled } from '@components/Styles/Global';
import { CardStyled } from '@components/Styles/CardStyles';
import { FormStyled } from '@components/Styles/FormStyles';
import SelectParty from '@components/Select/SelectParty';
import SelectParentPart from '@components/Select/SelectParentPart';
import SelectCommodity from '@components/Select/SelectCommodity';
import SelectCurrency from '@components/Select/SelectCurrency';
import SelectUnitOfMeasure from '@components/Select/SelectUnitOfMeasure';
import SelectCountry from '@components/Select/SelectCountry';

function PartsUpdateForm({
  selectedRow,
  onSuccess,
  onSubmitAction,
  onLoading,
}) {
  const rowId = get(selectedRow, 'Id', '');

  const [form] = useForm();
  const [formData, setFormData] = useState(null);

  const [getPartById, { data, loading: getByIdLoading }] = useLazyQuery(GET_PARTS_BY_ID, {
    variables: { Id: rowId },
    fetchPolicy: 'network-only',
  });

  const partsDetail = get(data, 'result', {});

  const { loading } = useCreateUpdateRecord({
    formData,
    mutation: UPDATE_PARTS,
    variables: { id: rowId },
    callback: () => onSuccess(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Customer_Party_Fk: partsDetail.Customer_Party_Fk || null,
      Supplier_Party_Fk: partsDetail.Supplier_Party_Fk || null,
      Part_Number: partsDetail.Part_Number || null,
      Part_Description: partsDetail.Part_Description || null,
      Remarks_1: partsDetail.Remarks_1 || null,
      Remarks_2: partsDetail.Remarks_2 || null,
      Remarks_3: partsDetail.Remarks_3 || null,
      Parent_Part_Fk: partsDetail.Parent_Part_Fk || null,
      Schedule_B: partsDetail.Schedule_B || null,
      ECCN: partsDetail.ECCN || null,
      CountryOfOrigin_Country_Fk: partsDetail.CountryOfOrigin_Country_Fk || null,
      Currency_Fk: partsDetail.Currency_Fk || null,
      Unit_Price: partsDetail.Unit_Price || null,
      Customs_Unit_Price: partsDetail.Customs_Unit_Price || null,
      UnitOfMeasure_Fk: partsDetail.UnitOfMeasure_Fk || null,
      Active: partsDetail.Active || 1,
    },
    onSubmit: async (values) => {
      const newData = {
        Customer_Party_Fk: get(values, 'Customer_Party_Fk.value', values.Customer_Party_Fk || null),
        Supplier_Party_Fk: get(values, 'Supplier_Party_Fk.value', values.Supplier_Party_Fk || null),
        Part_Number: values.Part_Number,
        Part_Description: values.Part_Description,
        Remarks_1: values.Remarks_1,
        Remarks_2: values.Remarks_2,
        Remarks_3: values.Remarks_3,
        Parent_Part_Fk: get(values, 'Parent_Part_Fk.value', values.Parent_Part_Fk || null),
        Schedule_B: values.Schedule_B,
        ECCN: values.ECCN,
        CountryOfOrigin_Country_Fk: get(values, 'CountryOfOrigin_Country_Fk.value', values.CountryOfOrigin_Country_Fk || null),
        Currency_Fk: get(values, 'Currency_Fk.value', values.Currency_Fk || null),
        Unit_Price: values.Unit_Price,
        Customs_Unit_Price: values.Customs_Unit_Price,
        UnitOfMeasure_Fk: get(values, 'UnitOfMeasure_Fk.value', values.UnitOfMeasure_Fk || null),
        Active: values.Active,
      };
      setFormData(newData);
    },
  });

  useEffect(() => {
    if (rowId) {
      getPartById();
    }
  }, [selectedRow]);

  useEffect(() => {
    onSubmitAction(() => () => form.submit());
  }, []);

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (partsDetail?.Id) {
      form.setFieldsValue({
        Customer_Party_Fk: partsDetail.Customer_Party_Fk ? {
          label: `[${partsDetail.Customer.Code}] ${partsDetail.Customer.Name}`,
          value: partsDetail.Customer.Id,
        } : null,
        Supplier_Party_Fk: partsDetail.Supplier_Party_Fk ? {
          label: `[${partsDetail.Supplier.Code}] ${partsDetail.Supplier.Name}`,
          value: partsDetail.Supplier.Id,
        } : null,
        Part_Number: partsDetail.Part_Number || '',
        Part_Description: partsDetail.Part_Description || '',
        Remarks_1: partsDetail.Remarks_1 || '',
        Remarks_2: partsDetail.Remarks_2 || '',
        Remarks_3: partsDetail.Remarks_3 || '',
        Parent_Part_Fk: partsDetail.Parent_Part_Fk ? {
          label: `[${partsDetail.ParentPart.Part_Number}] ${partsDetail.ParentPart.Part_Description}`,
          value: partsDetail.ParentPart.Id,
        } : null,
        Schedule_B: partsDetail.Schedule_B || 0,
        ECCN: partsDetail.ECCN || '',
        CountryOfOrigin_Country_Fk: partsDetail.CountryOfOrigin_Country_Fk ? {
          label: `[${partsDetail.CountryOfOrigin.Code}] ${partsDetail.CountryOfOrigin.Name}`,
          value: partsDetail.CountryOfOrigin.Id,
        } : null,
        Currency_Fk: partsDetail.Currency_Fk ? {
          label: `[${partsDetail.Currency.Code}] ${partsDetail.Currency.Description}`,
          value: partsDetail.Currency.Id,
        } : null,
        Unit_Price: partsDetail.Unit_Price || '',
        Customs_Unit_Price: partsDetail.Customs_Unit_Price || '',
        UnitOfMeasure_Fk: partsDetail.UnitOfMeasure_Fk ? {
          label: `[${partsDetail.UnitOfMeasure.Code}] ${partsDetail.UnitOfMeasure.Description}`,
          value: partsDetail.UnitOfMeasure.Id,
        } : null,
        Active: partsDetail.Active === 1 ? 1 : 0,
      });
    }
  }, [partsDetail]);

  return (
    <ContentStyled style={{ marginTop: 25 }}>
      <CardStyled style={{ backgroundColor: '#fcfcfc' }}>
        <Skeleton loading={getByIdLoading} active>
          <FormStyled
            form={form}
            layout="horizontal"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={formik.handleSubmit}
          >
            <Row>
              <Col sm={24}>
                <Form.Item
                  label="Customer"
                  name="Customer_Party_Fk"
                  rules={[{ required: true, message: 'Required' }]}
                >
                  <SelectParty
                    customFilter={(data) => data.filter((d) => d.Is_Customer === 1)}
                    onChange={(value) => {
                      formik.setFieldValue('Customer_Party_Fk', value);
                      formik.setFieldTouched('Customer_Party_Fk', true);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item
                  label="Supplier"
                  name="Supplier_Party_Fk"
                  rules={[{ required: true, message: 'Required' }]}
                >
                  <SelectParty
                    customFilter={(data) => data.filter((d) => d.Is_Supplier === 1)}
                    onChange={(value) => {
                      formik.setFieldValue('Supplier_Party_Fk', value);
                      formik.setFieldTouched('Supplier_Party_Fk', true);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item
                  label="Part Number"
                  name="Part_Number"
                  rules={[{ required: true, message: 'Required' }]}
                >
                  <Input
                    name="Part_Number"
                    value={formik.values.Part_Number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item
                  label="Part Description"
                  name="Part_Description"
                  rules={[{ required: true, message: 'Required' }]}
                >
                  <Input.TextArea
                    rows={2}
                    name="Part_Description"
                    value={formik.values.Part_Description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item
                  label="Remarks 1"
                  name="Remarks_1"
                >
                  <Input.TextArea
                    rows={2}
                    name="Remarks_1"
                    value={formik.values.Remarks_1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item
                  label="Remarks 2"
                  name="Remarks_2"
                >
                  <Input.TextArea
                    rows={2}
                    name="Remarks_2"
                    value={formik.values.Remarks_2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item
                  label="Remarks 3"
                  name="Remarks_3"
                >
                  <Input.TextArea
                    rows={2}
                    name="Remarks_3"
                    value={formik.values.Remarks_3}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item
                  label="Parent Part"
                  name="Parent_Part_Fk"
                >
                  <SelectParentPart
                    onChange={(value) => {
                      formik.setFieldValue('Parent_Part_Fk', value);
                      formik.setFieldTouched('Parent_Part_Fk', true);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item
                  label="Schedule B/HS Code"
                  name="Schedule_B"
                >
                  <SelectCommodity
                    onChange={(value) => {
                      formik.setFieldValue('Schedule_B', value);
                      formik.setFieldTouched('Schedule_B', true);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item
                  label="ECCN"
                  name="ECCN"
                >
                  <Input
                    name="ECCN"
                    value={formik.values.ECCN}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item
                  label="Country of Origin"
                  name="CountryOfOrigin_Country_Fk"
                  rules={[{ required: true, message: 'Required' }]}
                >
                  <SelectCountry
                    onChange={(value) => {
                      formik.setFieldValue('CountryOfOrigin_Country_Fk', value);
                      formik.setFieldTouched('CountryOfOrigin_Country_Fk', true);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item
                  label="Currency"
                  name="Currency_Fk"
                >
                  <SelectCurrency
                    onChange={(value) => {
                      formik.setFieldValue('Currency_Fk', value);
                      formik.setFieldTouched('Currency_Fk', true);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item
                  label="Unit Price"
                  name="Unit_Price"
                >
                  <Input
                    type="number"
                    value={formik.values.Unit_Price}
                    style={{ width: '50%' }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    precision={2}
                  />
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item
                  label="Customs Unit Price"
                  name="Customs_Unit_Price"
                >
                  <Input
                    type="number"
                    value={formik.values.Customs_Unit_Price}
                    style={{ width: '50%' }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    precision={2}
                  />
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item
                  label="UOM"
                  name="UnitOfMeasure_Fk"
                  rules={[{ required: true, message: 'Required' }]}
                >
                  <SelectUnitOfMeasure
                    onChange={(value) => {
                      formik.setFieldValue('UnitOfMeasure_Fk', value);
                      formik.setFieldTouched('UnitOfMeasure_Fk', true);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item
                  label="Active"
                  name="Active"
                  rules={[{ required: true, message: 'Required' }]}
                >
                  <Radio.Group
                    onChange={(e) => {
                      formik.setFieldValue('Active', e.target.value);
                      formik.setFieldTouched('Active', true);
                    }}
                  >
                    <Radio value={1}>Yes</Radio>
                    <Radio value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </FormStyled>
        </Skeleton>
      </CardStyled>
    </ContentStyled>
  );
}

export default memo(PartsUpdateForm);
