import { gql } from '@apollo/client';

export const INBOUND_JOB_PUTAWAY_GOODS = gql`
    mutation PutawayGoods($data: PutawayGoodsInput!) {
        putawayGoods(data: $data) {
            code
            success
            message
        }
    }`;

export default INBOUND_JOB_PUTAWAY_GOODS;
