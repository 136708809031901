import { gql } from '@apollo/client';

const GET_AVAILABLE_FOR_LOADING_SCAN_IDS = gql`
  query GetAllAvailableForLoadingScanIds($WarehouseOutId: Int!) {
    getAllAvailableForLoadingScanIds(WarehouseOutId: $WarehouseOutId) {
      code
      success
      message
      results {
        Id
        ScanId
        WarehouseIn_JobNumber
        WarehouseOut_JobNumber
        OverpackId_PoPartLocation_Fk
        LabelSeries
        UnitOfMeasure_Fk
        TotalQuantity
        LengthInch
        WidthInch
        HeightInch
        LengthCm
        WidthCm
        HeightCm
        WeightLbs
        WeightKg
        VolumeCbm
        VolumeCft
        Warehouse_Fk
        StorageLocation_Fk
        Is_Overpack
        PickedDate
        PackedBy
        PackedDate
        ConfirmedBy
        ConfirmedDate
        PutawayBy
        PutawayDate
        CreatedBy
        CreatedDate
        AttachedPOLineItemToScanId {
            Id
            LineNumber
            PartNumber_Part_Fk
            Part_Number
            Part_Description
            ExpectedQuantity
            ReceivedQuantity
            AvailableQuantity
            AssignedQuantity
        }
        Warehouse {
            Id
            Name
            Code
        }
        StorageLocation {
            Id
            Code
        }
      }
    }
  }
`;

export default GET_AVAILABLE_FOR_LOADING_SCAN_IDS;
