import { gql } from '@apollo/client';

export const UNLOAD_GOODS_TO_LOAD_PLAN = gql`
    mutation UnloadGoods($data: UnloadGoodsInput!) {
        unloadGoods(data: $data) {
            code
            success
            message
        }
    }`;

export default UNLOAD_GOODS_TO_LOAD_PLAN;
