import { gql } from '@apollo/client';

const UPDATE_USER = gql`
    mutation UpdateUser($updateID: ID!, $updateInput: UserUpdateInput!) {
        updateUser(id: $updateID, data: $updateInput) {
            code
            success
            message
        }
    }
`;

export default UPDATE_USER;
