import { get } from 'lodash';

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

function getInitials(fullName) {
  let initials = '';
  const words = fullName.split(' ');
  for (let i = 0; i < words.length; i++) {
    initials += words[i].charAt(0).toUpperCase();
  }
  return initials;
}

function getOptions(options) {
  return Object.keys(options).map((key) => ({
    value: key, label: options[key],
  }));
}

function filterDropdownList(input, option) {
  return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
}

function parseAndToFixed(value, places = 2) {
  if (!value) return value;

  if (!Number.isNaN(value)) {
    return parseFloat(value).toFixed(places);
  }

  return value;
}

function getAsString(object, path, defaultValue) {
  const value = get(object, path, defaultValue);
  return (value === null || value === undefined) ? defaultValue : value.toString();
}

function getMaxLineNumber(poLineItemList) {
  const lineNumbers = (poLineItemList || []).map((poLineItem) => poLineItem.LineNumber);
  if (lineNumbers.length <= 0) return 0;
  const LineNumber = Math.max(...lineNumbers);
  return !Number.isNaN(LineNumber) ? LineNumber + 1 : 0;
}

export {
  getKeyByValue,
  getInitials,
  getOptions,
  getAsString,
  filterDropdownList,
  parseAndToFixed,
  getMaxLineNumber,
};
