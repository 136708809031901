import { memo } from 'react';

import GET_ALL_CURRENCY from '@graphql/getAllCurrency';

import SelectLazy from '../SelectLazy';

function SelectCurrency(props) {
  const renderOptions = (data) => (data || []).map((d) => ({
    value: d.Id,
    label: `[${d.Code}] ${d.Description}`,
  }));

  const customFilter = (data) => data
    .filter((d) => !d.Code.includes('-'))
    .sort((a, b) => a.Code.localeCompare(b.Code));

  return (
    <SelectLazy
      {...props}
      placeholder="Select Currency"
      customRender={renderOptions}
      customFilter={customFilter}
      query={GET_ALL_CURRENCY}
    />
  );
}

export default memo(SelectCurrency);
