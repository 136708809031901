import { gql } from '@apollo/client';

const GET_PRINT_OVERPACK_SCAN_IDS = gql`
 query GetPrintableOverpackScanIds($Id: Int!) {
    getPrintableOverpackScanIds(WarehouseOutId: $Id) {
      code
      success
      message
      count
      results {
        Id
        ScanId
        LengthInch
        WidthInch
        HeightInch
        LengthCm
        WidthCm
        HeightCm
        HeightCm
        WeightKg
        WeightLbs
        VolumeCbm
        VolumeCft
        WarehouseOut {
          Id
          JobNumber
          JobDate
          JobStatus
          FreightJobNumber
        }
        CustomerParty {
          Id
          Code
          Name
        }
      }
    }
  }
`;

export default GET_PRINT_OVERPACK_SCAN_IDS;
