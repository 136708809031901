import { gql } from '@apollo/client';

export const REOPEN_LOAD_PLAN = gql`
    mutation ReopenLoadPlan($data: ReopenOrFinalizeLoadPlanInput!) {
        reopenLoadPlan(data: $data) {
            code
            success
            message
        }
    }`;

export default REOPEN_LOAD_PLAN;
