import { Select } from 'antd';
import styled from 'styled-components';

const SelectStyled = styled(Select)`
  width: 100%;
  .ant-select-selector {
    border-radius: 4px !important;
    height: 40px !important;
    align-items: center;
  }
`;
export default function CustomSelect({
  onChange, options, value, className, name, selectRef, openModal, disabled,
}) {
  const defaultValue = (options, value) => options.find((option) => option.value === value) || '';

  return (
    <div className={className}>
      {openModal && (
        <SelectStyled
          ref={selectRef}
          value={defaultValue(options, value)}
          onChange={(value) => onChange(value)}
          options={options}
          name={name}
          disabled={disabled}
        />
      )}
    </div>
  );
}
