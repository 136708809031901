import { memo } from 'react';
import {
  Col, DatePicker, Form, Input, InputNumber, Row, Select,
} from 'antd';
import dayjs from 'dayjs';

import useAuthStore from '@store/authStore';

import GET_ALL_PARTIES from '@graphql/TH_getAllParties';
import MODE_OF_TRANSPORT_FOR_DROPDOWN from '@graphql/TH_getModeOfTransportForDropdown';

import { FORMAT_DATE, dateStringToDate, timestampToDate } from '@utils/helpers/date';

import { FormStyled } from '@components/Styles/FormStyles';
import { CardStyled } from '@components/Styles/CardStyles';
import SelectLazy from '@components/SelectLazy';
import SelectPurchaseOrder from '@components/Select/SelectPurchaseOrder';

const { Option } = Select;

function InboundJobForm({
  record, form, onFinish, onValuesChange,
}) {
  const [user] = useAuthStore((state) => [state.user]);

  const isCreate = !record?.Id;

  let initialValues = isCreate ? {
    PersonInCharge: user?.Full_Name,
    JobStatus: 'Open',
    JobDate: dayjs(),
    ArrivalDate: dayjs(),
    CarrierReference: null,
    Carrier_Party_Fk: null,
    ConsigneeReference: null,
    Consignee_Party_Fk: null,
    CustomerReference: null,
    Customer_Party_Fk: null,
    FirstMrPrintedDate: null,
    FreightJobNumber: null,
    ModeOfTransport_Fk: null,
    MrPrintedDate: null,
    OfficeLocation: user?.BranchCode,
    PackageCount: null,
    PurchaseOrder_Fk: null,
    ScanIDCount: null,
    ShipperReference: null,
    Shipper_Party_Fk: null,
    SupplierReference: null,
    Supplier_Party_Fk: null,
  } : record;

  if (!isCreate) {
    initialValues = {
      ...initialValues,
      JobDate: timestampToDate(initialValues.JobDate, true, true),
      ArrivalDate: dateStringToDate(initialValues.ArrivalDate, true),
      FirstMrPrintedDate: timestampToDate(initialValues.FirstMrPrintedDate, true, true),
      MrPrintedDate: timestampToDate(initialValues.MrPrintedDate, true, true),
      Supplier_Party_Fk: record.Supplier_Party_Fk ? {
        label: `[${record.Supplier.Code}] ${record.Supplier.Name}`,
        value: record.Supplier.Id,
      } : null,
      Consignee_Party_Fk: record.Consignee_Party_Fk ? {
        label: `[${record.Consignee.Code}] ${record.Consignee.Name}`,
        value: record.Consignee.Id,
      } : null,
      Customer_Party_Fk: record.Customer_Party_Fk ? {
        label: `[${record.Customer.Code}] ${record.Customer.Name}`,
        value: record.Customer.Id,
      } : null,
      Shipper_Party_Fk: record.Shipper_Party_Fk ? {
        label: `[${record.Shipper.Code}] ${record.Shipper.Name}`,
        value: record.Shipper.Id,
      } : null,
      ModeOfTransport_Fk: record.ModeOfTransport_Fk ? {
        label: `[${record.ModeOfTransport.Code}] ${record.ModeOfTransport.Description}`,
        value: record.ModeOfTransport.Id,
      } : null,
      Carrier_Party_Fk: record.Carrier_Party_Fk ? {
        label: `[${record.Carrier.Code}] ${record.Carrier.Name}`,
        value: record.Carrier.Id,
      } : null,
      PurchaseOrder_Fk: record.PurchaseOrder_Fk ? {
        label: `${record?.PurchaseOrder?.PONumber} (${record?.PurchaseOrder?.CustomerParty?.Name} [${record?.PurchaseOrder?.CustomerParty?.Code}])`,
        value: record.PurchaseOrder?.Id,
      } : null,
    };
  }

  return (
    <FormStyled
      form={form}
      layout="horizontal"
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      initialValues={initialValues}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
    >
      <CardStyled>
        <Row gutter={24}>
          <Col sm={8}>
            <Form.Item
              name="JobNumber"
              label="Inbound Job No."
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item
              name="JobStatus"
              label="Job Status"
            >
              <Select disabled>
                <Option value="Open">Open</Option>
                <Option value="Closed">Closed</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item
              name="JobDate"
              label="Job Date"
            >
              <DatePicker disabled format={FORMAT_DATE} placeholder="" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="OfficeLocation"
              label="Office/Location"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="PersonInCharge"
              label="Person In Charge"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </CardStyled>
      <CardStyled>
        <Row gutter={24}>
          <Col sm={8}>
            <Form.Item
              name="PurchaseOrder_Fk"
              label="P.O. No."
            >
              <SelectPurchaseOrder
                disabled={!isCreate}
              />
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item
              name="Consignee_Party_Fk"
              label="Consignee"
            >
              <SelectLazy
                disabled={!isCreate}
                valueKey="Id"
                label="Name"
                query={GET_ALL_PARTIES}
                customFilter={(data) => (data || []).filter((d) => d.Is_Consignee === 1)}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="FreightJobNumber"
              label="Freight Job No."
            >
              <Input />
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item
              name="ModeOfTransport_Fk"
              label="Mode of Transport"
            >
              <SelectLazy
                valueKey="Id"
                label="Code"
                query={MODE_OF_TRANSPORT_FOR_DROPDOWN}
              />
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item
              name="ConsigneeReference"
              label="Consignee Reference"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item
              name="ArrivalDate"
              label="Arrival Date"
            >
              <DatePicker format={FORMAT_DATE} />
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item
              name="Customer_Party_Fk"
              label="Customer"
            >
              <SelectLazy
                disabled={!record?.IsCustomerEditable}
                valueKey="Id"
                label="Name"
                query={GET_ALL_PARTIES}
                customFilter={(data) => (data || []).filter((d) => d.Is_Customer === 1)}
              />
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item
              name="Shipper_Party_Fk"
              label="Shipper"
            >
              <SelectLazy
                disabled={!isCreate}
                valueKey="Id"
                label="Name"
                query={GET_ALL_PARTIES}
                customFilter={(data) => (data || []).filter((d) => d.Is_Shipper === 1)}
              />
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item
              name="PackageCount"
              label="Package Count"
            >
              <InputNumber disabled={!isCreate} />
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item
              name="CustomerReference"
              label="Customer Reference"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item
              name="ShipperReference"
              label="Shipper Reference"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item
              name="ScanIDCount"
              label="Scan ID Count"
            >
              <InputNumber disabled />
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item
              name="Supplier_Party_Fk"
              label="Supplier"
            >
              <SelectLazy
                valueKey="Id"
                label="Name"
                query={GET_ALL_PARTIES}
                customFilter={(data) => (data || []).filter((d) => d.Is_Supplier === 1)}
              />
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item
              name="Carrier_Party_Fk"
              label="Carrier"
            >
              <SelectLazy
                valueKey="Id"
                label="Name"
                query={GET_ALL_PARTIES}
                customFilter={(data) => (data || []).filter((d) => d.Is_Carrier === 1)}
              />
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item
              name="FirstMrPrintedDate"
              label="First MR Printed Date"
            >
              <DatePicker disabled format={FORMAT_DATE} placeholder="" />
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item
              name="SupplierReference"
              label="Supplier Reference"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item
              name="CarrierReference"
              label="Carrier Reference"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item
              name="MrPrintedDate"
              label="MR Printed Date"
            >
              <DatePicker disabled format={FORMAT_DATE} placeholder="" />
            </Form.Item>
          </Col>
        </Row>
      </CardStyled>
    </FormStyled>
  );
}

export default memo(InboundJobForm);
