import { useEffect, useState } from 'react';

import { showNotification } from '@utils/helpers/notifications';

const useLogo = ({
  logoUrl,
}) => {
  const [logo, setLogo] = useState(null);
  const [file, setFile] = useState(null);

  const convertPngToFile = async (logoUrl) => {
    try {
      let filename = null;
      if (logoUrl) {
        filename = logoUrl;
      } else {
        setLogo(null);
        return null;
      }
      const response = await fetch(filename);
      const blob = await response.blob();
      const filenameArr = filename.split('.');
      const extension = filenameArr[filenameArr.length - 1];
      const file = new File([blob], `image.${extension}`, { type: `image/${extension}` });
      setFile(file);
    } catch (err) {
      showNotification('error', 'Error', 'Error loading image');
    }
  };

  async function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }

  useEffect(() => {
    const processLogo = async (file) => {
      await getBase64(file)
        .then((res) => {
          setLogo(res);
        })
        .catch(() => {
          showNotification('error', 'Error', 'Error loading image');
        });
    };

    if (file) {
      processLogo(file);
    }
  }, [file]);

  useEffect(() => {
    convertPngToFile(logoUrl);
  }, [logoUrl]);

  return logo;
};

export default useLogo;
