import useAuthStore from '@store/authStore';

const usePermissions = () => {
  const user = useAuthStore((state) => state.user);

  switch (user?.role) {
    case 'SUPERADMIN':
      return { isAdmin: true };
    default:
      return { isAdmin: false };
  }
};

export default usePermissions;
