import React, { useState } from 'react';

import { ContentStyled } from '@components/Styles/Global';
import { TableStyled } from '@components/Styles/TableStyles';

function UnitMeasure() {
  const [filteredInfo] = useState({});
  const [sortedInfo] = useState({});

  const data = [
    {
      key: '1',
      code: '1',
      description: 'Actual Pounds',
      remarks: '',

    },
    {
      key: '2',
      code: '2',
      description: 'Statute Mile',
      remarks: '',
    },
    {
      key: '3',
      code: '3',
      description: 'Seconds',
      remarks: '',
    },
  ];
  const loading = false;

  const columns = [
    {
      title: 'Id',
      dataIndex: 'key',
      key: 'key',

      filteredValue: filteredInfo.name || null,
      onFilter: (value, record) => record.name.includes(value),
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',

    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',

    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
    },
  ];

  return (
    <ContentStyled>
      <TableStyled
        size="small"
        loading={loading}
        dataSource={data}
        columns={columns}
        pagination
        scroll={{ x: true }}
      />
    </ContentStyled>
  );
}

export default UnitMeasure;
