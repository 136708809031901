import {
  memo, useEffect, useState, useRef,
} from 'react';

import { useLocation } from 'react-router-dom';
import { get } from 'lodash';

import { useMutation, useQuery } from '@apollo/client';
import { Content } from 'antd/es/layout/layout';

import { UploadOutlined } from '@ant-design/icons';
import {
  Form, Row, Col, Input, Typography, Button, Modal,
} from 'antd';
import * as Yup from 'yup';
import styled from 'styled-components';

import SignatureCanvas from 'react-signature-canvas';

import MY_PROFILE from '@graphql/myProfile';
import UPDATE_USER from '@graphql/updateUser';

import { showNotification } from '@utils/helpers/notifications';

import { useFormik } from 'formik';

import styles from './myProfilePage.module.css';

const FormItemStyled = styled(Form.Item)`
    margin-bottom: 52px !important;
  
    .ant-form-item-row  {
      flex-direction: column;
      justify-content: flex-start;
    }
  
    .ant-form-item-label {
      text-align: left;
      margin-bottom: 12px;
      font-weight: bold;
    }
  `;

const InputStyled = styled(Input)`
    border-radius: 4px !important;
  `;

const ErrorText = styled(Typography.Paragraph)`
    color: red;
    font-size: 12px;
  `;

function MyProfilePage() {
  const [profileData, setProfileData] = useState({});
  const [signatureImg, setSignatureImg] = useState('');
  const [signModal, setSignModal] = useState(false);

  const { loading, refetch } = useQuery(MY_PROFILE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const getProf = get(data, 'me.user', {});
      setProfileData(getProf);
    },
  });

  const [updateUser] = useMutation(UPDATE_USER);

  const location = useLocation();
  const recordData = location.state?.user;

  const signatureRef = useRef();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Required'),
    role: Yup.string().required('Required'),
    fullname: Yup.string().required('Required'),
    phone: Yup.string().required('Required'),
    company: Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      fullname: profileData.fullname,
      email: profileData.email,
      role: profileData.role,
      company: profileData.company,
      phone: profileData.phone,
      signature: signatureImg || profileData.signature,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      try {
        await updateUser({
          variables: {
            updateInput: {
              email: (values.email) ? values.email : document.getElementById('email').value,
              role: (values.role) ? values.role : profileData?.me?.user?.role,
              fullname: (values.fullname) ? values.fullname : document.getElementById('fullname').value,
              phone: (values.phone) ? values.phone : document.getElementById('phone').value,
              company: (values.company) ? values.company : document.getElementById('company').value,
              signature: signatureImg || profileData.signature,
            },
            updateID: recordData._id,
          },
        });
        showNotification('success', 'User Updated', 'The user has been successfully updated');
      } catch (error) {
        showNotification('error', error.message, '');
      }
    },
  });

  const onSignChange = async (signImg) => {
    setSignatureImg(signImg);
  };

  const clear = () => signatureRef.current.clear();

  const onSaveSignature = async () => {
    const canvas = signatureRef.current;
    const dataUrl = canvas.toDataURL('image/png');
    onSignChange(dataUrl);
    setSignModal(false);
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Content style={{ margin: '0px 25px 25px' }}>
      {!loading && (
        <Form
          initialValues={{
            fullname: profileData.fullname,
            email: profileData.email,
            company: profileData.company,
            phone: profileData.phone,
            role: profileData.role,
            signature: profileData.signature,
          }}
          onFinish={formik.handleSubmit}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <FormItemStyled
                label="Fullname"
                name="fullname"
                colon={false}
              >
                <InputStyled
                  size="large"
                  name="fullname"
                  placeholder="Fullname"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </FormItemStyled>
              {formik.touched.fullname && formik.errors.fullname ? (
                <ErrorText>{formik.errors.fullname}</ErrorText>
              ) : null}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <FormItemStyled
                label="Email"
                name="email"
                colon={false}
              >
                <InputStyled
                  size="large"
                  name="email"
                  placeholder="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  disabled
                />
              </FormItemStyled>
              {formik.touched.email && formik.errors.email ? (
                <ErrorText>{formik.errors.email}</ErrorText>
              ) : null}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <FormItemStyled
                label="Company"
                name="company"
                colon={false}
              >
                <InputStyled
                  size="large"
                  name="company"
                  placeholder="company"
                  onChange={formik.handleChange}
                  value={formik.values.company}
                  onBlur={formik.handleBlur}
                />
              </FormItemStyled>
              {formik.touched.company && formik.errors.company ? (
                <ErrorText>{formik.errors.company}</ErrorText>
              ) : null}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <FormItemStyled
                label="Phone"
                name="phone"
                colon={false}
              >
                <InputStyled
                  size="large"
                  name="phone"
                  placeholder="phone"
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                  onBlur={formik.handleBlur}
                />
              </FormItemStyled>
              {formik.touched.phone && formik.errors.phone ? (
                <ErrorText>{formik.errors.phone}</ErrorText>
              ) : null}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <FormItemStyled
                label="Role"
                name="role"
                colon={false}
              >
                <InputStyled
                  size="large"
                  name="role"
                  placeholder="role"
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                  onBlur={formik.handleBlur}
                  disabled
                />
              </FormItemStyled>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Button
                icon={<UploadOutlined />}
                className="form-control"
                size="large"
                onClick={() => setSignModal(true)}
              >
                Update Signature
              </Button>
              <Modal
                visible={signModal}
                onCancel={() => setSignModal(false)}
                title="Draw Signature"
                onOk={onSaveSignature}
                okText="Save"
              >
                <div className={styles.sigContainer}>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{
                      width: 500,
                      height: 200,
                      className: 'sigCanvas',
                      style: { background: 'rgba(0,0,0,0)' },
                    }}
                    ref={signatureRef}
                  />
                </div>
                <div>
                  <Button className={styles.buttons} onClick={clear}>
                    Clear
                  </Button>
                </div>
              </Modal>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              {profileData.signature || signatureImg
                ? (
                  <img
                    alt="signatureImg"
                    style={{
                      height: '300px',
                      width: '400px',
                    }}
                    className={styles.sigImage}
                    src={signatureImg || profileData.signature}
                  />
                )
                : null}
            </Col>
          </Row>

          <Row style={{ justifyContent: 'flex-end', marginTop: '70px' }}>
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled>
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>

        </Form>
      )}
    </Content>
    // </Spin>
  );
}

export default memo(MyProfilePage);
