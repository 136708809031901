import { Form } from 'antd';

import styled from 'styled-components';

export const FormStyled = styled(Form)`
  max-width: none;
  padding: 12;

  & .ant-form-item {
    margin-bottom: 12px !important;

    & .ant-form-item-label {
      & > label {
        font-weight: bold;
      }
    }
  }
`;
