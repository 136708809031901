import { Button, Tooltip } from 'antd';
import { memo } from 'react';

function ButtonTooltip({ tooltipProps, ...props }) {
  return (
    <Tooltip {...tooltipProps}>
      <Button {...props} />
    </Tooltip>
  );
}

export default memo(ButtonTooltip);
