import { Font } from '@react-pdf/renderer';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: '/src/assets/fonts/Roboto-Regular.ttf',
      fontWeight: 400,
    },
    {
      src: '/src/assets/fonts/Roboto-Bold.ttf',
      fontWeight: 700,
    },
  ],
});

export default Font;
