import { gql } from '@apollo/client';

export const DISPATCH_SCAN_ID = gql`
    mutation DispatchGoods($data: DispatchUndispatchedInput!) {
        dispatchGoods(data: $data) {
            code
            success
            message
        }
    }`;

export default DISPATCH_SCAN_ID;
