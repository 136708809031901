import { gql } from '@apollo/client';

const GET_ALL_COMMODITY = gql`
    query GET_ALL_COMMODITIES {
        getAllCommodity {
            code
            success
            message
            results {
                Commodity
                Descrip_1
                Descrip_2
                Quantity_1
                Quantity_2
                SITC
                End_Use
                USDA
                NAICS
                HITECH
                Active
            }
        }
    }
  `;

export default GET_ALL_COMMODITY;
