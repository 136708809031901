import { gql } from '@apollo/client';

export const INBOUND_JOB_UNCONFIRM_GOODS = gql`
    mutation UnconfirmGoods($data: UnconfirmGoodsInput!) {
        unconfirmGoods(data: $data) {
            code
            success
            message
        }
    }`;

export default INBOUND_JOB_UNCONFIRM_GOODS;
