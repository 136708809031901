import React from 'react';
import logo from '@assets/starlogo.png';
import './POPrinting.css';

import { Content } from 'antd/es/layout/layout';
import {
  Card,
  Col,
  Row,
  Divider,
} from 'antd';

function POPrinting() {
  return (

    <div>
      <header>
        <table className="header-table">
          <tbody>
            <tr>
              <td>
                <img src={logo} alt="logo" />
                <br />
                Star Global Logistics Group
                {' '}
                <br />
                {' '}
                5244 N Sam Houston Pkwy E
                Houston, TX
                (832)-295-3609
              </td>

              <td>printed by</td>
            </tr>
          </tbody>
        </table>

      </header>

      <Content>

        <Divider style={{ opacity: '100%' }} />

        <Card>

          <Row gutter={16}>
            <Col span={9}>

              <img src={logo} alt="logo" />
            </Col>

            <Col span={15} />
          </Row>

          {/* Add more form items for the remaining fields */}

        </Card>
      </Content>

    </div>

  );
}

export default POPrinting;
