import { memo } from 'react';

import GET_ALL_PARTIES from '@graphql/TH_getAllParties';

import SelectLazy from '../SelectLazy';

function SelectParty({ customRenderOptions, ...props }) {
  const renderOptions = (data) => (data || []).map((d) => ({
    value: d.Id,
    label: `[${d.Code}] ${d.Name}`,
  }));

  return (
    <SelectLazy
      {...props}
      placeholder="Select Party"
      customRender={customRenderOptions || renderOptions}
      query={GET_ALL_PARTIES}
    />
  );
}

export default memo(SelectParty);
