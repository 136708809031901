import { gql } from '@apollo/client';

const GET_PURCHASE_ORDER_BY_ID = gql`
    query GetPurchaseOrderById($Id: Int!) {
        getPurchaseOrderById(Id: $Id) {
            code
            success
            message
            result {
                Id
                PONumber
                PODate
                POStatus
                POType
                # RigCode
                Customer_Party_Fk
                Supplier_Party_Fk
                Shipper_Party_Fk
                Consignee_Party_Fk
                ModeOfTransport_Fk
                PortOfLoading_Location_Fk
                PortOfDischarge_Location_Fk
                FinalDestination_Location_Fk
                ProjectAFECode
                HarmonizedCode
                CustomerReference
                SupplierReference
                ShipperReference
                ConsigneeReference
                PaymentTerm_Fk
                FreightTerm
                RequiredDate
                PlannedPickupDate
                ActualPickupDate
                POReceivedDate
                FirstReceivedDate
                LastReceivedDate
                CompletionDate
                ReferenceText1
                ReferenceText2
                ReferenceText3
                Remarks
                CreatedBy
                UpdatedBy
                UpdatedDate
                
                CustomerParty {
                    Id
                    Name
                    Code
                }
                SupplierParty {
                    Id
                    Name
                    Code
                }
                ShipperParty {
                    Id
                    Name
                    Code
                }
                ConsigneeParty {
                    Id
                    Name
                    Code
                }
                ModeOfTransport {
                    Id
                    Code
                    Description
                }
                PortOfLoading {
                    Id
                    Name
                    City_Code
                }
                PortOfDischarge {
                    Id
                    Name
                    City_Code
                }
                FinalDestination {
                    Id
                    Name
                    City_Code
                }
                PaymentTerm {
                    Id
                    Payment_Term
                }
            }
        }
    }
`;

export default GET_PURCHASE_ORDER_BY_ID;
