import { gql } from '@apollo/client';

const GET_ALL_OSD_REASON = gql`
    query OSDReason{
    getAllOSDReason {
        code
        success
        message
        results {
        Id
        Osd_Reason
        }
    }
    }
`;

export default GET_ALL_OSD_REASON;
