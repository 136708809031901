const inchToCm = 2.54;
const cmToInch = 0.393701;
const kgToPounds = 2.20462;
const poundsToKg = 0.453592;
const cbm = 0.01;
const cft = 0.0833333;

export const convertInchToCm = (value) => value * inchToCm;
export const convertCmToInch = (value) => value * cmToInch;
export const convertKgToPounds = (value) => value * kgToPounds;
export const convertPoundsToKg = (value) => value * poundsToKg;
export const getVolumeCbm = (length, width, height) => (length * cbm) * (width * cbm) * (height * cbm);
export const getVolumeCft = (length, width, height) => (length * cft) * (width * cft) * (height * cft);
