import React, { useCallback, useState } from 'react';
import { Tag } from 'antd';

import GET_ALL_PARTIES from '@graphql/TH_getAllParties';

import useList from '@hooks/useList';

import { ActionButton } from '@components/Styles/ButtonStyles';
import { ContentStyled } from '@components/Styles/Global';
import { TableStyled } from '@components/Styles/TableStyles';
import { ModalStyled } from '@components/Styles/ModalStyles';
import { PlusOutlined } from '@ant-design/icons';
import PartyAddForm from './PartyAddForm';
import PartyUpdateForm from './PartyUpdateForm';

function PartyList() {
  const [openAddPartyModal, setOpenAddPartyModal] = useState(false);
  const [openUpdatePartyModal, setOpenUpdatePartyModal] = useState(false);
  const [selectedParty, setSelectedParty] = useState(null);

  const { data, loading, refetch } = useList({ query: GET_ALL_PARTIES });

  const handleSuccess = useCallback(() => {
    refetch();
    setOpenAddPartyModal(false);
    setOpenUpdatePartyModal(false);
  }, []);

  const handleSuccessUpdate = useCallback(() => {
    refetch();
  }, []);

  const handleCancelAdd = useCallback(() => {
    setOpenAddPartyModal(false);
  }, []);

  const handleOpenAdd = useCallback(() => {
    setOpenAddPartyModal(true);
  }, []);

  const handleOpenUpdate = useCallback(() => {
    setOpenUpdatePartyModal(true);
  }, []);

  const handleCancelUpdate = useCallback(() => {
    setOpenUpdatePartyModal(false);
  }, []);

  const columns = [
    {
      title: 'Code',
      dataIndex: 'Code',
      key: 'Code',
      defaultSortOrder: 'none',
      render: (text, record) => (
        <a
          style={{ userSelect: 'text' }}
          onClick={() => {
            setSelectedParty(record);
            handleOpenUpdate();
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Website',
      dataIndex: 'Website',
      key: 'Website',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Parent Party',
      dataIndex: 'Parent_Party_Fk',
      key: 'Parent_Party_Fk',
      render: (text) => {
        if (!text) {
          return (
            <span style={{ userSelect: 'text' }}>
              N/A
            </span>
          );
        }
        const party = data.find((part) => part.Id === text);

        return (
          <span style={{ userSelect: 'text' }}>
            [
            {party?.Code}
            ] -
            {party?.Name}
          </span>
        );
      },
    },
    {
      title: 'Is Customer?',
      dataIndex: 'Is_Customer',
      key: 'Is_Customer',
      render: (text) => {
        if (text === 1) {
          return (
            <Tag color="green">
              Yes
            </Tag>
          );
        }
        return (
          <Tag color="red">
            No
          </Tag>
        );
      },
    },
    {
      title: 'Is Billing Party?',
      dataIndex: 'Is_Billing_Party',
      key: 'Is_Billing_Party',
      render: (text) => {
        if (text === 1) {
          return (
            <Tag color="green">
              Yes
            </Tag>
          );
        }
        return (
          <Tag color="red">
            No
          </Tag>
        );
      },
    },
    {
      title: 'Is Supplier?',
      dataIndex: 'Is_Supplier',
      key: 'Is_Supplier',
      render: (text) => {
        if (text === 1) {
          return (
            <Tag color="green">
              Yes
            </Tag>
          );
        }
        return (
          <Tag color="red">
            No
          </Tag>
        );
      },
    },
    {
      title: 'Is Shipper?',
      dataIndex: 'Is_Shipper',
      key: 'Is_Shipper',
      render: (text) => {
        if (text === 1) {
          return (
            <Tag color="green">
              Yes
            </Tag>
          );
        }
        return (
          <Tag color="red">
            No
          </Tag>
        );
      },
    },
    {
      title: 'Is Consignee?',
      dataIndex: 'Is_Consignee',
      key: 'Is_Consignee',
      render: (text) => {
        if (text === 1) {
          return (
            <Tag color="green">
              Yes
            </Tag>
          );
        }
        return (
          <Tag color="red">
            No
          </Tag>
        );
      },
    },
    {
      title: 'Is Carrier?',
      dataIndex: 'Is_Carrier',
      key: 'Is_Carrier',
      render: (text) => {
        if (text === 1) {
          return (
            <Tag color="green">
              Yes
            </Tag>
          );
        }
        return (
          <Tag color="red">
            No
          </Tag>
        );
      },
    },
    {
      title: 'Is NotifyParty?',
      dataIndex: 'Is_NotifyParty',
      key: 'Is_NotifyParty',
      render: (text) => {
        if (text === 1) {
          return (
            <Tag color="green">
              Yes
            </Tag>
          );
        }
        return (
          <Tag color="red">
            No
          </Tag>
        );
      },
    },
    {
      title: 'Active',
      dataIndex: 'Active',
      key: 'Active',
      render: (text) => {
        if (text === 1) {
          return (
            <Tag color="green">
              ACTIVE
            </Tag>
          );
        }
        return (
          <Tag color="red">
            INACTIVE
          </Tag>
        );
      },
    },
  ];

  return (
    <ContentStyled>
      <ActionButton type="default" onClick={handleOpenAdd} icon={<PlusOutlined />} />
      <TableStyled
        size="small"
        loading={loading}
        dataSource={data}
        columns={columns}
        pagination
        scroll={{ x: true }}
      />
      {openAddPartyModal && (
        <ModalStyled
          title="Add New Party"
          open={openAddPartyModal}
          onCancel={handleCancelAdd}
          footer={null}
          maskClosable={false}
          keyboard={false}
        >
          <PartyAddForm onSuccess={handleSuccess} />
        </ModalStyled>
      )}
      {openUpdatePartyModal && (
        <ModalStyled
          title="Update Party"
          open={openUpdatePartyModal}
          onCancel={handleCancelUpdate}
          footer={null}
          maskClosable={false}
          keyboard={false}
        >
          <PartyUpdateForm selectedRow={selectedParty} onSuccess={handleSuccessUpdate} />
        </ModalStyled>
      )}
    </ContentStyled>
  );
}

export default PartyList;
