import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { handleApolloResponse } from '@utils/helpers/apolloResponse';

const useListRelatedId = ({
  query,
}) => {
  const [value, setValue] = useState(null);

  const response = useQuery(query, {
    skip: !value,
    variables: { Id: value },
  });

  const data = handleApolloResponse(response, 'results');

  return { ...response, data, filters: { setValue } };
};

export default useListRelatedId;
