import React, { memo, useCallback } from 'react';

import { Result } from 'antd';
import { useNavigate } from 'react-router-dom';

import { ButtonRounded, ContentCentered } from '@components/Styles/Global';

function Error404() {
  const navigate = useNavigate();
  const handleBack = useCallback(() => {
    navigate('/');
  }, []);
  return (
    <ContentCentered style={{ background: '#fff' }}>
      <Result
        status="404"
        title="Oops! Page not found"
        subTitle="Sorry, the page you visited does not exist."
        extra={<ButtonRounded block size="large" type="primary" onClick={handleBack}>Go Back</ButtonRounded>}
      />
    </ContentCentered>
  );
}

export default memo(Error404);
