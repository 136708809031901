import { memo } from 'react';

import GET_ALL_LOCATIONS from '@graphql/getAllLocations';
import SelectLazy from '../SelectLazy';

function SelectLocation(props) {
  const renderOptions = (data) => (data || []).map((d) => ({
    value: d.Id,
    label: `[${d.City_Code}] ${d.Name}`,
  }));

  return (
    <SelectLazy
      {...props}
      customRender={renderOptions}
      query={GET_ALL_LOCATIONS}
      lazySearch
    />
  );
}

export default memo(SelectLocation);
