import { memo } from 'react';

import GET_ALL_COUNTRIES from '@graphql/getAllCountries';

import SelectLazy from '../SelectLazy';

function SelectLocation(props) {
  const renderOptions = (data) => (data || []).map((d) => ({
    value: d.Id,
    label: `[${d.Code}] ${d.Name}`,
  }));

  return (
    <SelectLazy
      {...props}
      placeholder="Select Country"
      customRender={renderOptions}
      query={GET_ALL_COUNTRIES}
    />
  );
}

export default memo(SelectLocation);
