import { memo } from 'react';

import MODE_OF_TRANSPORT from '@graphql/TH_getModeOfTransport';

import SelectLazy from '../SelectLazy';

function SelectModeOfTransport(props) {
  const renderOptions = (data) => (data || []).map((d) => ({
    value: d.Id,
    label: `[${d.Code}] ${d.Description}`,
  }));

  return (
    <SelectLazy
      {...props}
      placeholder="Select Mode of Transport"
      customRender={renderOptions}
      query={MODE_OF_TRANSPORT}
    />
  );
}

export default memo(SelectModeOfTransport);
