export const parseScanIdStatus = (ConfirmedDate, PutawayDate) => {
  if (!ConfirmedDate && !PutawayDate) {
    return 'OPEN';
  }
  if (PutawayDate && !ConfirmedDate) {
    return 'PUTAWAY';
  }
  if (ConfirmedDate) {
    return 'CONFIRMED';
  }
};
