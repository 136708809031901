import { gql } from '@apollo/client';

const CANCEL_WAREHOUSE_INBOUND_JOB = gql`
    mutation CancelWarehouseInboundJob($id: Int!) {
        cancelWarehouseInboundJob(id: $id) {
            code
            success
            message
        }
    }
`;

export default CANCEL_WAREHOUSE_INBOUND_JOB;
