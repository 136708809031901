import { gql } from '@apollo/client';

const MY_PROFILE = gql`
  query MyProfile {
    me {
      code
      success
      user {
        fullname
        email
        image
        phone
        company
        role
        accountStatus
        signature
        loginAttempts
        created_at
        updated_at
        deleted_at
        _id
        signature
      }
    }
  }
`;
export default MY_PROFILE;
