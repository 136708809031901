import { gql } from '@apollo/client';

const GET_OVERPACK_SCAN_IDS_BY_WAREHOUSE_OUT_AND_CUSTOMER = gql`
  query GetOverpackScanIdsByWarehouseOutAndCustomer($WarehouseOutId: Int!) {
    getOverpackScanIdsByWarehouseOutAndCustomer(WarehouseOutId: $WarehouseOutId) {
      code
      success
      message
      results {
        Id
        ScanId
      }
    }
  }
`;

export default GET_OVERPACK_SCAN_IDS_BY_WAREHOUSE_OUT_AND_CUSTOMER;
