import React, { memo, useCallback, useState } from 'react';
import {
  Flex, InputNumber, Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import styled from 'styled-components';

import { CardStyled } from '@components/Styles/CardStyles';
import { TableSmall, TableSubSmall } from '@components/Styles/TableStyles';
import { EditFilled } from '@ant-design/icons';

const ContentFullHeight = styled(Content)`
  padding: 16px;
  height: 100%;
  background: #eee;
  border-radius: 4px;

  & .ant-row, & .ant-col, & .ant-card {
    height: 100% !important;
  }
`;

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
`;

function AutoDistributionTransfer() {
  const [selectedRowKeys] = useState([]);

  const [poFormData, setPoFormData] = useState({

  });

  const handleChange = useCallback((val, key) => {
    setPoFormData({
      ...poFormData,
      [key]: val,
    });
  }, [poFormData]);

  const scanIdData = [
    {
      key: '1',
      ScanId: '123456',
      Status: 'Completed',
      TotalQuantity: 10,
      Warehouse: 'Warehouse A',
      StorageLocation: 'A1',
      LabelSeries: 'Series 1',
      DimensionsIn: '10x10x10',
      DimensionsCm: '25x25x25',
      VolumeCbm: 0.625,
      VolumeCft: 22.07,
      WeightKg: 5,
      WeightLbs: 11.02,
      LineItems: [
        {
          key: '1-1',
          LineItemNo: '001',
          PartNo: 'ABC123',
          PartDesc: 'Widget',
          AssignedQuantity: 10,
        },
        {
          key: '1-2',
          LineItemNo: '002',
          PartNo: 'XYZ456',
          PartDesc: 'Gadget',
          AssignedQuantity: 5,
        },
      ],
    },
    {
      key: '2',
      ScanId: '41234',
      Status: 'Completed',
      TotalQuantity: 10,
      Warehouse: 'Warehouse B',
      StorageLocation: 'B1',
      LabelSeries: 'Series 1',
      DimensionsIn: '13x14x13',
      DimensionsCm: '25x25x25',
      VolumeCbm: 1.625,
      VolumeCft: 12.07,
      WeightKg: 2,
      WeightLbs: 4.02,
      LineItems: [
        {
          key: '2-1',
          LineItemNo: '001',
          PartNo: 'ABC123',
          PartDesc: 'Widget',
          AssignedQuantity: 10,
        },
        {
          key: '2-2',
          LineItemNo: '002',
          PartNo: 'XYZ456',
          PartDesc: 'Gadget',
          AssignedQuantity: 5,
        },
      ],
    },
  ];

  const scanIdColumns = [
    {
      title: 'Scan ID',
      dataIndex: 'ScanId',
      key: 'ScanId',
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
    },
    {
      title: 'Total Quantity',
      dataIndex: 'TotalQuantity',
      key: 'TotalQuantity',
    },
    {
      title: 'Label Series',
      dataIndex: 'LabelSeries',
      key: 'LabelSeries',
    },
    {
      title: 'Dimensions (INCH)',
      dataIndex: 'DimensionsIn',
      key: 'DimensionsIn',
    },
    {
      title: 'Dimensions (CM)',
      dataIndex: 'DimensionsCm',
      key: 'DimensionsCm',
    },
    {
      title: 'Volume (CBM)',
      dataIndex: 'VolumeCbm',
      key: 'VolumeCbm',
    },
    {
      title: 'Weight (KG)',
      dataIndex: 'WeightKg',
      key: 'WeightKg',
    },
    {
      title: 'Weight (LBS)',
      dataIndex: 'WeightLbs',
      key: 'WeightLbs',
    },
  ];

  const lineItemColumns = [
    {
      title: 'P.O. No.',
      dataIndex: 'PoNumber',
      key: 'PoNumber',
    },
    {
      title: 'Line Item No.',
      dataIndex: 'LineItemNo',
      key: 'LineItemNo',
    },
    {
      title: 'Part No.',
      dataIndex: 'PartNo',
      key: 'PartNo',
    },
    {
      title: 'Part Description.',
      dataIndex: 'PartDesc',
      key: 'PartDesc',
    },
    {
      title: 'Assigned Quantity',
      dataIndex: 'AssignedQuantity',
      key: 'AssignedQuantity',
    },
  ];

  const lineItemReceiveColumns = [
    {
      title: 'PO No.',
      dataIndex: 'PoNumber',
      key: 'PoNumber',
    },
    {
      title: 'Line Item No.',
      dataIndex: 'LineItemNo',
      key: 'LineItemNo',
    },
    {
      title: 'Part No.',
      dataIndex: 'PartNo',
      key: 'PartNo',
    },
    {
      title: 'Part Description.',
      dataIndex: 'PartDesc',
      key: 'PartDesc',
    },
    {
      title: 'Expected Quantity',
      dataIndex: 'ExpectedQuantity',
      key: 'ExpectedQuantity',
    },
    {
      title: 'Received Quantity',
      dataIndex: 'ReceivedQuantity',
      key: 'ReceivedQuantity',
    },
    {
      title: 'Line Status',
      dataIndex: 'LineStatus',
      key: 'LineStatus',
    },
    {
      title: 'Total Quantity to Assign',
      dataIndex: 'TotalQuantityAssign',
      key: 'TotalQuantityAssign',
      width: 200,
      render: (_, record) => <InputNumberStyled size="small" onChange={(val) => handleChange(val, record.key)} />,
    },
    {
      title: 'Quantity per Scan ID',
      dataIndex: 'QuantityPerScanId',
      key: 'QuantityPerScanId',
      width: 200,
      render: (_, record) => <InputNumberStyled size="small" onChange={(val) => handleChange(val, record.key)} />,
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: () => (
        <a>
          <EditFilled />
          {' '}
          Distribute
        </a>
      ),
    },
  ];

  const lineItemAvailableData = [
    {
      key: '1',
      PoNumber: 'PO-001',
      LineItemNo: '1',
      PartNo: 'P1234',
      PartDesc: 'Widget A',
      ExpectedQuantity: 100,
      ReceivedQuantity: 90,
      LineStatus: 'Received',
    },
    {
      key: '2',
      PoNumber: 'PO-001',
      LineItemNo: '2',
      PartNo: 'P5678',
      PartDesc: 'Widget B',
      ExpectedQuantity: 50,
      ReceivedQuantity: 50,
      LineStatus: 'Received',
    },
    {
      key: '3',
      PoNumber: 'PO-002',
      LineItemNo: '1',
      PartNo: 'P9012',
      PartDesc: 'Widget C',
      ExpectedQuantity: 75,
      ReceivedQuantity: 60,
      LineStatus: 'Received',
    },
  ];

  const expandedRowRender = ({ LineItems }) => (
    <div style={{ padding: '18px 12px', backgroundColor: '#eee' }}>
      <TableSubSmall
        size="small"
        dataSource={LineItems}
        columns={lineItemColumns}
        pagination={false}
        scroll={{
          x: true,
        }}
      />
    </div>
  );

  return (
    <ContentFullHeight>
      <Flex vertical gap="small" style={{ height: '100%' }}>
        <CardStyled style={{ flexGrow: 1, overflowY: 'auto' }}>
          <Typography.Title level={5}>P.O. Line Items</Typography.Title>
          <TableSmall
            style={{ marginTop: 24 }}
            size="small"
            dataSource={lineItemAvailableData}
            columns={lineItemReceiveColumns}
            pagination={false}
            scroll={{
              x: true,
            }}
          />
        </CardStyled>
        <CardStyled style={{ flexGrow: 1, overflowY: 'auto' }}>
          <Typography.Title level={5}>Scan IDs</Typography.Title>
          <TableSmall
            style={{ marginTop: 24 }}
            size="small"
            dataSource={scanIdData}
            columns={scanIdColumns}
            pagination={false}
            scroll={{
              x: true,
            }}
            selectedRowKeys={selectedRowKeys}
            expandable={{
              defaultExpandAllRows: true,
              expandedRowRender,
              rowExpandable: (record) => record.name !== 'Not Expandable',
            }}
          />
        </CardStyled>
      </Flex>
    </ContentFullHeight>
  );
}

export default memo(AutoDistributionTransfer);
