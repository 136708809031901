import { memo, useEffect } from 'react';
import {
  Col, DatePicker, Form, Input, Row,
} from 'antd';

import { FormStyled } from '@components/Styles/FormStyles';
import { timestampToDate } from '@utils/helpers/date';

function LoadPlanForm({
  form,
  onFinish,
  loadPlanDetails,
}) {
  useEffect(() => {
    if (loadPlanDetails?.Id) {
      form.setFieldsValue({
        LoadPlanId: loadPlanDetails.LoadPlanId,
        ContainerId: loadPlanDetails.ContainerId,
        LoadedDate: timestampToDate(loadPlanDetails.LoadedDate, true, true),
      });
    }
  }, [loadPlanDetails]);

  return (
    <FormStyled
      form={form}
      layout="horizontal"
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col sm={8}>
          <Form.Item
            name="LoadPlanId"
            label="Load Plan ID"
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col sm={8}>
          <Form.Item
            name="ContainerId"
            label="Container ID"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={8}>
          <Form.Item
            name="LoadedDate"
            label="Loaded Date"
          >
            <DatePicker disabled placeholder="" />
          </Form.Item>
        </Col>
      </Row>
    </FormStyled>
  );
}

export default memo(LoadPlanForm);
