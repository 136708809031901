import { gql } from '@apollo/client';

export const FINALIZE_LOAD_PLAN = gql`
    mutation FinalizeLoadPlan($data: ReopenOrFinalizeLoadPlanInput!) {
        finalizeLoadPlan(data: $data) {
            code
            success
            message
        }
    }`;

export default FINALIZE_LOAD_PLAN;
