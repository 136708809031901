import React from 'react';

import MODE_OF_TRANSPORT from '@graphql/TH_getModeOfTransport';

import useList from '@hooks/useList';

import { ContentStyled } from '@components/Styles/Global';
import { TableStyled } from '@components/Styles/TableStyles';

function ModeTransport() {
  const { data, loading } = useList({ query: MODE_OF_TRANSPORT });

  const columns = [
    {
      title: 'Id',
      dataIndex: 'Id',
      key: 'Id',
      defaultSortOrder: 'none',
      render: (text) => (
        <a style={{ userSelect: 'text' }}>
          {text}
        </a>
      ),
    },
    {
      title: 'Code',
      dataIndex: 'Code',
      key: 'Code',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'Description',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text}
        </span>
      ),
    },
  ];

  return (
    <ContentStyled>
      <TableStyled
        size="small"
        loading={loading}
        dataSource={data}
        columns={columns}
        pagination
        scroll={{ x: true }}
      />
    </ContentStyled>
  );
}

export default ModeTransport;
