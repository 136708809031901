import { useMutation } from '@apollo/client';
import { showNotification } from '@utils/helpers/notifications';
import { useEffect, useState } from 'react';

const useCreateUpdateRecord = ({
  mutation,
  formData,
  callback,
  isDelete,
  variables = {},
}) => {
  const [data, setData] = useState(null);
  const [createUpdateRequest, { loading }] = useMutation(mutation);

  useEffect(() => {
    const sendRequest = async () => {
      try {
        const props = !isDelete ? {
          ...variables,
          data: formData,
        } : {
          ...variables,
        };

        const { data } = await createUpdateRequest({
          variables: {
            ...props,
          },
        });

        setData(data);

        if (data.success) {
          showNotification('success', 'Success', data.message);

          if (callback) {
            callback(data?.result);
          }
        } else {
          showNotification('error', 'Error', data.message);
        }
      } catch (error) {
        showNotification('error', 'Error', error.message);
      }
    };
    if (formData) {
      sendRequest();
    }
  }, [formData]);

  return { data, loading };
};

export default useCreateUpdateRecord;
