import { Pagination, Table } from 'antd';
import styled from 'styled-components';

export const TableStyled = styled(Table)`
  table  {
    color: #666666;
    
    .ant-table-thead .ant-table-column-has-sorters:hover {
      background: #2f568b;
    }
    
    .ant-table-thead > tr th {
      white-space: nowrap;
      background: #2f568b;
      color: #fff;
      padding: 9px !important;

      .anticon-caret-up.active, .anticon-caret-down.active {
        color: #fff;
      }
    }

    .ant-table-thead > tr th:first-child, .ant-table-thead > tr th:last-child {
      border-radius: 0 !important;
    }

    .ant-table-thead {
      .ant-table-column-has-sorters {
        background: #2f568b;
      }
    }

    a {
      color: #2f568b;
    }
    
    .highlighted-row .ant-table-cell {
      background: #e15e5e !important;
      color: #fff;

      .ant-btn {
        color: #fff !important;
      }
    }
  }
`;

export const TableSubStyled = styled(TableStyled)`
  table {
    .ant-table-thead > tr th {
      white-space: nowrap;
      background: #3d3d3d;
      color: #fff;
      padding: 9px !important;

      .anticon-caret-up.active, .anticon-caret-down.active {
        color: #fff;
      }
    }
  }
`;

export const TablePaginationStyled = styled(Pagination)`
  margin-top: 12px;
  text-align: right;
`;

export const TableSmall = styled(TableStyled)`
  table {
    font-size: 12px;

    .ant-table-thead > tr th, .ant-table-thead > tr td {
      padding: 3px !important;
    }

    .ant-table-tbody > tr td {
      padding: 3px !important;
    }

    & .ant-table {
      margin-inline: 0 !important;
    }
  }
`;

export const TableList = styled(TableStyled)`
  table {
    font-size: 12px;

    .ant-table-thead > tr th, .ant-table-thead > tr td {
      padding: 8px 16px !important;
    }

    .ant-table-tbody > tr td {
      padding: 8px 16px !important;
    }

    & .ant-table {
      margin-inline: 0 !important;
    }
  }
`;

export const TableSubSmall = styled(TableSubStyled)`
  table {
    font-size: 12px;

    th, td {
      padding: 3px !important;
    }
  }
`;
