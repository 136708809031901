import { gql } from '@apollo/client';

const GET_PO_LINE_ITEMS_BY_PURCHASE_ORDER_ID = gql`
query GetPOLineItemsByPurchaseOrderId($Id: Int!) {
  getPOLineItemsByPurchaseOrderId(Id: $Id) {
      code
      success
      message
      results {
        Id
        PurchasedOrder_Fk
        LineNumber
        LineStatus
        Supplier_Party_Fk
        PartNumber_Part_Fk
        UnitOfMeasure_Fk
        ExpectedQuantity
        ReceivedQuantity
        AvailableQuantity
        Currency_Fk
        UnitPrice
        TotalPrice
        CustomsUnitPrice
        CustomsTotalPrice
        ModeOfTransport_Fk
        Eccn
        Osd
        PortOfLoading_ID
        FreeBonded
        Commodity_Fk
        UNClassification
        CountryOfOrigin_Country_Fk
        FinalDestination_Location_Fk
        AdditionalDescription
        CreatedBy
        CreatedDate
        UpdatedBy
        UpdateDate
        CountryOfOrigin {
          Code
          Name
        }
        Part {
          Part_Number
          Part_Description
        }
      }
    }
  }
`;

export default GET_PO_LINE_ITEMS_BY_PURCHASE_ORDER_ID;
