import React, {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Button,
  Col, List, Radio, Row, Skeleton, Space, Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { saveAs } from 'file-saver';
import printJS from 'print-js';
import { get } from 'lodash';
import { PDFViewer } from '@react-pdf/renderer';
import { useLazyQuery, useMutation } from '@apollo/client';

import useAuthStore from '@store/authStore';

import { getAsString } from '@utils/helpers/helpers';

import PRINT_FINAL_GOODS_RECEIPT_NOTE from '@graphql/TH_printFinalGoodsReceiptNote';
import GET_PRINT_CIPL from '@graphql/getPrintableCIPL';
import GET_PRINT_AUTHORIZE_TO_PICK from '@graphql/getPrintableAuthorizeToPickScanIds';
import GET_PRINT_PICK from '@graphql/getPrintablePickedScanIds';
import GET_PRINT_DISPATCH from '@graphql/getPrintableDispatchedScanIds';
import GET_PRINT_PACK from '@graphql/getPrintablePackedScanIds';
import GET_PRINT_LOAD_PLAN from '@graphql/getPrintableLoadPlans';

import { CardStyled } from '@components/Styles/CardStyles';
import Barcode from '@containers/TH_WarehouseIn/WarehouseInInboundJob/Components/Barcode';
import GET_PRINT_OVERPACK_SCAN_IDS from '@graphql/getPrintableOverpackScanIds';
import PrintCIPL from '../Components/PrintCIPL';
import PrintAuthPick from '../Components/PrintAuthPick';
import PrintPick from '../Components/PrintPick';
import PrintDispatch from '../Components/PrintDispatch';
import PrintPack from '../Components/PrintPack';
import PrintLoadPlan from '../Components/PrintLoadPlan';
import PrintOverpackLabels from '../Components/PrintOverpackLabels';

function OutboundJobPrint({
  record,
  currentTab,
  onSetModalActions,
}) {
  const [user] = useAuthStore((state) => [state.user]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [barcodes, setBarcodes] = useState({});
  const [printBlob, setPrintBlob] = useState();
  const [handlePrintOrDownload] = useMutation(PRINT_FINAL_GOODS_RECEIPT_NOTE);

  const cardRef = useRef();

  const handleRadioChange = useCallback((e) => {
    setSelectedValue(e.target.value);
  }, []);

  const [getCIPL, { data: dataCIPL, loading: loadingCIPL }] = useLazyQuery(GET_PRINT_CIPL, {
    variables: { Id: record?.Id },
  });

  const [getAuthPick, { data: dataAuthPick, loading: loadingAuthPick }] = useLazyQuery(GET_PRINT_AUTHORIZE_TO_PICK, {
    variables: { Id: record?.Id },
  });

  const [getPick, { data: dataPick, loading: loadingPick }] = useLazyQuery(GET_PRINT_PICK, {
    variables: { Id: record?.Id },
  });

  const [getPack, { data: dataPack, loading: loadingPack }] = useLazyQuery(GET_PRINT_PACK, {
    variables: { Id: record?.Id },
  });

  const [getDispatch, { data: dataDispatch, loading: loadingDispatch }] = useLazyQuery(GET_PRINT_DISPATCH, {
    variables: { Id: record?.Id },
  });

  const [getLoadPlan, { data: dataLoadPlan, loading: loadingLoadPlan }] = useLazyQuery(GET_PRINT_LOAD_PLAN, {
    variables: { Id: record?.Id },
  });

  const [getOverpackLabels, { data: dataOverpackLabels, loading: loadingOverpackLabels }] = useLazyQuery(GET_PRINT_OVERPACK_SCAN_IDS, {
    variables: { Id: record?.Id },
  });

  const ciplData = get(dataCIPL, 'result', {});
  const authPickData = get(dataAuthPick, 'result', {});
  const pickData = get(dataPick, 'result', {});
  const packData = get(dataPack, 'result', {});
  const dispatchData = get(dataDispatch, 'result', {});
  const loadPlanData = get(dataLoadPlan, 'result', {});
  const overpackLabelData = get(dataOverpackLabels, 'results', []);

  const resizePdf = useCallback(() => {
    if (!cardRef) return;

    const { clientWidth, clientHeight } = cardRef.current;
    setDimensions({ width: clientWidth, height: clientHeight });
  }, []);

  const handleLoadPrintFile = useCallback((blob) => {
    setPrintBlob(blob);
  }, []);

  const handleFileDownload = useCallback((selectedValue) => {
    let filename = '';
    switch (selectedValue) {
      case 'Commercial Invoice / Packing List':
        filename = `Commercial Invoice and Packing List ${record?.JobNumber}.pdf`;
        try {
          handlePrintOrDownload({
            variables: {
              warehouseInId: record?.Id,
            },
          });
        } catch (error) {
          // console.error(error);
        }
        break;
      case 'Authorize to Pick Report':
        filename = `Authorize to Pick Report ${record?.JobNumber}.pdf`;
        break;
      case 'Picked Report':
        filename = `Picked Report ${record?.JobNumber}.pdf`;
        break;
      case 'Dispatched Report':
        filename = `Dispatched Report ${record?.JobNumber}.pdf`;
        break;
      case 'Packed / Unpacked Report':
        filename = `Packed / Unpacked Report ${record?.JobNumber}.pdf`;
        break;
      case 'Load Plan Report':
        filename = `Load Plan Report ${record?.JobNumber}.pdf`;
        break;
      case 'Overpack Labels Report':
        filename = `Overpack Labels Report ${record?.WarehouseOut?.JobNumber}.pdf`;
        break;
      default:
        break;
    }

    if (printBlob?.blob) {
      saveAs(printBlob?.blob, filename);
    }
  }, [printBlob?.blob, record?.JobNumber]);

  const handleFilePrint = useCallback(() => {
    if (printBlob?.blob) {
      const blobURL = URL.createObjectURL(printBlob?.blob);
      printJS(blobURL);
    }

    try {
      handlePrintOrDownload({
        variables: {
          warehouseInId: record?.Id,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }, [printBlob?.blob]);

  const handleBarcodeGenerated = useCallback((barcodeObj) => {
    setBarcodes((prevState) => ({
      ...prevState,
      [barcodeObj.value]: barcodeObj.dataURL,
    }));
  }, []);

  const printTypes = [
    'Commercial Invoice / Packing List',
    'Authorize to Pick Report',
    'Overpack Labels',
    'Picked Report',
    'Packed / Unpacked Report',
    'Load Plan Report',
    'Dispatched Report',
  ];

  const showCIPL = selectedValue === 'Commercial Invoice / Packing List' && Object.keys(ciplData).length > 0;
  const showAuthPick = selectedValue === 'Authorize to Pick Report' && Object.keys(authPickData).length > 0;
  const showPick = selectedValue === 'Picked Report' && Object.keys(pickData).length > 0;
  const showDispatch = selectedValue === 'Dispatched Report' && Object.keys(dispatchData).length > 0;
  const showPack = selectedValue === 'Packed / Unpacked Report' && Object.keys(packData).length > 0;
  const showLoadPlan = selectedValue === 'Load Plan Report' && Object.keys(loadPlanData).length > 0;
  const showOverpack = selectedValue === 'Overpack Labels' && overpackLabelData.length > 0;

  useEffect(() => {
    setBarcodes({});

    switch (selectedValue) {
      case 'Commercial Invoice / Packing List':
        getCIPL();
        break;
      case 'Authorize to Pick Report':
        getAuthPick();
        break;
      case 'Picked Report':
        getPick();
        break;
      case 'Dispatched Report':
        getDispatch();
        break;
      case 'Packed / Unpacked Report':
        getPack();
        break;
      case 'Load Plan Report':
        getLoadPlan();
        break;
      case 'Overpack Labels':
        getOverpackLabels();
        break;
      default:
        break;
    }
  }, [selectedValue]);

  useEffect(() => {
    resizePdf();
  }, [cardRef]);

  useEffect(() => {
    if (currentTab === 'printDocument') {
      onSetModalActions([
        <Space key={1}>&nbsp;</Space>,
        <Space key={2}>
          <Button onClick={() => handleFilePrint()} disabled={!printBlob}>
            Print
          </Button>
          <Button onClick={() => handleFileDownload(selectedValue)} disabled={!printBlob}>
            Download
          </Button>
        </Space>,
      ]);
    }
  }, [selectedValue, currentTab, printBlob]);

  useEffect(() => {
    const handleResize = () => {
      resizePdf();
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Content style={{ padding: 16 }}>
      <Row gutter={16}>
        <Col xs={8}>
          <List
            header={<Typography.Text strong>Print Types</Typography.Text>}
            size="small"
            bordered
            dataSource={printTypes}
            renderItem={(item) => (
              <List.Item>
                <Space direction="vertical">
                  <Radio
                    value={item}
                    checked={selectedValue === item}
                    onChange={handleRadioChange}
                  >
                    {item}
                  </Radio>
                </Space>
              </List.Item>
            )}
          />
        </Col>
        <Col xs={16}>
          <CardStyled ref={cardRef}>
            <Typography.Title level={4}>Preview</Typography.Title>
            {(getAsString(authPickData, 'WarehouseOutDetails.JobNumber') && showAuthPick) && (
              <Barcode
                key={getAsString(authPickData, 'WarehouseOutDetails.JobNumber')}
                value={getAsString(authPickData, 'WarehouseOutDetails.JobNumber')}
                onBarcodeGenerated={handleBarcodeGenerated}
                barcodeProps={{
                  displayValue: false,
                }}
              />
            )}
            {(getAsString(pickData, 'WarehouseOutDetails.JobNumber') && showPick) && (
              <Barcode
                key={getAsString(pickData, 'WarehouseOutDetails.JobNumber')}
                value={getAsString(pickData, 'WarehouseOutDetails.JobNumber')}
                onBarcodeGenerated={handleBarcodeGenerated}
                barcodeProps={{
                  displayValue: false,
                }}
              />
            )}
            {(getAsString(dispatchData, 'WarehouseOutDetails.JobNumber') && showDispatch) && (
              <Barcode
                key={getAsString(dispatchData, 'WarehouseOutDetails.JobNumber')}
                value={getAsString(dispatchData, 'WarehouseOutDetails.JobNumber')}
                onBarcodeGenerated={handleBarcodeGenerated}
                barcodeProps={{
                  displayValue: false,
                }}
              />
            )}
            {(getAsString(packData, 'WarehouseOutDetails.JobNumber') && showPack) && (
              <Barcode
                key={getAsString(packData, 'WarehouseOutDetails.JobNumber')}
                value={getAsString(packData, 'WarehouseOutDetails.JobNumber')}
                onBarcodeGenerated={handleBarcodeGenerated}
                barcodeProps={{
                  displayValue: false,
                }}
              />
            )}
            {(getAsString(loadPlanData, 'WarehouseOutDetails.JobNumber') && showLoadPlan) && (
              <Barcode
                key={getAsString(loadPlanData, 'WarehouseOutDetails.JobNumber')}
                value={getAsString(loadPlanData, 'WarehouseOutDetails.JobNumber')}
                onBarcodeGenerated={handleBarcodeGenerated}
                barcodeProps={{
                  displayValue: false,
                }}
              />
            )}
            {showOverpack && (
              <>
                {overpackLabelData.map(({ Id, ScanId }) => (
                  <Barcode key={Id} value={ScanId} uniqueKeyIdentifier={Id} onBarcodeGenerated={handleBarcodeGenerated} />
                ))}
              </>
            )}
            <Skeleton loading={loadingCIPL || loadingAuthPick || loadingPick || loadingDispatch || loadingPack || loadingLoadPlan || loadingOverpackLabels} active>
              <PDFViewer width={dimensions.width - 32} height={600} showToolbar={false}>
                {showCIPL && (
                  <PrintCIPL
                    data={ciplData}
                    user={user}
                    onLoadPrintFile={handleLoadPrintFile}
                  />
                )}
                {showAuthPick && (
                  <PrintAuthPick
                    barcodes={barcodes}
                    data={authPickData}
                    user={user}
                    onLoadPrintFile={handleLoadPrintFile}
                  />
                )}
                {showPick && (
                  <PrintPick
                    barcodes={barcodes}
                    data={pickData}
                    user={user}
                    onLoadPrintFile={handleLoadPrintFile}
                  />
                )}
                {showDispatch && (
                  <PrintDispatch
                    barcodes={barcodes}
                    data={dispatchData}
                    user={user}
                    onLoadPrintFile={handleLoadPrintFile}
                  />
                )}
                {showPack && (
                  <PrintPack
                    barcodes={barcodes}
                    data={packData}
                    user={user}
                    onLoadPrintFile={handleLoadPrintFile}
                  />
                )}
                {showLoadPlan && (
                  <PrintLoadPlan
                    barcodes={barcodes}
                    data={loadPlanData}
                    user={user}
                    onLoadPrintFile={handleLoadPrintFile}
                  />
                )}
                {showOverpack && (
                  <PrintOverpackLabels
                    barcodes={barcodes}
                    list={overpackLabelData}
                    onLoadPrintFile={handleLoadPrintFile}
                  />
                )}
              </PDFViewer>
            </Skeleton>
          </CardStyled>
        </Col>
      </Row>
    </Content>
  );
}

export default memo(OutboundJobPrint);
