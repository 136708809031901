import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { ApolloProvider } from '@apollo/client';

import 'antd/dist/reset.css';
import './App.css';

import theme from '@config/theme';
import client from '@services/client';
import routes from '@routes/Routes';

import useAuthStore from '@store/authStore';
import useHydration from '@hooks/useHydrate';

function App() {
  const isHydrated = useHydration();
  const [token, user] = useAuthStore((state) => [state.token, state.user]);
  const accountStatus = user || {};

  if (!isHydrated) return null;

  return (
    <ConfigProvider theme={theme}>
      <ApolloProvider client={client}>
        <div className="App">
          <RouterProvider router={routes(token, accountStatus)} />
        </div>
      </ApolloProvider>
    </ConfigProvider>
  );
}

export default App;
