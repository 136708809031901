import * as XLSX from 'xlsx';
import { useState } from 'react';
import { showNotification } from '@utils/helpers/notifications';

const useExcelDownload = () => {
  const [isLoading, setIsLoading] = useState(false);

  const downloadExcel = (name, data) => {
    const date = new Date().toLocaleDateString('en-US').replace(/\//g, '-');
    const reportName = `${name.replace(/\s/g, '_').toLowerCase()}_${date}`;

    setIsLoading(true);

    if (!data || data.length <= 0) {
      showNotification('error', 'Error', 'No data to export');
      setIsLoading(false);
    } else {
      try {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'name');
        XLSX.writeFile(wb, `${reportName}.xlsx`);
      } catch (error) {
        showNotification('error', 'Error', error.message);
      }
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return [isLoading, downloadExcel];
};

export default useExcelDownload;
