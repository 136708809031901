import { gql } from '@apollo/client';

export const UPDATE_SCAN_ID = gql`
    mutation UpdateScanId($id: Int, $data: PurchaseOrderPartLocationInput!) {
        updateScanId(id: $id, data: $data) {
            code
            success
            message
        }
    }`;

export default UPDATE_SCAN_ID;
