import { gql } from '@apollo/client';

export const PICK_SCAN_ID = gql`
    mutation PickGoods($data: PickInput!) {
        pickGoods(data: $data) {
            code
            success
            message
        }
    }`;

export default PICK_SCAN_ID;
