import React, {
  memo, useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Col, Row, Space, Form, Checkbox, Modal, message,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useMutation } from '@apollo/client';
import { UserOutlined, LockOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import LOGIN_MUTATION from '@graphql/loginMutation';
import useAuthStore from '@store/authStore';

import { LOGIN_BLOCK_MESSAGE } from '@constants/login';

import Logo from '@assets/thinkbros-logo.png';
import ScaffBg from '@assets/industrial.jpg';

import {
  ButtonStyled, ErrorText, FooterText, FormItemStyled, InputPasswordStyled, InputStyled, TitleStyled,
} from '@components/Styles/LoginStyles';
import { ACCOUNT_STATUS } from '@constants/accountStatus';
import { FOOTER } from '@constants/footer';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid Email Format').required('Required'),
  password: Yup.string().required('Required'),
});

function Login() {
  const navigate = useNavigate();

  const [setToken, setUser] = useAuthStore((state) => [state.setToken, state.setUser]);
  const [login, { loading }] = useMutation(LOGIN_MUTATION);

  const [modal, contextHolder] = Modal.useModal();
  const [messageApi, contextHolderMessage] = message.useMessage();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const { data } = await login({
          variables: {
            email: values.email,
            password: values.password,
          },
        });

        const {
          token, user, message: msg, success,
        } = data;

        if (!success) {
          if (msg === LOGIN_BLOCK_MESSAGE) {
            showLoginPrompt();
          } else {
            messageApi.open({
              type: 'error',
              content: msg,
              style: {
                marginRight: '-50vw',
              },
            });

            formik.setFieldError('password', true);
            formik.setFieldError('email', msg);
          }
          return;
        }

        setToken(token);
        setUser(user);

        navigate('/po-parts-labs');

        if (user?.accountStatus === ACCOUNT_STATUS.RESET) {
          navigate('/change-password');
        } else {
          navigate('/po-parts-labs');
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const showLoginPrompt = useCallback(() => {
    modal.error({
      title: 'Login Blocked',
      icon: <ExclamationCircleOutlined />,
      content: 'Your account has been locked due to multiple failed login attempts',
    });
  }, []);

  return (
    <Space direction="vertical" style={{ width: '100%', height: '100%' }} size={[0, 48]}>
      <Row style={{ height: '100vh' }}>
        <Col
          md={0}
          lg={12}
          style={{
            background: `url(${ScaffBg}) no-repeat left top`,
            backgroundSize: 'cover',
            backgroundColor: 'rgba(24,144,255,.35)',
            backgroundBlendMode: 'multiply',
          }}
        />
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
        >
          <Content style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            alignItems: 'center',
            backgroundColor: '#ffffff',
          }}
          >
            <Content style={{
              maxWidth: 400,
              width: '100%',
              marginTop: 200,
            }}
            >
              <img src={Logo} alt="thikbros-logo" style={{ width: 200, marginLeft: -12 }} />
              <TitleStyled level={3}>SIGN IN</TitleStyled>
              <Form onFinish={formik.handleSubmit}>
                <FormItemStyled
                  label="Email address"
                  name="email"
                >
                  <InputStyled
                    size="large"
                    prefix={<UserOutlined />}
                    placeholder="Email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    status={!(formik.touched.email && formik.errors.email) || 'error'}
                  />
                </FormItemStyled>

                {formik.touched.email && formik.errors.email ? (
                  <ErrorText>{formik.errors.email}</ErrorText>
                ) : null}

                <FormItemStyled
                  label="Password"
                  name="password"
                >
                  <InputPasswordStyled
                    size="large"
                    prefix={<LockOutlined />}
                    placeholder="Password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                    status={!(formik.touched.password && formik.errors.password) || 'error'}
                  />
                </FormItemStyled>

                {formik.touched.password && formik.errors.password ? (
                  <ErrorText>{formik.errors.password}</ErrorText>
                ) : null}

                <Form.Item>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>
                </Form.Item>

                <ButtonStyled block type="primary" htmlType="submit" size="large" loading={loading}>
                  Sign In
                </ButtonStyled>
              </Form>
            </Content>
            <FooterText>{FOOTER}</FooterText>
          </Content>
        </Col>
      </Row>
      {contextHolder}
      {contextHolderMessage}
    </Space>
  );
}

export default memo(Login);
