import { gql } from '@apollo/client';

const GET_PRINT_LOAD_PLAN = gql`
  query GetPrintableLoadPlans($Id: Int!) {
    getPrintableLoadPlans(WarehouseOutId: $Id) {
      code
      success
      message
      result {
        WarehouseOutDetails {
          JobNumber
          CustomerParty {
            Code
            Name
          }
          ModeOfTransport {
            Code
            Description
          }
          JobDate
          JobStatus
          FreightJobNumber
          Remarks
        }
        LoadPlans {
          LoadPlanId
          ContainerId
          LoadedDate
          LoadedOverpackScanIds {
            ScanId
            Warehouse {
              Code
              Name
            }
            StorageLocation {
              Id
              Code
            }
            LengthInch
            WidthInch
            HeightInch
            LengthCm
            WidthCm
            HeightCm
            WeightKg
            WeightLbs
            VolumeCbm
            VolumeCft
          }
        }
        TotalLoadPlans
      }
    }
  }
`;

export default GET_PRINT_LOAD_PLAN;
