import React, { memo, useCallback, useState } from 'react';
import {
  Button, Tag, Input, Space,
} from 'antd';
import { EyeOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { RefreshSharp } from '@mui/icons-material';

import GET_ALL_PARTS from '@graphql/getAllPart';

import useList from '@hooks/useList';

import { ContentStyled } from '@components/Styles/Global';
import { TableStyled } from '@components/Styles/TableStyles';
import { ModalStyled } from '@components/Styles/ModalStyles';
import { SpaceActionButton } from '@components/Styles/ButtonStyles';
import ButtonTooltip from '@components/Button/ButtonTooltip';
import { parseAndToFixed } from '@utils/helpers/helpers';
import PartsAddForm from './PartsAddForm';
import PartsUpdateForm from './PartsUpdateForm';

function AddPartsList() {
  const [openAddPartsModal, setOpenAddPartsModal] = useState(false);
  const [openUpdatePartsModal, setOpenUpdatePartsModal] = useState(false);
  const [selectedParts, setSelectedParts] = useState(null);
  const [action, setAction] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { data, loading, refetch } = useList({ query: GET_ALL_PARTS });

  const handleSuccessAdd = useCallback(() => {
    refetch();
    setOpenAddPartsModal(false);
  }, []);

  const handleSuccessUpdate = useCallback(() => {
    refetch();
  }, []);

  const handleRefresh = useCallback(() => {
    refetch();
  }, []);

  const handleCancelAdd = useCallback(() => {
    setOpenAddPartsModal(false);
  }, []);

  const handleOpenAdd = useCallback(() => {
    setOpenAddPartsModal(true);
  }, []);

  const handleOpenUpdate = useCallback(() => {
    setOpenUpdatePartsModal(true);
  }, []);

  const handleCancelUpdate = useCallback(() => {
    setOpenUpdatePartsModal(false);
  }, []);

  const handleOnSave = useCallback(() => {
    if (action) {
      action();
    }
  }, [action]);

  const handleOnSubmitAction = useCallback((action) => {
    setAction(action);
  }, []);

  const handleOnLoading = useCallback((loading) => {
    setIsLoading(loading);
  }, []);

  const filterDropdown = ({
    setSelectedKeys, selectedKeys, confirm, clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder="Search name"
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        style={{ width: 200, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined style={{ color: '#ffffff' }} />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  );

  const filterIcon = (filtered) => <SearchOutlined style={{ color: filtered ? '#ffffff' : undefined }} />;

  const columns = [
    {
      title: 'Part Number',
      dataIndex: 'Part_Number',
      key: 'Part_Number',
      fixed: 'left',
      filterDropdown,
      filterIcon,
      onFilter: (value, record) => record.Part_Number.toLowerCase().includes(value.toLowerCase()),
      render: (text, record) => (
        <Button
          type="link"
          icon={<EyeOutlined />}
          onClick={() => {
            setSelectedParts(record);
            handleOpenUpdate();
          }}
        >
          {text}
        </Button>
      ),
      sorter: (a, b) => a.Part_Number.length - b.Part_Number.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Part Description',
      dataIndex: 'Part_Description',
      key: 'Part_Description',
      sorter: (a, b) => a.Part_Description.length - b.Part_Description.length,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Remarks 1',
      dataIndex: 'Remarks_1',
      key: 'Remarks_1',
      sorter: (a, b) => a.Remarks_1.length - b.Remarks_1.length,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Remarks 2',
      dataIndex: 'Remarks_2',
      key: 'Remarks_2',
      sorter: (a, b) => a.Remarks_2.length - b.Remarks_2.length,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Parent Part',
      dataIndex: 'ParentPart',
      key: 'ParentPart',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Part_Number && `[${text?.Part_Number}] ${text?.Part_Description}`}
        </span>
      ),
    },
    {
      title: 'Schedule B',
      dataIndex: 'Schedule_B',
      key: 'Schedule_B',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'ECCN',
      dataIndex: 'ECCN',
      key: 'ECCN',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Country of Origin',
      dataIndex: 'CountryOfOrigin',
      key: 'CountryOfOrigin',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Code && `[${text?.Code}] ${text?.Name}`}
        </span>
      ),
    },
    {
      title: 'Currency',
      dataIndex: 'Currency',
      key: 'Currency',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Code && `[${text?.Code}] ${text?.Description}`}
        </span>
      ),
    },
    {
      title: 'Unit Price',
      dataIndex: 'Unit_Price',
      key: 'Unit_Price',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {parseAndToFixed(text)}
        </span>
      ),
    },
    {
      title: 'Customs Unit Price',
      dataIndex: 'Customs_Unit_Price',
      key: 'Customs_Unit_Price',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {parseAndToFixed(text)}
        </span>
      ),
    },
    {
      title: 'Unit of Measure',
      dataIndex: 'UnitOfMeasure',
      key: 'UnitOfMeasure',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Code && `[${text?.Code}] ${text?.Description}`}
        </span>
      ),
    },
    {
      title: 'Active',
      dataIndex: 'Active',
      key: 'Active',
      render: (text) => {
        if (text === 1) {
          return (
            <Tag color="green">
              ACTIVE
            </Tag>
          );
        }
        return (
          <Tag color="red">
            INACTIVE
          </Tag>
        );
      },
    },
  ];

  return (
    <ContentStyled>
      <SpaceActionButton>
        <ButtonTooltip
          type="default"
          onClick={handleRefresh}
          icon={<RefreshSharp />}
          tooltipProps={{ title: 'Refresh' }}
        />
        <ButtonTooltip
          type="default"
          onClick={handleOpenAdd}
          icon={<PlusOutlined />}
          tooltipProps={{ title: 'Add Parts' }}
        />
      </SpaceActionButton>
      <TableStyled
        size="small"
        loading={loading}
        dataSource={data}
        columns={columns}
        pagination
        scroll={{
          x: true,
        }}
      />
      {openAddPartsModal && (
        <ModalStyled
          title="Add New Parts"
          maskClosable={false}
          keyboard={false}
          style={{ top: '54px' }}
          styles={{ body: { height: 'calc(90vh - 108px)', overflow: 'auto' } }}
          width="50%"
          open={openAddPartsModal}
          onCancel={handleCancelAdd}
          footer={[
            <div key={1} />,
            <Button key={2} type="primary" onClick={handleOnSave} disabled={isLoading} loading={isLoading}>
              Create
            </Button>,
          ]}
        >
          <PartsAddForm
            onSuccess={handleSuccessAdd}
            onSubmitAction={handleOnSubmitAction}
            onLoading={handleOnLoading}
          />
        </ModalStyled>
      )}
      {openUpdatePartsModal && (
        <ModalStyled
          title="Update Part"
          maskClosable={false}
          keyboard={false}
          style={{ top: '54px' }}
          styles={{ body: { height: 'calc(90vh - 108px)', overflow: 'auto' } }}
          width="50%"
          open={openUpdatePartsModal}
          onCancel={handleCancelUpdate}
          footer={[
            <div key={1} />,
            <Button key={2} type="primary" onClick={handleOnSave} disabled={isLoading} loading={isLoading}>
              Save
            </Button>,
          ]}
        >
          <PartsUpdateForm
            selectedRow={selectedParts}
            onSuccess={handleSuccessUpdate}
            onSubmitAction={handleOnSubmitAction}
            onLoading={handleOnLoading}
          />
        </ModalStyled>
      )}
    </ContentStyled>
  );
}

export default memo(AddPartsList);
