import { gql } from '@apollo/client';

export const UPDATE_PURCHASE_ORDER = gql`
    mutation UPDATE_PURCHASE_ORDER($id: Int, $data: PurchaseOrderInput!) {
        updatePurchaseOrder(id: $id, data: $data) {
            code
            success
            message
        }
    }`;

export default UPDATE_PURCHASE_ORDER;
