import { Button, Space } from 'antd';
import styled from 'styled-components';

export const ActionButton = styled(Button)`
  position: absolute;
  top: 20px;
  right: 0;
  margin: 0 24px 24px 24px;
`;

export const SpaceActionButton = styled(Space)`
  align-items: start;
  position: absolute;
  top: 20px;
  right: 0;
  margin: 0 24px 24px 24px;
`;

export const SpaceActionButtonInnerTable = styled(Space)`
  align-items: start;
  position: absolute;
  top: 12px;
  right: 0;
  margin: 0 16px 0 0;
`;
