import React from 'react';

import GET_ALL_TARIFF from '@graphql/getallTariff';

import useList from '@hooks/useList';

import { ContentStyled } from '@components/Styles/Global';
import { TableStyled } from '@components/Styles/TableStyles';

function Tariff() {
  const { data, loading } = useList({ query: GET_ALL_TARIFF });

  const columns = [
    {
      title: 'Id',
      dataIndex: 'Id',
      key: 'Id',
      defaultSortOrder: 'none',
      render: (text) => (
        <a style={{ userSelect: 'text' }}>
          {text}
        </a>
      ),
    },
    {
      title: 'Buy Rate',
      dataIndex: 'Buy_Rate',
      key: 'Buy_Rate',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Sell Rate',
      dataIndex: 'Sell_Rate',
      key: 'Sell_Rate',
    },
  ];

  return (
    <ContentStyled>
      <TableStyled
        size="small"
        loading={loading}
        dataSource={data}
        columns={columns}
        pagination
        scroll={{ x: true }}
      />
    </ContentStyled>
  );
}

export default Tariff;
