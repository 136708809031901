import { gql } from '@apollo/client';

export const UNPICK_SCAN_ID = gql`
    mutation UnpickGoods($data: UnpickInput!) {
        unpickGoods(data: $data) {
            code
            success
            message
        }
    }`;

export default UNPICK_SCAN_ID;
