import { gql } from '@apollo/client';

export const CREATE_PURCHASE_ORDER = gql`
    mutation CREATE_PURCHASE_ORDER($data: PurchaseOrderInput!) {
        createPurchaseOrder(data: $data) {
            code
            success
            message
        }
    }`;

export default CREATE_PURCHASE_ORDER;
