import { gql } from '@apollo/client';

const UPDATE_PARTS = gql`
    mutation UpdatePart($id: Int, $data: PartInput!) {
        updatePart(id: $id, data: $data) {
            code
            success
            message
        }
    }
`;

export default UPDATE_PARTS;
