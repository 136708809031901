import dayjs from 'dayjs';
import moment from 'moment';

export const FORMAT_DATE = 'YYYY-MM-DD';
export const FORMAT_DATE_TIME = 'YYYY-MM-DD HH:mm';

export const timestampToDate = (value, noTime, toDate, format) => {
  if (!value) return value;
  const date = moment.unix(value / 1000).format(format || (noTime ? FORMAT_DATE : FORMAT_DATE_TIME));

  if (toDate) {
    return dateStringToDate(date, noTime);
  }

  return date;
};

export const dateStringToDate = (value, noTime) => {
  if (!value) return value;
  return dayjs(value, noTime ? FORMAT_DATE : FORMAT_DATE_TIME);
};

export const dateToString = (value, format) => {
  if (!value) return value;
  return dayjs(value).format(format || FORMAT_DATE);
};
