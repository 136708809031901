import React, {
  memo, useEffect, useState, useRef, useCallback,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button, Popconfirm, Form, Row, Col, Input, AutoComplete, Typography,
} from 'antd';
import { get } from 'lodash';
import { EditOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import CustomSelect from '@components/Select/CustomSelect';

import GET_ALL_COMPANIES from '@graphql/getAllCompanies';

import { Content } from 'antd/es/layout/layout';
import UPDATE_USER from '@graphql/updateUser';
import { showNotification } from '@utils/helpers/notifications';

import RESET_PASSWORD from '@graphql/resetPassword';

const FormItemStyled = styled(Form.Item)`
  margin-bottom: 52px !important;

  .ant-form-item-row  {
    flex-direction: column;
    justify-content: flex-start;
  }

  .ant-form-item-label {
    text-align: left;
    margin-bottom: 12px;
    font-weight: bold;
  }
`;

const InputStyled = styled(Input)`
  border-radius: 4px !important;
`;

const InputSearchStyled = styled(Input.Search)`
    border-radius: 4px !important;
    & input, span button {
      border-radius: 4px !important;
    }
  `;

const ErrorText = styled(Typography.Paragraph)`
  color: red;
  font-size: 12px;
`;

function Users() {
  const location = useLocation();
  const recordData = location.state?.user;

  const [userData, setUserData] = useState(recordData || '');
  const [updateUser] = useMutation(UPDATE_USER);
  const [resetPassword] = useMutation(RESET_PASSWORD);
  const selectRef = useRef(null);

  const [resetChildComponent] = useState('');
  const navigate = useNavigate();

  const { data: companyData } = useQuery(GET_ALL_COMPANIES);
  const companies = get(companyData, 'results', []);

  useEffect(() => {
    if (recordData) {
      setUserData(recordData);
    }
  }, [recordData]);
  useEffect(() => {
    formik.setFieldValue('role', userData.role);
  }, []);

  const validationSchema = Yup.object().shape({
    fullname: Yup.string().required('Required'),
    email: Yup.string().required('Required'),
    company: Yup.string().required('Required'),
    phone: Yup.string().required('Required'),
    role: Yup.string().required('Required'),
  });
  const formik = useFormik({
    initialValues: {
      fullname: userData.fullname,
      email: userData.email,
      company: userData.company,
      phone: userData.phone,
      role: userData.role,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await updateUser({
          variables: {
            updateInput: {
              fullname: (values.fullname) ? values.fullname : document.getElementById('fullname').value,
              email: (values.email) ? values.email : document.getElementById('email').value,
              company: (values.company) ? values.company : document.getElementById('company').value,
              phone: (values.phone) ? values.phone : document.getElementById('phone').value,
              role: (values.role) ? values.role : userData.role,
            },
            updateID: userData._id,
          },
        });
        showNotification('success', 'User Updated', 'The user has been successfully updated');
        navigate('/admin/users');
      } catch (error) {
        showNotification('error', error.message, '');
      }
    },
  });

  const handleResetPassword = useCallback(async (id) => {
    try {
      await resetPassword({
        variables: {
          id,
        },
      });
      showNotification('success', 'Password Resetted', 'User password was set to default password: 123456');
      navigate('/admin/users');
    } catch (e) {
      showNotification('error', e.message, '');
    }
  }, []);

  const handleCompanySelect = (value) => {
    formik.setFieldValue('company', value);
  };

  return (
    <Content style={{ margin: '0px 25px 25px' }}>
      <Form
        initialValues={{
          fullname: userData.fullname, email: userData.email, company: userData.company, phone: userData.phone, role: userData.role,
        }}
        onFinish={formik.handleSubmit}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <FormItemStyled
              label="Fullname"
              name="fullname"
              colon={false}
            >
              <InputStyled
                size="large"
                name="fullname"
                placeholder="Fullname"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </FormItemStyled>
            {formik.touched.fullname && formik.errors.fullname ? (
              <ErrorText>{formik.errors.fullname}</ErrorText>
            ) : null}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <FormItemStyled
              label="Email"
              name="email"
              colon={false}
            >
              <InputStyled
                size="large"
                name="email"
                placeholder="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
              />
            </FormItemStyled>
            {formik.touched.email && formik.errors.email ? (
              <ErrorText>{formik.errors.email}</ErrorText>
            ) : null}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <FormItemStyled
              label="Company"
              name="company"
              colon={false}
            >
              {/* <InputStyled
                size="large"
                name="company"
                placeholder="company"
                onChange={formik.handleChange}
                value={formik.values.company}
                onBlur={formik.handleBlur}
              /> */}
              <AutoComplete
                options={companies?.map(((cmp) => ({ value: cmp.code })))}
                placeholder="Select Company"
                name="company"
                onChange={(value) => handleCompanySelect(value)}
                value={formik.values.company}
                onBlur={formik.handleBlur}
              >
                <InputSearchStyled size="large" />
              </AutoComplete>
            </FormItemStyled>
            {formik.touched.company && formik.errors.company ? (
              <ErrorText>{formik.errors.company}</ErrorText>
            ) : null}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <FormItemStyled
              label="Phone"
              name="phone"
              colon={false}
            >
              <InputStyled
                size="large"
                name="phone"
                placeholder="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                onBlur={formik.handleBlur}
              />
            </FormItemStyled>
            {formik.touched.phone && formik.errors.phone ? (
              <ErrorText>{formik.errors.phone}</ErrorText>
            ) : null}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <FormItemStyled
              label="Role"
              name="role"
              colon={false}
            >
              <CustomSelect
                options={[
                  {
                    value: '',
                    label: 'Select Role',
                  },
                  {
                    value: 'SUPERADMIN',
                    label: 'SUPERADMIN',
                  },
                  {
                    value: 'REQUESTOR',
                    label: 'REQUESTOR',
                  },
                  {
                    value: 'PACKAGE_ENGINEER',
                    label: 'PACKAGE ENGINEER',
                  },
                  {
                    value: 'ARCHITECTURAL',
                    label: 'ARCHITECTURAL',
                  },
                  {
                    value: 'STRUCTURAL',
                    label: 'STRUCTURAL',
                  },
                  {
                    value: 'SPACE_MANAGEMENT',
                    label: 'SPACE MANAGEMENT',
                  },
                  {
                    value: 'COMMERCIAL',
                    label: 'COMMERCIAL',
                  },
                  {
                    value: 'PROJECT_MANAGER',
                    label: 'PROJECT MANAGER',
                  },
                  {
                    value: 'EXYTE_ADMIN',
                    label: 'EXYTE ADMIN',
                  },
                  {
                    value: 'CONTRACTOR',
                    label: 'CONTRACTOR',
                  },
                  {
                    value: 'CC_TEAM',
                    label: 'CC TEAM',
                  },
                ]}
                value={formik.values.role}
                name="role"
                onChange={(value) => formik.setFieldValue('role', value)}
                selectRef={selectRef}
                openModal
                reset={resetChildComponent}
              />
            </FormItemStyled>
            {formik.touched.role && formik.errors.role ? (
              <ErrorText>{formik.errors.role}</ErrorText>
            ) : null}
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Popconfirm
            title="Reset Account Password"
            description={(
              <Typography.Text style={{ width: '360px', display: 'block' }}>
                Password for this account
                will be set to 123456 and will require the user to change password.
              </Typography.Text>
            )}
            onConfirm={() => handleResetPassword(userData?._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" icon={<EditOutlined />}>Reset Account Password</Button>
          </Popconfirm>
          {' '}
        </Row>
        <Row style={{ justifyContent: 'flex-end', marginTop: '70px' }}>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Content>
  );
}

export default memo(Users);
