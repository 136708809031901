import { gql } from '@apollo/client';

export const UPDATE_OR_DETACH_PO_LINE_ITEM_TO_SCAN_ID = gql`
    mutation UpdateOrDetachedPOLineItemToScanId($data: PurchaseOrderPartLocationDetailInput!) {
        updateOrDetachedPOLineItemToScanId(data: $data) {
            code
            success
            message
        }
    }`;

export default UPDATE_OR_DETACH_PO_LINE_ITEM_TO_SCAN_ID;
