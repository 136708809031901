import React, { memo, useState } from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import {
  Col, Row, Space, Typography, Form, Input, Button,
} from 'antd';

import useAuthStore from '@store/authStore';

import { Content } from 'antd/es/layout/layout';
import { useNavigate } from 'react-router-dom';
import { ACCOUNT_STATUS } from '@constants/accountStatus';
import CHANGE_PASSWORD from '@graphql/changePassword';
import { showNotification } from '@utils/helpers/notifications';

const ButtonStyled = styled(Button)`
  // background: linear-gradient(93.67deg, #38A3A5 -16.02%, #196899 124.2%);
  background: #38A3A5;
  border-radius: 4px !important;
  // text-transform: uppercase;
  letter-spacing: 2px;
  // font-weight: bold;
`;
const ButtonDefault = styled(Button)`
    // background: linear-gradient(93.67deg, #38A3A5 -16.02%, #196899 124.2%);
    background: #fff;
    border-radius: 4px !important;
    // text-transform: uppercase;
    letter-spacing: 2px;
    // font-weight: bold;
    border: 1px solid #38A3A5;
    color: #38A3A5;
    &:hover {
        background: #38A3A5;
        color: #fff !important;
    }
`;

const InputPasswordStyled = styled(Input.Password)`
  border-radius: 4px !important;
  margin-bottom: 8px;
`;

const TitleStyled = styled(Typography.Title)`
    letter-spacing: 2px;
    margin-top: 20px;
    padding-bottom: 20px;
    text-align: left;
`;

const FooterText = styled(Typography.Text)`
  margin-top: 40px;
  padding: 12px;
`;

const ErrorText = styled(Typography.Paragraph)`
  color: red;
  font-size: 12px;
`;

function ChangePassword() {
  const [changePassword] = useMutation(CHANGE_PASSWORD);
  const [setToken, setUser, user] = useAuthStore((state) => [state.setToken, state.setUser, state.user]);
  const [logOutLoading, setLogOutLoading] = useState(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    setLogOutLoading(true);
    setToken(null);
    setUser(null);
    navigate('/login');
    setLogOutLoading(false);
  };

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
    newPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      password: '',
      newPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const { data } = await changePassword({
          variables: {
            currentPassword: values.currentPassword,
            newPassword: values.newPassword,
          },
        });

        const {
          message: msg,
        } = data;

        if (!data.success) {
          showNotification('error', 'Error', data.message);
          formik.setFieldError('currentPassword', msg);
          formik.setFieldError('newPassword', true);
          formik.setFieldError('confirmPassword', true);
          return;
        }
        showNotification('success', 'Success', data.message);

        setUser({ ...user, accountStatus: ACCOUNT_STATUS.ACTIVE });
        navigate('/po-parts-labs');
      } catch (error) {
        showNotification('error', 'Error', error.message);
      }
    },
  });

  return (
    <Space direction="vertical" style={{ width: '100%', height: '100%' }} size={[0, 48]}>
      <Row style={{
        height: '100vh',
        background: 'url(/src/assets/scaffolding.jpg) no-repeat left top',
        backgroundSize: 'cover',
        // backgroundColor: 'rgba(24,144,255,.35)',
        backgroundColor: '#38A3A5',
        backgroundBlendMode: 'multiply',
      }}
      >
        <Col
          md={0}
          lg={12}

        />
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.81)', height: '100vh' }}
        >
          <Content style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            alignItems: 'center',
          }}
          >
            <Content style={{
              maxWidth: 400,
              width: '100%',
              marginTop: 200,
              borderRadius: '4px',
              background: '#fff',
              padding: '15px 25px',
            }}
            >
              <TitleStyled level={4} style={{ marginBottom: '20px' }}>
                ePen
                <span style={{ color: '#38A3A5' }}>etration</span>
              </TitleStyled>
              <TitleStyled level={4}>Login</TitleStyled>
              <Form onFinish={formik.handleSubmit}>
                <InputPasswordStyled
                  size="large"
                  name="currentPassword"
                  placeholder="Enter your current password"
                  onChange={formik.handleChange}
                  value={formik.values.currentPassword}
                  onBlur={formik.handleBlur}
                  status={!(formik.touched.currentPassword && formik.errors.currentPassword) || 'error'}
                />
                {formik.touched.currentPassword && formik.errors.currentPassword ? (
                  <ErrorText>{formik.errors.currentPassword}</ErrorText>
                ) : null}
                <InputPasswordStyled
                  size="large"
                  name="password"
                  placeholder="Enter your new password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  status={!(formik.touched.password && formik.errors.password) || 'error'}
                />

                {formik.touched.password && formik.errors.password ? (
                  <ErrorText>{formik.errors.password}</ErrorText>
                ) : null}

                <InputPasswordStyled
                  size="large"
                  name="newPassword"
                  placeholder="Confirm your password"
                  onChange={formik.handleChange}
                  value={formik.values.newPassword}
                  onBlur={formik.handleBlur}
                  status={!(formik.touched.newPassword && formik.errors.newPassword) || 'error'}
                />

                {formik.touched.newPassword && formik.errors.newPassword ? (
                  <ErrorText>{formik.errors.newPassword}</ErrorText>
                ) : null}

                <ButtonStyled block type="primary" htmlType="submit" size="large">
                  Submit
                </ButtonStyled>
                <ButtonDefault style={{ marginTop: 12 }} block htmlType="button" size="large" onClick={handleLogout} loading={logOutLoading}>
                  Logout
                </ButtonDefault>

              </Form>
            </Content>
            <FooterText>Copyright 2023. All Rights Reserved (v1.0.1)</FooterText>
          </Content>
        </Col>
      </Row>
    </Space>
  );
}

export default memo(ChangePassword);
