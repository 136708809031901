import { gql } from '@apollo/client';

export const INBOUND_JOB_UNCONFIRM_PUTAWAY_GOODS = gql`
    mutation UnconfirmPutawayGoods($data: UnputawayGoodsInput!) {
        unconfirmPutawayGoods(data: $data) {
            code
            success
            message
        }
    }`;

export default INBOUND_JOB_UNCONFIRM_PUTAWAY_GOODS;
