import { gql } from '@apollo/client';

/** This v2 query is used to show all Authorized Scan IDs regardless
 * if the Scan ID is Picked, Packaged, or Dispatched. */

const GET_AUTHORIZED_SCAN_IDS_V2 = gql`
    query GetAuthorizedScanIds($WarehouseOutId: Int) {
        getAuthorizedScanIds(WarehouseOutId: $WarehouseOutId) {
            code
            success
            message
            results {
                Id
                ScanId
                WarehouseIn_JobNumber
                WarehouseOut_JobNumber
                OverpackId_PoPartLocation_Fk
                LabelSeries
                UnitOfMeasure_Fk
                TotalQuantity
                LengthInch
                WidthInch
                HeightInch
                LengthCm
                WidthCm
                HeightCm
                WeightLbs
                WeightKg
                VolumeCbm
                VolumeCft
                Warehouse_Fk
                StorageLocation_Fk
                Is_Overpack
                AuthorizedBy
                AuthorizedDate
                ConfirmedBy
                ConfirmedDate
                PutawayBy
                PutawayDate
                CreatedBy
                CreatedDate
                AttachedPOLineItemToScanId {
                    Id
                    LineNumber
                    PartNumber_Part_Fk
                    Part_Number
                    Part_Description
                    ExpectedQuantity
                    ReceivedQuantity
                    AvailableQuantity
                    AssignedQuantity
                }
                Warehouse {
                    Id
                    Name
                    Code
                }
                StorageLocation {
                    Id
                    Code
                }
            }
        }
    }
`;

export default GET_AUTHORIZED_SCAN_IDS_V2;
