import { gql } from '@apollo/client';

const GET_USERS_HIDDEN_PASSWORD = gql`
    query Users {
        users {
            code
            success
            message
            results {
            First_Name
            Last_Name
            Full_Name
            Email
            Password
            Role
            CreatedDate
            Account_Status
            BranchCode
            }
        }
    }
`;

export default GET_USERS_HIDDEN_PASSWORD;
