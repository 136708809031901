import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import {
  Button, Divider, Popconfirm, Space, Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/es/form/Form';
import { useLazyQuery } from '@apollo/client';

import UPDATE_INBOUND_JOB from '@graphql/TH_updateInbountJob';
import CREATE_INBOUND_JOB from '@graphql/TH_createInbountJob';
import GET_PURCHASE_ORDER_BY_ID from '@graphql/TH_getPurchaseOrderById';
import CANCEL_WAREHOUSE_INBOUND_JOB from '@graphql/cancelWarehouseInboundJob';

import useCreateUpdateRecord from '@hooks/useCreateUpdateRecord';

import { TableSmall } from '@components/Styles/TableStyles';
import { CardStyled } from '@components/Styles/CardStyles';
import { get } from 'lodash';
import { dateToString } from '@utils/helpers/date';
import InboundJobForm from '../Components/InboundJobForm';

function InboundJob({
  record,
  poLineItems,
  poLineItemsLoading,
  poLineItemColumns,
  listFilters,
  currentTab,
  onSuccess,
  onSetModalActions,
}) {
  const [formData, setFormData] = useState(null);
  const [formDataDelete, setFormDataDelete] = useState(null);

  const [inboundJobForm] = useForm();

  const [getPurchaseOrderById] = useLazyQuery(GET_PURCHASE_ORDER_BY_ID);

  const { loading } = useCreateUpdateRecord({
    formData,
    mutation: record?.Id ? UPDATE_INBOUND_JOB : CREATE_INBOUND_JOB,
    variables: Boolean(record?.Id) && { id: record?.Id },
    callback: () => handleSuccess(),
  });

  useCreateUpdateRecord({
    formData: formDataDelete,
    variables: { id: formDataDelete?.id },
    mutation: CANCEL_WAREHOUSE_INBOUND_JOB,
    isDelete: true,
    callback: () => handleSuccessDelete(),
  });

  const handleSubmit = useCallback(() => {
    inboundJobForm.submit();
  }, []);

  const handleSuccess = useCallback(() => {
    const closeModal = !record?.Id;
    onSuccess(closeModal);
  }, [record?.Id]);

  const handleSuccessDelete = useCallback(() => {
    onSuccess(true);
  }, []);

  const handleCancel = useCallback(() => {
    setFormDataDelete({ id: record?.Id });
  }, [record?.Id]);

  const handleFinishInboundJob = useCallback((values) => {
    const newData = { ...values };

    if (record?.Id) {
      delete newData.PurchaseOrder_Fk;
      delete newData.Consignee_Party_Fk;
      delete newData.Shipper_Party_Fk;

      if (record?.IsCustomerEditable) {
        newData.Customer_Party_Fk = get(values, 'Customer_Party_Fk.value', values.Customer_Party_Fk || null);
      } else {
        delete newData.Customer_Party_Fk;
      }
    } else {
      newData.Consignee_Party_Fk = get(values, 'Consignee_Party_Fk.value', values.Consignee_Party_Fk || null);
      newData.Customer_Party_Fk = get(values, 'Customer_Party_Fk.value', values.Customer_Party_Fk || null);
      newData.Shipper_Party_Fk = get(values, 'Shipper_Party_Fk.value', values.Shipper_Party_Fk || null);
    }

    newData.ModeOfTransport_Fk = get(values, 'ModeOfTransport_Fk.value', values.ModeOfTransport_Fk || null);
    newData.Supplier_Party_Fk = get(values, 'Supplier_Party_Fk.value', values.Supplier_Party_Fk || null);
    newData.Carrier_Party_Fk = get(values, 'Carrier_Party_Fk.value', values.Carrier_Party_Fk || null);
    newData.JobDate = dateToString(values.PODate);
    newData.ArrivalDate = dateToString(values.ArrivalDate);

    delete newData.JobNumber;
    delete newData.MrPrintedDate;
    delete newData.FirstMrPrintedDate;

    setFormData(newData);
  }, [record?.Id]);

  const handleValuesChange = useCallback(async (changedFields) => {
    if (Object.keys(changedFields)[0] === 'PurchaseOrder_Fk') {
      const poNumber = changedFields.PurchaseOrder_Fk;

      if (!poNumber) {
        listFilters.setValue(-1);
        return;
      }

      listFilters.setValue(poNumber);
      const { data } = await getPurchaseOrderById({ variables: { Id: poNumber } });
      const poDetails = get(data, 'result', {});

      inboundJobForm.setFieldsValue({
        Consignee_Party_Fk: poDetails.Consignee_Party_Fk ? {
          label: `[${poDetails.ConsigneeParty.Code}] ${poDetails.ConsigneeParty.Name}`,
          value: poDetails.ConsigneeParty.Id,
        } : null,
        ConsigneeReference: poDetails?.ConsigneeReference,
        ModeOfTransport_Fk: poDetails.ModeOfTransport_Fk ? {
          label: `[${poDetails.ModeOfTransport.Code}] ${poDetails.ModeOfTransport.Description}`,
          value: poDetails.ModeOfTransport.Id,
        } : null,
        Customer_Party_Fk: poDetails.Customer_Party_Fk ? {
          label: `[${poDetails.CustomerParty.Code}] ${poDetails.CustomerParty.Name}`,
          value: poDetails.CustomerParty.Id,
        } : null,
        CustomerReference: poDetails?.CustomerReference,
        Shipper_Party_Fk: poDetails.Shipper_Party_Fk ? {
          label: `[${poDetails.ShipperParty.Code}] ${poDetails.ShipperParty.Name}`,
          value: poDetails.ShipperParty.Id,
        } : null,
        ShipperReference: poDetails?.ShipperReference,
        Supplier_Party_Fk: poDetails.Supplier_Party_Fk ? {
          label: `[${poDetails.SupplierParty.Code}] ${poDetails.SupplierParty.Name}`,
          value: poDetails.SupplierParty.Id,
        } : null,
        SupplierReference: poDetails?.SupplierReference,
      });
    }
  }, [record, inboundJobForm]);

  useEffect(() => {
    if (currentTab === 'inboundJobs') {
      onSetModalActions([
        <Space key={1}>
          {record?.Id && (
            <Popconfirm
              title="Delete Inbound Job"
              description="Are you sure you want to delete this invoice job?"
              onConfirm={handleCancel}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger>
                Cancel
              </Button>
            </Popconfirm>
          )}
        </Space>,
        <Space key={2}>
          <Button type="primary" onClick={handleSubmit} disabled={loading} loading={loading}>
            {record?.Id ? 'Save' : 'Create'}
          </Button>
        </Space>,
      ]);
    }
  }, [loading, record?.Id, currentTab]);

  return (
    <Content style={{ padding: 16 }}>
      <InboundJobForm
        record={record}
        form={inboundJobForm}
        onFinish={handleFinishInboundJob}
        onValuesChange={handleValuesChange}
      />
      <Divider />
      <CardStyled>
        <Typography.Title level={5}>Available PO Line Items to receive</Typography.Title>
        <TableSmall
          style={{ marginTop: 24 }}
          size="small"
          loading={poLineItemsLoading}
          dataSource={poLineItems}
          columns={poLineItemColumns}
          pagination={false}
        />
      </CardStyled>
    </Content>
  );
}

export default memo(InboundJob);
