import { useLocation } from 'react-router-dom';

const useActiveMenu = () => {
  const { pathname } = useLocation();
  let key;
  switch (true) {
    case pathname.includes('/dashboard'):
      key = '/dashboard';
      break;
    case pathname.includes('/penetration-request/'):
      key = '/penetration-request';
      break;
    case pathname.includes('/closing-process/'):
      key = '/closing-process';
      break;
    case pathname.includes('/admin/'):
      key = 'sub6';
      break;
    default:
      key = 'sub8';
      break;
  }

  return { key, pathname };
};

export default useActiveMenu;
