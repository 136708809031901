import { gql } from '@apollo/client';

const GET_ONHAND_REPORT = gql`
    query GetGeneralPrintableOnHandReport {
        getGeneralPrintableOnHandReport {
            code
            success
            message
            count
            results
        }
    }
  `;

export default GET_ONHAND_REPORT;
