import { gql } from '@apollo/client';

export const GET_ALL_LOCATIONS = gql`
    query GET_ALL_LOCATIONS($limit: Int, $offset: Int, $searchText: String) {
        getAllLocation (limit: $limit, offset: $offset, searchText: $searchText) {
            code
            success
            message
            count
            results {
                Id
                City_Code
                Name
            }
        }
    }
`;

export default GET_ALL_LOCATIONS;
