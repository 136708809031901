import { gql } from '@apollo/client';

const GET_PRINT_FINAL_GOODS_RECEIPT_NOTE = gql`
    query GetPrintableFinalGoodsReceiptNote($Id: Int!) {
        getPrintableFinalGoodsReceiptNote(WarehouseInId: $Id) {
            code
            success
            message
            result {
                Id
                JobNumber
                PurchaseOrder {
                  PONumber
                  POType
                }
                ArrivalDate
                PackageCount
                ScanIDCount
                Carrier {
                  Id
                  Code
                  Name
                }
                CarrierReference
                Customer {
                  Code
                  Name
                  Website
                  Logo
                }
                CustomerParent {
                  Code
                  Name
                  Website
                  Logo
                }
                CustomerReference
                PurchaseOrder {
                  PONumber
                  POType
                  POStatus
                  PODate
                }
                Supplier {
                  Code
                  Name
                }
                SupplierReference
                Shipper {
                  Code
                  Name
                }
                ShipperReference
                Consignee {
                  Code
                  Name
                }
                ConsigneeReference
                AttachedScanIds {
                  Warehouse {
                    Code
                    Name
                  }
                  StorageLocation {
                    Code
                  }
                  UnitOfMeasure {
                    Id
                    Code
                    Description
                    Remarks
                }
                  ScanId
                  LotNumber
                  LabelSeries
                  LengthCm
                  WidthCm
                  HeightCm
                  LengthInch
                  WidthInch
                  HeightInch
                  ConfirmedDate # show status CONFIRMED if not empty
                  #Lot No
                  UnitOfMeasure_Fk # Show UOM Details, no field to capture UOM when creating Scan Id
                  VolumeCbm
                  VolumeCft
                  # Local Customs Ref
                  # Document Ref
                  WeightKg
                  WeightLbs
                  AttachedPOLineItemToScanId {
                    # PO Number
                    AssignedQuantity # Quantity
                    # UOM
                    LineNumber
                    LineStatus
                    UnitPrice
                    TotalPrice
                    AdditionalDescription
                    PurchaseOrder {
                      Id
                      PONumber
                      POStatus
                      PODate
                      POType
                      Remarks
                    }
                    Supplier {
                      Id
                      Name
                      Code
                    }
                    Part {
                      Id
                      Part_Number
                      Part_Description
                      Schedule_B
                      ECCN
                      Is_Hazardous
                    }
                    UnitOfMeasure {
                      Id
                      Code
                      Description
                      Remarks
                    }
                    Currency {
                      Id
                      Code
                      Description
                    }
                    ModeOfTransport {
                      Id
                      Code
                      Description
                    }
                    FinalDestination {
                      Id
                      Name
                      City_Code
                    }
                    CountryOfOrigin {
                      Id
                      Name
                      Code
                    }
                    Commodity {
                      Commodity
                      Descrip_1
                    }
                    Supplier {
                      Id
                      Code
                      Name
                    }
                  }
                }
                TotalWeightKgs
                TotalWeightLbs
                TotalCBM
                TotalCFT
                TotalPrice
            }
        }
    }
  `;

export default GET_PRINT_FINAL_GOODS_RECEIPT_NOTE;
