import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import {
  Col, Form, Row, Spin, Typography,
} from 'antd';
import { get } from 'lodash';
import { useLazyQuery } from '@apollo/client';

import GET_PO_LINE_ITEMS_BY_PURCHASE_ORDER_ID from '@graphql/getPOLineItemsByPurchaseOrderId';
import CREATE_PO_PARTS_DETAILS from '@graphql/TH_createPOPartsDetail';
import GET_ALL_PART from '@graphql/getAllPart';
import GET_PO_LINE_ITEM_BY_PART_ID from '@graphql/getPOLineItemByPartId';
import UPDATE_PO_PARTS_DETAILS from '@graphql/TH_updatePOPartsDetails';

import useCreateUpdateRecord from '@hooks/useCreateUpdateRecord';
import useDropdownList from '@hooks/useDropdownList';
import { getMaxLineNumber } from '@utils/helpers/helpers';

import { CardStyled } from '@components/Styles/CardStyles';
import { ContentStyled } from '@components/Styles/Global';
import PurchaseOrderPartForm from './PurchaseOrderPartForm';
import POLineItems from './PurchaseOrderPartsLineItemTable';
import OsdReasonList from './OsdReasonList';

function PurchaseOrderPartsV1({
  selectedRowId,
  onSubmitAction,
  onLoading,
  currentTab,
  poDetails,
}) {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState(null);
  const [isCreate, setIsCreate] = useState(true);
  const [selectedPartId, setSelectedPartId] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { data: parts } = useDropdownList({ query: GET_ALL_PART });

  const [getPOLineItems, { data, loading }] = useLazyQuery(GET_PO_LINE_ITEMS_BY_PURCHASE_ORDER_ID, {
    variables: { Id: selectedRowId },
  });

  const [getPartById, { data: partData, loading: partDetailsLoading }] = useLazyQuery(GET_PO_LINE_ITEM_BY_PART_ID);

  const poLineItemList = get(data, 'results', []);
  const partDetails = get(partData, 'result', {});

  const { loading: loadingPart } = useCreateUpdateRecord({
    formData,
    mutation: isCreate ? CREATE_PO_PARTS_DETAILS : UPDATE_PO_PARTS_DETAILS,
    variables: Boolean(partDetails?.Id) && { id: partDetails?.Id },
    callback: () => {
      if (isCreate) {
        form.resetFields();
      }
      getPOLineItems();
    },
  });

  const handleSelectRowKeys = useCallback((value) => {
    setSelectedRowKeys(value);
  }, []);

  const handleClear = useCallback(() => {
    setIsCreate(true);
    setSelectedPartId(null);
    setSelectedRowKeys([]);
    form.resetFields();
  }, []);

  const handleAdd = useCallback(() => {
    setIsCreate(true);
    setSelectedPartId(null);
    setSelectedRowKeys([]);
    form.resetFields();

    if (poDetails?.Id) {
      form.setFieldsValue({
        LineNumber: getMaxLineNumber(poLineItemList),
        PortOfLoading_ID: poDetails.PortOfLoading_Location_Fk ? {
          label: `[${poDetails.PortOfLoading.City_Code}] ${poDetails.PortOfLoading.Name}`,
          value: poDetails.PortOfLoading.Id,
        } : '',
        FinalDestination_Location_Fk: poDetails.FinalDestination_Location_Fk ? {
          label: `[${poDetails.FinalDestination.City_Code}] ${poDetails.FinalDestination.Name}`,
          value: poDetails.FinalDestination.Id,
        } : '',
        ModeOfTransport_Fk: poDetails.ModeOfTransport_Fk ? {
          label: `[${poDetails.ModeOfTransport.Code}] ${poDetails.ModeOfTransport.Description}`,
          value: poDetails.ModeOfTransport.Id,
        } : '',
        FreeBonded: 'FREE',
      });
    }
  }, [poLineItemList]);

  const handleSelectPart = useCallback((partId) => {
    form.resetFields();
    setSelectedPartId(partId);
    setIsCreate(false);
  }, []);

  const handleFinish = useCallback((values) => {
    const newData = {
      ...values,
      PurchasedOrder_Fk: selectedRowId,
      PartNumber_Part_Fk: get(values, 'PartNumber_Part_Fk.value', values.PartNumber_Part_Fk || null),
      UnitOfMeasure_Fk: get(values, 'UnitOfMeasure_Fk.value', values.UnitOfMeasure_Fk || null),
      Currency_Fk: get(values, 'Currency_Fk.value', values.Currency_Fk || null),
      FinalDestination_Location_Fk: get(values, 'FinalDestination_Location_Fk.value', values.FinalDestination_Location_Fk || null),
      ModeOfTransport_Fk: get(values, 'ModeOfTransport_Fk.value', values.ModeOfTransport_Fk || null),
      PortOfLoading_ID: get(values, 'PortOfLoading_ID.value', values.PortOfLoading_ID || null),
      Commodity_Fk: get(values, 'Commodity_Fk.value', values.Commodity_Fk || null),
    };
    setFormData(newData);
  }, []);

  useEffect(() => {
    if (selectedPartId) {
      getPartById({ variables: { Id: selectedPartId } });
    }
  }, [selectedPartId]);

  useEffect(() => {
    if (selectedRowId) {
      getPOLineItems();
    }
  }, [selectedRowId]);

  useEffect(() => {
    if (currentTab === 'poPart') {
      onSubmitAction(() => () => form.submit());
    }
  }, [currentTab]);

  useEffect(() => {
    if (currentTab === 'poPart') {
      onLoading(loadingPart);
    }
  }, [loadingPart]);

  return (
    <ContentStyled style={{ margin: 12 }}>
      <Row gutter={[12]}>
        <Col sm={6}>
          <CardStyled style={{ backgroundColor: '#fcfcfc' }}>
            <POLineItems
              onClear={handleClear}
              onAddClick={handleAdd}
              onSelect={handleSelectPart}
              onSelectRowKeys={handleSelectRowKeys}
              data={poLineItemList}
              loading={loading}
              parts={parts}
              selectedRowKeys={selectedRowKeys}
            />
          </CardStyled>
        </Col>
        <Col sm={18}>
          <CardStyled style={{ backgroundColor: '#fcfcfc' }}>
            <Typography.Title level={4}>{isCreate ? 'Add Part' : 'Edit Part'}</Typography.Title>
            <Spin spinning={partDetailsLoading}>
              <PurchaseOrderPartForm
                isCreate={isCreate}
                form={form}
                partDetails={partDetails}
                onFinish={handleFinish}
                parts={parts}
                poDetails={poDetails}
                poLineItemList={poLineItemList}
              />
            </Spin>
          </CardStyled>
          {!isCreate && (
            <CardStyled style={{ backgroundColor: '#fcfcfc' }}>
              <Typography.Title level={4}>OSD Reason</Typography.Title>
              <OsdReasonList detailId={selectedPartId} />
            </CardStyled>
          )}
        </Col>
      </Row>

    </ContentStyled>
  );
}

export default memo(PurchaseOrderPartsV1);
