import { gql } from '@apollo/client';

const CREATE_USER = gql`
    mutation CreateUser($userInput: UserInput!) {
        createUser(data: $userInput) {
            code
            success
            message
        }
    }
`;

export default CREATE_USER;
