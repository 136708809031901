import {
  Button, Form, Input, Select, Typography,
} from 'antd';
import styled from 'styled-components';

export const ButtonStyled = styled(Button)`
  background: linear-gradient(93.67deg, #449FE0 -16.02%, #196899 124.2%);
  border-radius: 32px !important;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
`;

export const ButtonDefault = styled(Button)`
  border-radius: 32px !important;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
`;

export const FormItemStyled = styled(Form.Item)`
  margin-bottom: 52px !important;

  .ant-form-item-row  {
    flex-direction: column;
    justify-content: flex-start;
  }

  .ant-form-item-label {
    text-align: left;
    margin-bottom: 12px;
    font-weight: bold;
  }
`;

export const InputStyled = styled(Input)`
  border-radius: 4px !important;
`;

export const InputPasswordStyled = styled(Input.Password)`
  border-radius: 4px !important;
`;

export const TitleStyled = styled(Typography.Title)`
    letter-spacing: 3px;
    margin-top: 20px;
    padding-bottom: 20px;
`;

export const FooterText = styled(Typography.Text)`
  margin-top: 40px;
  padding: 12px;
`;

export const ErrorText = styled(Typography.Paragraph)`
  color: red;
  font-size: 12px;
`;

export const SelectStyled = styled(Select)`
  width: 100% !important;

  .ant-select-selector {
    height: 40px !important;  
    border-radius: 4px;
  }

  .ant-select-selection-item {
    line-height: 36px !important;
  }
`;

export const ButtonActions = styled(Button)`
  border-radius: 0;
`;
