export const handleApolloResponse = (response, key, defaultValue = []) => {
  if (!key) {
    throw Error('key is required');
  }

  if (response?.data?.code === 'SUCCESS') {
    return response?.data[key] || defaultValue;
  }
  // Handle error case or return default value
  return defaultValue;
};
