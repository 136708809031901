import { gql } from '@apollo/client';

export const UPDATE_OVERPACK_SCAN_ID = gql`
    mutation UpdateOverPackScanId($id: Int, $data: PurchaseOrderPartLocationInput!) {
        updateOverPackScanId(id: $id, data: $data) {
            code
            success
            message
        }
    }`;

export default UPDATE_OVERPACK_SCAN_ID;
