import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import {
  Button, Space,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/es/form/Form';

import UPDATE_OUTBOUND_JOB from '@graphql/TH_updateOutboundJob';
import CREATE_OUTBOUND_JOB from '@graphql/TH_createOutboundJob';

import useCreateUpdateRecord from '@hooks/useCreateUpdateRecord';

import OutboundJobForm from '../Components/OutboundJobForm';

function InboundJob({
  record,
  currentTab,
  onSuccess,
  onSetModalActions,
}) {
  const [formData, setFormData] = useState(null);

  const [inboundJobForm] = useForm();

  const { loading } = useCreateUpdateRecord({
    formData,
    mutation: record?.Id ? UPDATE_OUTBOUND_JOB : CREATE_OUTBOUND_JOB,
    variables: Boolean(record?.Id) && { id: record?.Id },
    callback: () => handleSuccess(),
  });

  const handleSubmit = useCallback(() => {
    inboundJobForm.submit();
  }, []);

  const handleSuccess = useCallback(() => {
    const closeModal = !record?.Id;
    onSuccess(closeModal);
  }, [record?.Id]);

  const handleFinishOutboundJob = useCallback((values) => {
    const newData = { ...values };
    delete newData.JobStatus;
    delete newData.JobNumber;
    delete newData.JobDate;

    setFormData(newData);
  }, [record?.Id]);

  useEffect(() => {
    if (currentTab === 'outboundJobs') {
      onSetModalActions([
        <Space key={1}>&nbsp;</Space>,
        <Space key={2}>
          <Button type="primary" onClick={handleSubmit} disabled={loading} loading={loading}>
            {record?.Id ? 'Save' : 'Create'}
          </Button>
        </Space>,
      ]);
    }
  }, [loading, record?.Id, currentTab]);

  return (
    <Content style={{ padding: 16 }}>
      <OutboundJobForm
        record={record}
        form={inboundJobForm}
        onFinish={handleFinishOutboundJob}
      />
    </Content>
  );
}

export default memo(InboundJob);
