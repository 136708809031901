import React, {
  memo, useCallback, useEffect, useRef,
} from 'react';
import {
  Empty,
  Flex, List, Skeleton, Space,
  Typography,
} from 'antd';
import VirtualList from 'rc-virtual-list';
import { PlusOutlined } from '@ant-design/icons';

import { ListStyled } from '@components/Styles/ListStyles';
import ButtonTooltip from '@components/Button/ButtonTooltip';

function LoadPlanLineItems({
  data,
  loading,
  loadingAdd,
  onAddClick,
  onSelect,
  onSelectRowKeys,
  selectedRowKeys,
  onNewDataCallback,
}) {
  const listRef = useRef(null);

  const handleSelect = useCallback((partId, key) => {
    onSelect(partId);
    onSelectRowKeys([key]);
  }, []);

  const newData = (data || []).map((d, i) => ({
    ...d,
    key: i,
  }));

  useEffect(() => {
    if (data.length <= 0) return;

    onNewDataCallback(() => {
      const newRecord = newData[data.length - 1];
      onSelect(newRecord.Id);
      onSelectRowKeys([newRecord.key]);

      if (listRef.current) {
        listRef.current.scrollTo((data.length - 1) * 47);
      }
    });
  }, [data, onNewDataCallback]);

  return (
    <Flex vertical gap={16}>
      <Flex justify="space-between">
        <div>&nbsp;</div>
        <Space>
          <ButtonTooltip
            icon={<PlusOutlined />}
            onClick={onAddClick}
            tooltipProps={{
              title: 'Add Load Plan',
            }}
            loading={loadingAdd}
          />
        </Space>
      </Flex>
      <Skeleton loading={loading} active>
        <ListStyled
          bordered
          header={<Typography><strong>Load Plans</strong></Typography>}
        >
          {newData.length > 0 ? (
            <VirtualList
              data={newData}
              height={400}
              itemHeight={47}
              itemKey="Id"
            >
              {(item) => (
                <List.Item
                  key={item.email}
                  onClick={() => handleSelect(item?.Id, item?.key)}
                  className={selectedRowKeys.includes(item.key) ? 'selected' : ''}
                >
                  <List.Item.Meta
                    title={item.LoadPlanId}
                    description={`Container: ${item.ContainerId || ''}`}
                  />
                </List.Item>
              )}
            </VirtualList>
          ) : (
            <Empty description={false} imageStyle={{ height: 64 }} style={{ padding: 12 }} />
          )}
        </ListStyled>
      </Skeleton>
    </Flex>
  );
}

export default memo(LoadPlanLineItems);
