import { List } from 'antd';
import styled from 'styled-components';

export const ListStyled = styled(List)`
  & .selected {
    background-color: #dfeef8;
  }

  & .ant-list-item {
    cursor: pointer;
    padding: 8px 16px !important;
  }

  & .ant-list-header {
    background: #2f568b;
    border-radius: 8px 8px 0 0;

    & > .ant-typography {
      color: #fff;
    }
  }
`;
