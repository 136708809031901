import React, { memo, useCallback, useState } from 'react';
import { Button } from 'antd';

import { ModalStyled } from '@components/Styles/ModalStyles';
import ManualDistributionTransfer from '../Components/ManualDistributionTransfer';

function ManualDistribution({
  scanIdList,
  poLineItemList,
  onSuccess,
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCancel = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Button type="default" onClick={handleOpen}>
        Manual Distribution
      </Button>
      {open && (
        <ModalStyled
          title="Manual Distribution"
          open={open}
          onCancel={handleCancel}
          maskClosable={false}
          keyboard={false}
          width="95%"
          styles={{ body: { height: 'calc(90vh - 180px)', overflow: 'auto' } }}
          footer={[]}
        >
          <ManualDistributionTransfer
            scanIdList={scanIdList}
            poLineItemList={poLineItemList}
            onSuccess={onSuccess}
          />
        </ModalStyled>
      )}
    </>
  );
}

export default memo(ManualDistribution);
