import localforage from 'localforage';
import { get } from 'lodash';
import {
  ApolloClient, ApolloLink, createHttpLink, InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/link-error';

import { GRAPHQL_ROOT_URL } from '@constants/config';
import { message } from 'antd';
import useAuthStore from '@store/authStore';

const authLink = setContext(async (_, { headers }) => {
  const response = await localforage.getItem('auth');

  if (response) {
    try {
      const data = JSON.parse(response);
      const token = get(data, 'state.token');

      return {
        headers: {
          ...headers,
          authorization: token ? `JWT ${token}` : '',
        },
      };
    } catch (error) {
      console.log(error);
    }
  }

  return {
    headers,
  };
});

const errorLink = onError(({
  networkError, operation, forward,
}) => {
  if (networkError) {
    message.error(`[Network error]: ${networkError}`);
  }

  return forward(operation);
});

const dataLink = new ApolloLink((operation, forward) => forward(operation).map((response) => {
  if (response.data) {
    const keys = Object.keys(response.data);
    const firstKey = keys[0];

    if (response.data[firstKey]?.code === 'UNAUTHENTICATED') {
      message.error('Your session has expired. Please login again to continue.');
      useAuthStore.setState({ user: null, token: null });
    }

    return {
      ...response,
      data: response.data[firstKey],
    };
  }
  return response;
}));

const httpLink = createHttpLink({
  uri: GRAPHQL_ROOT_URL,
});

const link = ApolloLink.from([authLink, dataLink, errorLink, httpLink]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  fetchOptions: {
    mode: 'no-cors',
  },
});

export default client;
