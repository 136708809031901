import { gql } from '@apollo/client';

export const UPDATE_OUTBOUND_JOB = gql`
    mutation UpdateWarehouseOutboundJob($data: WarehouseOutInput!, $id: Int) {
        updateWarehouseOutboundJob(data: $data, id: $id) {
            code
            success
            message
        }
    }`;

export default UPDATE_OUTBOUND_JOB;
