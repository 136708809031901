import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    flexDirection: 'column',
    backgroundColor: '#fff',
    fontSize: 8,
    padding: 12,
    lineHeight: '1.4px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerImage: {
    width: 160,
    marginTop: -12,
  },
  fontBold: {
    fontWeight: 600,
  },
  fontNormal: {
    fontWeight: 400,
  },
  textLeft: {
    textAlign: 'left',
  },
  textRight: {
    textAlign: 'right',
  },
  tableNoBorder: {
    border: 'none',
  },
  tableCellAlignRight: {
    justifyContent: 'flex-end',
  },
  tableCellAlignLeft: {
    justifyContent: 'flex-end',
  },
  headerSection: {
    textAlign: 'center',
  },
  headerSectionText: {
    fontWeight: 600,
    fontSize: 20,
    textDecoration: 'underline',
  },
  headerSectionTextSmall: {
    fontWeight: 600,
    fontSize: 14,
  },
  textUnderline: {
    textDecoration: 'underline',
  },
  borderSmall: {
    borderTop: '1px solid #000',
    height: '1px',
  },
  cellLabel: {
    justifyContent: 'space-between',
  },
  packageSection: {
    borderBottom: '1px dashed #000',
  },
  scanIdSection: {
    borderBottom: '1px solid #000',
    borderTop: '1px dashed #000',
  },
  subTotalWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  subTotal: {
    width: '180px',
    padding: 4,
    borderBottom: '1px solid #000',
  },
  subTotalText: {
    fontWeight: 600,
  },
  subTotalHeaderText: {
    fontWeight: 600,
    textDecoration: 'underline',
    marginBottom: 8,
  },
  overpackSection: {
    borderTop: '2px solid #000',
  },
  grandTotalSection: {
    borderTop: '2px solid #000',
    borderBottom: '2px solid #000',
  },
  barcode: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  barcodeImg: {
    width: 300,
    height: 100,
    marginBottom: 4,
  },
  tdBottomBordered: {
    borderBottom: '1px solid #000',
  },
});

export default styles;
