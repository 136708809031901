import { gql } from '@apollo/client';

const GET_ALL_STORAGE_LOCATION = gql`
    query GetAllStorageLocation {
        getAllStorageLocation {
            code
            success
            message
            results {
                Id
                Code
            }
        }
    }
  `;

export default GET_ALL_STORAGE_LOCATION;
