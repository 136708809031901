import React, { memo } from 'react';
import {
  Form,
  Col,
  Row,
  InputNumber,
} from 'antd';

import { FormStyled } from '@components/Styles/FormStyles';

function DistributeQuantityForm({
  direction,
  form,
  onFinish,
}) {
  return (
    <FormStyled
      form={form}
      layout="horizontal"
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
      initialValues={{
        ReceivedQuantity: null,
      }}
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={direction === 'assignLeft' ? 'Quantity to Assign' : 'Quantity to Unassign'}
            name="ReceivedQuantity"
          >
            <InputNumber />
          </Form.Item>
        </Col>
      </Row>
    </FormStyled>
  );
}

export default memo(DistributeQuantityForm);
