import { gql } from '@apollo/client';

export const CREATE_PO_PARTS_DETAILS = gql`
    mutation CREATE_PO_PARTS_DETAILS($data: PODetailsInput!) {
        createPOPartsDetails(data: $data) {
            code
            success
            message
        }
    }`;

export default CREATE_PO_PARTS_DETAILS;
