import { gql } from '@apollo/client';

export const CREATE_LOAD_PLAN = gql`
    mutation CreateLoadPlan($data: LoadPlanInput!) {
        createLoadPlan(data: $data) {
            code
            success
            message
        }
    }`;

export default CREATE_LOAD_PLAN;
