import React, { memo, useState, useEffect } from 'react';
import {
  Form, Row, Col, Input, Radio,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useFormik } from 'formik';

import CREATE_PART from '@graphql/createPart';

import useCreateUpdateRecord from '@hooks/useCreateUpdateRecord';

import { ContentStyled } from '@components/Styles/Global';
import { CardStyled } from '@components/Styles/CardStyles';
import { FormStyled } from '@components/Styles/FormStyles';
import SelectCurrency from '@components/Select/SelectCurrency';
import SelectParentPart from '@components/Select/SelectParentPart';
import SelectUnitOfMeasure from '@components/Select/SelectUnitOfMeasure';
import SelectCommodity from '@components/Select/SelectCommodity';
import SelectParty from '@components/Select/SelectParty';
import SelectCountry from '@components/Select/SelectCountry';

function PartsAddForm({
  onSuccess,
  onSubmitAction,
  onLoading,
}) {
  const [form] = useForm();
  const [formData, setFormData] = useState(null);

  const { loading } = useCreateUpdateRecord({
    formData,
    mutation: CREATE_PART,
    callback: () => onSuccess(),
  });

  const formik = useFormik({
    initialValues: {
      Customer_Party_Fk: null,
      Supplier_Party_Fk: null,
      Part_Number: null,
      Part_Description: null,
      Remarks_1: null,
      Remarks_2: null,
      Remarks_3: null,
      Parent_Part_Fk: null,
      Schedule_B: null,
      ECCN: null,
      CountryOfOrigin_Country_Fk: null,
      Currency_Fk: null,
      Unit_Price: null,
      Customs_Unit_Price: null,
      UnitOfMeasure_Fk: null,
      Active: 1,
    },
    onSubmit: async (values) => {
      setFormData({
        Customer_Party_Fk: values.Customer_Party_Fk,
        Supplier_Party_Fk: values.Supplier_Party_Fk,
        Part_Number: values.Part_Number,
        Part_Description: values.Part_Description,
        Remarks_1: values.Remarks_1,
        Remarks_2: values.Remarks_2,
        Remarks_3: values.Remarks_3,
        Parent_Part_Fk: values.Parent_Part_Fk,
        Schedule_B: values.Schedule_B,
        ECCN: values.ECCN,
        CountryOfOrigin_Country_Fk: values.CountryOfOrigin_Country_Fk,
        Currency_Fk: values.Currency_Fk,
        Unit_Price: values.Unit_Price,
        Customs_Unit_Price: values.Customs_Unit_Price,
        UnitOfMeasure_Fk: values.UnitOfMeasure_Fk,
        Active: values.Active,
      });
    },
  });

  useEffect(() => {
    onSubmitAction(() => () => form.submit());
  }, []);

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  return (
    <ContentStyled style={{ margin: 12 }}>
      <CardStyled style={{ backgroundColor: '#fcfcfc' }}>
        <FormStyled
          form={form}
          layout="horizontal"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={formik.handleSubmit}
        >
          <Row>
            <Col sm={24}>
              <Form.Item
                label="Customer"
                name="Customer_Party_Fk"
                rules={[{ required: true, message: 'Required' }]}
              >
                <SelectParty
                  customFilter={(data) => data.filter((d) => d.Is_Customer === 1)}
                  onChange={(value) => {
                    formik.setFieldValue('Customer_Party_Fk', value);
                    formik.setFieldTouched('Customer_Party_Fk', true);
                  }}
                />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                label="Supplier"
                name="Supplier_Party_Fk"
                rules={[{ required: true, message: 'Required' }]}
              >
                <SelectParty
                  customFilter={(data) => data.filter((d) => d.Is_Supplier === 1)}
                  onChange={(value) => {
                    formik.setFieldValue('Supplier_Party_Fk', value);
                    formik.setFieldTouched('Supplier_Party_Fk', true);
                  }}
                />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                label="Part Number"
                name="Part_Number"
                rules={[{ required: true, message: 'Required' }]}
              >
                <Input
                  name="Part_Number"
                  value={formik.values.Part_Number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                label="Part Description"
                name="Part_Description"
                rules={[{ required: true, message: 'Required' }]}
              >
                <Input.TextArea
                  rows={2}
                  name="Part_Description"
                  value={formik.values.Part_Description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                label="Remarks 1"
                name="Remarks_1"

              >
                <Input.TextArea
                  rows={2}
                  name="Remarks_1"
                  value={formik.values.Remarks_1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                label="Remarks 2"
                name="Remarks_2"

              >
                <Input.TextArea
                  rows={2}
                  name="Remarks_2"
                  value={formik.values.Remarks_2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                label="Remarks 3"
                name="Remarks_3"

              >
                <Input.TextArea
                  rows={2}
                  name="Remarks_3"
                  value={formik.values.Remarks_3}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                label="Parent Part"
                name="Parent_Part_Fk"

              >
                <SelectParentPart
                  onChange={(value) => {
                    formik.setFieldValue('Parent_Part_Fk', value);
                    formik.setFieldTouched('Parent_Part_Fk', true);
                  }}
                />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                label="Schedule B/HS Code"
                name="Schedule_B"

              >
                <SelectCommodity
                  onChange={(value) => {
                    formik.setFieldValue('Schedule_B', value);
                    formik.setFieldTouched('Schedule_B', true);
                  }}
                />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                label="ECCN"
                name="ECCN"
              >
                <Input
                  name="ECCN"
                  value={formik.values.ECCN}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                label="Country of Origin"
                name="CountryOfOrigin_Country_Fk"
                rules={[{ required: true, message: 'Required' }]}
              >
                <SelectCountry
                  onChange={(value) => {
                    formik.setFieldValue('CountryOfOrigin_Country_Fk', value);
                    formik.setFieldTouched('CountryOfOrigin_Country_Fk', true);
                  }}
                />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                label="Currency"
                name="Currency_Fk"
              >
                <SelectCurrency
                  onChange={(value) => {
                    formik.setFieldValue('Currency_Fk', value);
                    formik.setFieldTouched('Currency_Fk', true);
                  }}
                />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                label="Unit Price"
                name="Unit_Price"
              >
                <Input
                  type="number"
                  value={formik.values.Unit_Price}
                  style={{ width: '50%' }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  precision={2}
                />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                label="Customs Unit Price"
                name="Customs_Unit_Price"
              >
                <Input
                  type="number"
                  value={formik.values.Customs_Unit_Price}
                  style={{ width: '50%' }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  precision={2}
                />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                label="UOM"
                name="UnitOfMeasure_Fk"
                rules={[{ required: true, message: 'Required' }]}
              >
                <SelectUnitOfMeasure
                  onChange={(value) => {
                    formik.setFieldValue('UnitOfMeasure_Fk', value);
                    formik.setFieldTouched('UnitOfMeasure_Fk', true);
                  }}
                />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                label="Active"
                name="Active"
                validateStatus={formik.errors.Active ? 'error' : 'success'}
                help={formik.errors.Active}
              >
                <Radio.Group
                  defaultValue={1}
                  value={formik.values.Active}
                  onChange={(e) => {
                    formik.setFieldValue('Active', e.target.value);
                    formik.setFieldTouched('Active', true);
                  }}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </FormStyled>
      </CardStyled>
    </ContentStyled>
  );
}

export default memo(PartsAddForm);
