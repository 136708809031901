import React, {
  memo, useState, useRef, useEffect, useCallback,
} from 'react';
import {
  Button, Table, Modal, Form, Row, Col, Input, Skeleton,
  Typography, notification, Popconfirm, message, Pagination,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { get } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PlusOutlined, LockOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { Content } from 'antd/es/layout/layout';
import styled from 'styled-components';

import GET_USERS_HIDDEN_PASSWORD from '@graphql/getAllUsersQuery';
import CREATE_USER from '@graphql/createUser';
import UPDATE_USER from '@graphql/updateUser';

import { ACCOUNT_STATUS, ACCOUNT_STATUS_COLORS } from '@constants/accountStatus';
import usePermissions from '@hooks/usePermissions';

import CustomSelect from '@components/Select/CustomSelect';

const TableStyled = styled(Table)`
  
  table  {
    color: #666666;
    .ant-table-thead .ant-table-column-has-sorters:hover {
      background: #4c83a7;
    }
    .ant-table-thead > tr th {
      background: #22577A;
      color: #fff;
      padding: 12px !important;
      .anticon-caret-up.active, .anticon-caret-down.active {
        color: #fff;
      }
    }
    .ant-table-thead > tr th:first-child, .ant-table-thead > tr th:last-child {
      border-radius: 0 !important;
    }
    .ant-table-thead {
      .ant-table-column-has-sorters {
        background: #22577A;
      }
    }
    a {
      color: #22577A;
    }
  }
`;
const ButtonStyled = styled(Button)`
  position: absolute;
  top: 21px;
  right: 0;
  margin: 0 25px 25px 25px;
  border-radius: 12px;
  background: #57CC8D;
  color: #fff;
  border: 0;
  &:hover {
    color: #fff !important;
    background: #38A3A5;
  }
`;
const FormItemStyled = styled(Form.Item)`
  margin-bottom: 52px !important;

  .ant-form-item-row  {
    flex-direction: column;
    justify-content: flex-start;
  }

  .ant-form-item-label {
    text-align: left;
    margin-bottom: 12px;
    font-weight: bold;
  }
`;

const InputStyled = styled(Input)`
  border-radius: 4px !important;
`;
const ErrorText = styled(Typography.Paragraph)`
  color: red;
  font-size: 12px;
`;

const PaginationStyled = styled(Pagination)`
  margin-top: 12px;
  text-align: right;
  margin-bottom: 22px;
  margin-right: 18px;
`;

function Users() {
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { data, loading } = useQuery(GET_USERS_HIDDEN_PASSWORD);

  const users = get(data, 'results', []);

  const total = get(data, 'count', []);
  const [updateUser] = useMutation(UPDATE_USER);

  const [createUser] = useMutation(CREATE_USER);

  const { isAdmin } = usePermissions();

  const [open, setOpen] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);
  const [resetChildComponent, setResetChildComponent] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const selectRef = useRef(null);
  const [form] = useForm();

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (hasMounted && !open) {
      clearModal2();
    } else {
      setHasMounted(true);
    }
  }, [open]);

  // functions
  const showModal = () => {
    setResetChildComponent('test');
    setOpen(true);
  };
  const handleOk = () => {
    setConfirmLoading(true);
    document.getElementById('submitModal').click();
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    setOpen(false);
    clearModal();
  };

  const clearModal = () => {
    form.resetFields(); // Clear the form fields
    formik.setFieldValue('role', false);
  };
  const clearModal2 = () => {
    if (!open) {
      form.resetFields(); // Clear the form fields
      formik.setFieldValue('role', false);
    }
  };

  const validationSchema = Yup.object().shape({
    fullname: Yup.string().required('Required'),
    email: Yup.string().required('Required'),
    company: Yup.string().required('Required'),
    phone: Yup.string().required('Required'),
    role: Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      company: '',
      phone: '',
      role: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await createUser(
          {
            variables: {
              userInput: {
                fullname: values.fullname,
                email: values.email,
                company: values.company,
                phone: values.phone,
                role: values.role,
              },
            },
          },
        );
        successNotif('success');
        setOpen(false);
      } catch (error) {
        errorNotif('error', error.message);
      }
    },
  });

  const successNotif = (type) => {
    api[type]({
      message: 'User Created',
      description: 'The user has been successfully created',

    });
  };

  const errorNotif = (type, error) => {
    api[type]({
      message: 'Error',
      description: error,

    });
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'Full_Name',
      key: 'Full_Name',
      defaultSortOrder: 'none',
      render: (text) => (
        <a style={{ userSelect: 'text' }}>
          {text}
        </a>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      key: 'Email',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'Role',
      key: 'Role',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Branch Code',
      dataIndex: 'BranchCode',
      key: 'BranchCode',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'Account_Status',
      key: 'Account_Status',
      render: (text, record) => (
        <>
          <ButtonStyled
            style={{
              background: ACCOUNT_STATUS_COLORS[record.Account_Status],
              margin: 0,
              position: 'unset',
              width: '75px',
              height: '32px',
              pointerEvents: 'none',
              cursor: 'default',
            }}
          >
            {record.Account_Status}

          </ButtonStyled>

          {(record.Account_Status === ACCOUNT_STATUS.LOCKED && isAdmin) && (
            <Popconfirm
              title={`Activate ${record.Full_Name}`}
              description="Are you sure to activate this user?"
              onConfirm={() => handleUpdateStatus(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ marginLeft: '5px' }}>
                <LockOutlined />
              </a>
            </Popconfirm>
          )}
        </>
      ),
    },

  ];

  const handleUpdateStatus = useCallback(async (id) => {
    try {
      const response = await updateUser({
        variables: {
          updateID: id,
          updateInput: {
            accountStatus: 'ACTIVE',
            loginAttempts: 0,
          },
        },
      });
      const { success, message: msg } = get(response, 'data.updateUser') || {};

      const type = success ? 'success' : 'error';
      message[type]({
        type,
        content: msg,
      });
      if (success) {
        return response;
      }
    } catch (e) {
      message.error({
        type: 'error',
        content: e.message,
      });
      return new Promise((resolve) => {
        resolve(null);
      });
    }
  });

  return (
    <Content style={{ margin: '0px 25px 25px' }}>
      <ButtonStyled style={{}} icon={<PlusOutlined />} onClick={showModal}>Add Users</ButtonStyled>
      <br />
      <br />
      <br />
      {contextHolder}
      <Modal
        title="Add User"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Content style={{ margin: '0px 25px 25px' }}>
          <Form form={form} onFinish={formik.handleSubmit}>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={24}>
                <FormItemStyled
                  label="Fullname"
                  name="fullname"
                  colon={false}
                >
                  <InputStyled
                    size="large"
                    name="fullname"
                    placeholder="Fullname"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormItemStyled>
                {formik.touched.fullname && formik.errors.fullname ? (
                  <ErrorText>{formik.errors.fullname}</ErrorText>
                ) : null}
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24}>
                <FormItemStyled
                  label="Email"
                  name="email"
                  colon={false}
                >
                  <InputStyled
                    size="large"
                    name="email"
                    placeholder="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                  />
                </FormItemStyled>
                {formik.touched.email && formik.errors.email ? (
                  <ErrorText>{formik.errors.email}</ErrorText>
                ) : null}
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24}>
                <FormItemStyled
                  label="Company"
                  name="company"
                  colon={false}
                >
                  <InputStyled
                    size="large"
                    name="company"
                    placeholder="company"
                    onChange={formik.handleChange}
                    value={formik.values.company}
                    onBlur={formik.handleBlur}
                  />
                </FormItemStyled>
                {formik.touched.company && formik.errors.company ? (
                  <ErrorText>{formik.errors.company}</ErrorText>
                ) : null}
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24}>
                <FormItemStyled
                  label="Phone"
                  name="phone"
                  colon={false}
                >
                  <InputStyled
                    size="large"
                    name="phone"
                    placeholder="phone"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                    onBlur={formik.handleBlur}
                  />
                </FormItemStyled>
                {formik.touched.phone && formik.errors.phone ? (
                  <ErrorText>{formik.errors.phone}</ErrorText>
                ) : null}
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={24}>
                <FormItemStyled
                  label="Role"
                  name="role"
                  colon={false}
                >
                  <CustomSelect
                    options={[
                      {
                        value: '',
                        label: 'Select Role',
                      },
                      {
                        value: 'SUPERADMIN',
                        label: 'SUPERADMIN',
                      },
                      {
                        value: 'REQUESTOR',
                        label: 'REQUESTOR',
                      },
                      {
                        value: 'PACKAGE_ENGINEER',
                        label: 'PACKAGE ENGINEER',
                      },
                      {
                        value: 'ARCHITECTURAL',
                        label: 'ARCHITECTURAL',
                      },
                      {
                        value: 'STRUCTURAL',
                        label: 'STRUCTURAL',
                      },
                      {
                        value: 'SPACE_MANAGEMENT',
                        label: 'SPACE MANAGEMENT',
                      },
                      {
                        value: 'COMMERCIAL',
                        label: 'COMMERCIAL',
                      },
                      {
                        value: 'PROJECT_MANAGER',
                        label: 'PROJECT MANAGER',
                      },
                      {
                        value: 'EXYTE_ADMIN',
                        label: 'EXYTE ADMIN',
                      },
                      {
                        value: 'CONTRACTOR',
                        label: 'CONTRACTOR',
                      },
                      {
                        value: 'CC_TEAM',
                        label: 'CC TEAM',
                      },
                    ]}
                    value={formik.values.role}
                    name="role"
                    onChange={(value) => formik.setFieldValue('role', value)}
                    selectRef={selectRef}
                    openModal={open}
                    reset={resetChildComponent}
                  />
                </FormItemStyled>
                {formik.touched.role && formik.errors.role ? (
                  <ErrorText>{formik.errors.role}</ErrorText>
                ) : null}
              </Col>
            </Row>
            <Row style={{ justifyContent: 'flex-end', marginTop: '70px' }}>
              <Col>
                <Form.Item>
                  <Button type="primary" htmlType="submit" id="submitModal" hidden>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Content>
      </Modal>

      <Skeleton loading={loading} active>
        <TableStyled
          size="small"
          dataSource={users}
          columns={columns}
          pagination={false}
        />
        <PaginationStyled
          showSizeChanger={false}
          current={currentPage}
          pageSize={pageSize}
          total={total}
          onChange={(page, newPageSize) => {
            setCurrentPage(page);
            setPageSize(newPageSize);
          }}
        />
      </Skeleton>
    </Content>
  );
}

export default memo(Users);
