import { gql } from '@apollo/client';

const GET_ALL_WAREHOUSE = gql`
    query GetAllWarehouse {
        getAllWarehouse {
            code
            success
            message
            results {
                Id
                Code
                Name
            }
        }
    }
  `;

export default GET_ALL_WAREHOUSE;
