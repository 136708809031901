import { gql } from '@apollo/client';

const UPDATE_PO_PARTS_DETAILS = gql`
    mutation UpdatePOPartsDetails($id: Int!, $data: PODetailsInput!) {
        updatePOPartsDetails(id: $id, data: $data) {
            code
            success
            message
        }
    }
`;

export default UPDATE_PO_PARTS_DETAILS;
