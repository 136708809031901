import React, { memo, useCallback } from 'react';
import {
  Page, Document,
  View,
  Image,
  Text,
} from '@react-pdf/renderer';
import {
  TD, TR, Table,
} from '@ag-media/react-pdf-table';
import dayjs from 'dayjs';

import useLogo from '@hooks/useLogo';

import '@components/Font/Roboto';
import styles from '@components/Styles/PDFStyles';
import { get } from 'lodash';
import { getAsString, parseAndToFixed } from '@utils/helpers/helpers';
import getDefaultLogo from '@config/defaultLogo';

function PrintCIPL({
  data,
  onLoadPrintFile,
  user,
}) {
  const defaultLogo = getDefaultLogo();
  const logo = useLogo({ logoUrl: defaultLogo });

  const handleRender = useCallback((blob) => {
    onLoadPrintFile(blob);
  }, []);

  return (
    <Document onRender={handleRender}>
      <Page size="A4" style={styles.page}>
        <View style={styles.header} fixed>
          <View>
            <Image
              style={styles.headerImage}
              src={logo}
            />
          </View>
          <View>
            <Table
              style={{ ...styles.tableNoBorder, ...{ width: '140px' } }}
              weightings={[0.4, 0.6]}
            >
              <TR>
                <TD style={styles.cellLabel}>
                  <Text>Page</Text>
                  <Text>{' : '}</Text>
                </TD>
                <TD>
                  <Text
                    render={({ pageNumber, totalPages }) => (
                      `${pageNumber} / ${totalPages}`
                    )}
                  />
                </TD>
              </TR>
              <TR>
                <TD style={styles.cellLabel}>
                  <Text>Printed By</Text>
                  <Text>{' : '}</Text>
                </TD>
                <TD>
                  {user?.Full_Name}
                </TD>
              </TR>
              <TR>
                <TD style={styles.cellLabel}>
                  <Text>Printed Date</Text>
                  <Text>{' : '}</Text>
                </TD>
                <TD>
                  {dayjs().format('DD MMM YYYY')}
                </TD>
              </TR>
            </Table>
          </View>
        </View>
        <View style={styles.headerSection}>
          <Text style={styles.headerSectionText}>Commercial Invoice & Export Packing List</Text>
        </View>
        <View style={{ height: 12 }} />
        <Text style={styles.fontBold}>COMMERCIAL INVOICE #: </Text>
        <View style={{ height: 4 }} />
        <View style={styles.borderSmall} />
        <View style={{ height: 4 }} />
        <Table
          style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
          tdStyle={{ alignItems: 'start', fontWeight: 600, padding: 2 }}
          weightings={[0.25, 0.25, 0.2, 0.3]}
        >
          <TR>
            <TD style={styles.cellLabel}>
              <Text>Shipper</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{get(data, 'CIPLSection1.Shipper.Code', '') && `[${get(data, 'CIPLSection1.Shipper.Code', '')}] ${get(data, 'CIPLSection1.Shipper.Name', '')} `}</Text>
            </TD>
            <TD style={styles.cellLabel}>
              <Text>Consignee</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{get(data, 'CIPLSection1.Consignee.Code', '') && `[${get(data, 'CIPLSection1.Consignee.Code', '')}] ${get(data, 'CIPLSection1.Consignee.Name', '')} `}</Text>
            </TD>
          </TR>
        </Table>
        <View style={{ height: 4 }} />
        <View style={styles.borderSmall} />
        <View style={{ height: 4 }} />
        <Table
          style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
          tdStyle={{ alignItems: 'start', fontWeight: 600, padding: 2 }}
          weightings={[0.25, 0.25, 0.2, 0.3]}
        >
          <TR>
            <TD style={styles.cellLabel}>
              <Text>SHIPPED VIA</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{get(data, 'CIPLSection2.ShippedVia.Code', '') && `[${get(data, 'CIPLSection2.ShippedVia.Code', '')}] ${get(data, 'CIPLSection2.ShippedVia.Description', '')} `}</Text>
            </TD>
            <TD style={styles.cellLabel}>
              <Text>ETD</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{`${get(data, 'CIPLSection2.ETD', '')} `}</Text>
            </TD>
          </TR>
          <TR>
            <TD style={styles.cellLabel}>
              <Text>CARRIER</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{get(data, 'CIPLSection2.Carrier.Code', '') && `[${get(data, 'CIPLSection2.Carrier.Code', '')}] ${get(data, 'CIPLSection2.Carrier.Description', '')} `}</Text>
            </TD>
            <TD style={styles.cellLabel}>
              <Text>ETA</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{`${get(data, 'CIPLSection2.ETA', '')} `}</Text>
            </TD>
          </TR>
          <TR>
            <TD style={styles.cellLabel}>
              <Text>VESSEL / VOYAGE NO</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{`${get(data, 'CIPLSection2.VesselVoyageNo', '')} `}</Text>
            </TD>
            <TD style={styles.cellLabel}>
              <Text>DESTINATION</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>
                {[
                  get(data, 'CIPLSection2.Destination.Name', ''),
                  get(data, 'CIPLSection2.Destination.City_Code', ''),
                  get(data, 'CIPLSection2.Destination.Country_Code', ''),
                ].filter(Boolean).join('')}
              </Text>
            </TD>
          </TR>
        </Table>
        <View style={{ height: 4 }} />
        <View style={styles.borderSmall} />
        <View style={{ height: 4 }} />
        <Table
          style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
          tdStyle={{ alignItems: 'start', fontWeight: 600, padding: 2 }}
          weightings={[0.25, 0.75]}
        >
          <TR>
            <TD style={styles.cellLabel}>
              <Text>Notify</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              &nbsp;
            </TD>
          </TR>
          <TR>
            <TD style={styles.cellLabel} />
            <TD>
              <Text>{`${get(data, 'CIPLSection3.VesselVoyageNo', '')} `}</Text>
            </TD>
          </TR>
        </Table>
        <View style={{ height: 4 }} />
        <View style={styles.borderSmall} />
        <View style={{ height: 4 }} />
        <Table
          style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
          tdStyle={{ alignItems: 'start', fontWeight: 600, padding: 2 }}
          weightings={[0.25, 0.25, 0.2, 0.3]}
        >
          <TR>
            <TD style={styles.cellLabel}>
              <Text>BILL OF LADING NO</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{`${get(data, 'CIPLSection4.BillOfLading_FreightJobNumber', '')} `}</Text>
            </TD>
            <TD style={styles.cellLabel}>
              <Text>CONTAINER TYPE</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{`${get(data, 'CIPLSection4.ContainerType', '')} `}</Text>
            </TD>
          </TR>
          <TR>
            <TD style={styles.cellLabel}>
              <Text>CONTAINER OF PACKING UNIT NO</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{`${get(data, 'CIPLSection4.ContainerOrPackingUnitNo', '')} `}</Text>
            </TD>
            <TD style={styles.cellLabel}>
              <Text>SEAL NO IF USED</Text>
              <Text>{' : '}</Text>
            </TD>
            <TD>
              <Text>{`${get(data, 'CIPLSection4.SealNoIfUsed', '')} `}</Text>
            </TD>
          </TR>
        </Table>
        <View style={{ height: 4 }} />
        <View style={styles.borderSmall} />
        <View style={{ height: 4 }} />

        {(data?.OverpackSections || []).map((overpackData, overpackIndex) => (
          <View key={overpackData.OverpackScanId} style={overpackIndex > 0 && styles.overpackSection}>
            {overpackIndex.index > 0 && <View style={{ height: 8 }} />}
            <Table
              style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
              tdStyle={{ alignItems: 'start', fontWeight: 600, padding: 2 }}
              weightings={[0.15, 0.2, 0.10, 0.2, 0.15, 0.2]}
            >
              <TR>
                <TD style={styles.cellLabel}>
                  <Text>PACKING UNIT #</Text>
                  <Text>{' : '}</Text>
                </TD>
                <TD>
                  <Text>{get(overpackData, 'PackingUnitNo', '')}</Text>
                </TD>
                <TD style={styles.cellLabel}>
                  <Text>UOM</Text>
                  <Text>{' : '}</Text>
                </TD>
                <TD>
                  <Text>{get(overpackData, 'UnitofMeasure.Description', '')}</Text>
                </TD>
                <TD style={styles.cellLabel}>
                  <Text>OVERPACK #</Text>
                  <Text>{' : '}</Text>
                </TD>
                <TD>
                  <Text>{get(overpackData, 'OverpackScanId', '')}</Text>
                </TD>
              </TR>
            </Table>
            <View style={{ height: 8 }} />

            {(overpackData?.POLineItems?.LineItems || []).map((poLineItem, poLineItemIndex) => (
              <View key={poLineItem?.ItemNo}>
                <View style={styles.packageSection}>
                  <Table
                    style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
                    tdStyle={{
                      padding: '2px',
                      flexWrap: 'wrap',
                    }}
                  >
                    {poLineItemIndex === 0 && (
                      <TR>
                        <TD><Text style={styles.textUnderline}>ITEM #</Text></TD>
                        <TD><Text style={styles.textUnderline}>QTY</Text></TD>
                        <TD><Text style={styles.textUnderline}>UOM</Text></TD>
                        <TD><Text style={styles.textUnderline}>PART #</Text></TD>
                        <TD><Text style={styles.textUnderline}>CO OF ORIGIN</Text></TD>
                        <TD><Text style={styles.textUnderline}>PO NUMBER</Text></TD>
                        <TD><Text style={styles.textUnderline}>SCHEDULE B/HS CODE</Text></TD>
                        <TD><Text style={styles.textUnderline}>MR #</Text></TD>
                        <TD><Text style={styles.textUnderline}>SUPPLIER</Text></TD>
                        <TD><Text style={styles.textUnderline}>UNIT PRICE</Text></TD>
                        <TD><Text style={styles.textUnderline}>TOTAL COST</Text></TD>
                      </TR>
                    )}
                    <TR>
                      <TD><Text>{get(poLineItem, 'ItemNo', '')}</Text></TD>
                      <TD><Text>{get(poLineItem, 'Quantity', '')}</Text></TD>
                      <TD><Text>{getAsString(poLineItem, 'UOM', '').toString().split('')}</Text></TD>
                      <TD><Text>{getAsString(poLineItem, 'PartNumber', '').toString().split('')}</Text></TD>
                      <TD><Text>{get(poLineItem, 'CountryOfOrigin', '')}</Text></TD>
                      <TD><Text>{getAsString(poLineItem, 'PONumber', '').toString().split('')}</Text></TD>
                      <TD><Text>{getAsString(poLineItem, 'ScheduleB', '').toString().split('')}</Text></TD>
                      <TD><Text>{getAsString(poLineItem, 'MRNumberWarehouseInboundJob', '').toString().split('')}</Text></TD>
                      <TD><Text>{getAsString(poLineItem, 'Supplier', '').toString().split('')}</Text></TD>
                      <TD><Text>{parseAndToFixed(get(poLineItem, 'UnitPrice', ''))}</Text></TD>
                      <TD><Text>{parseAndToFixed(get(poLineItem, 'TotalCost', ''))}</Text></TD>
                    </TR>
                  </Table>
                  <Table
                    style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
                    tdStyle={{ alignItems: 'start', padding: 2 }}
                    weightings={[0.2, 0.3, 0.15, 0.1, 0.1, 0.15]}
                  >
                    <TR>
                      <TD style={styles.cellLabel}>
                        <Text>PART DESCRIPTION</Text>
                        <Text>{' : '}</Text>
                      </TD>
                      <TD>
                        <Text>{get(poLineItem, 'PartDescription', '')}</Text>
                      </TD>
                      <TD style={styles.cellLabel}>
                        <Text>NET WEIGHT (KGS)</Text>
                        <Text>{' : '}</Text>
                      </TD>
                      <TD>
                        <Text>{get(poLineItem, 'NetWeightKgs', '')}</Text>
                      </TD>
                      <TD style={styles.cellLabel}>
                        <Text>ECCN</Text>
                        <Text>{' : '}</Text>
                      </TD>
                      <TD>
                        <Text>{get(poLineItem, 'ECCN', '')}</Text>
                      </TD>
                    </TR>
                    <TR>
                      <TD style={styles.cellLabel}>
                        <Text>ADDITIONAL DESCRIPTION</Text>
                        <Text>{' : '}</Text>
                      </TD>
                      <TD>
                        <Text>{get(poLineItem, 'AdditionalDescription', '')}</Text>
                      </TD>
                      <TD style={styles.cellLabel}>
                        &nbsp;
                      </TD>
                      <TD>
                        &nbsp;
                      </TD>
                      <TD style={styles.cellLabel}>
                        &nbsp;
                      </TD>
                      <TD>
                        &nbsp;
                      </TD>
                    </TR>
                  </Table>
                </View>
              </View>
            ))}

            <View style={{ height: 4 }} />
            <View style={styles.subTotalWrapper}>
              <View style={styles.subTotal}>
                <Text style={styles.subTotalText}>
                  SUBTOTAL PER P.O.:
                  {' '}
                  {parseAndToFixed(get(overpackData, 'POLineItems.SubTotalCostPerPO', ''))}
                </Text>
              </View>
            </View>

            <View>
              <Text style={styles.subTotalHeaderText}>
                SUBTOTAL FOR CRATE #:
                {`${overpackIndex + 1} of ${data?.OverpackSections.length}`}
              </Text>
              <Table
                style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
                tdStyle={{ alignItems: 'start', padding: 2 }}
                weightings={[0.15, 0.15, 0.1, 0.2, 0.15, 0.25]}
              >
                <TR>
                  <TD style={styles.cellLabel}>
                    <Text>WEIGHT/KG</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{parseAndToFixed(get(overpackData, 'SubTotalWeightKgs', ''), 2)}</Text>
                  </TD>
                  <TD style={styles.cellLabel}>
                    <Text>CBM</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{parseAndToFixed(get(overpackData, 'SubTotalCBM', ''), 3)}</Text>
                  </TD>
                  <TD style={styles.cellLabel}>
                    <Text>DIMENSIONS CM</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>
                      {`${parseAndToFixed(get(overpackData, 'SubTotalLengthCm', '')) || 0}`}
                      {` x ${parseAndToFixed(get(overpackData, 'SubTotalWidthCm', '')) || 0}`}
                      {` x ${parseAndToFixed(get(overpackData, 'SubTotalHeightCm', '')) || 0}`}
                    </Text>
                  </TD>
                </TR>
                <TR>
                  <TD style={styles.cellLabel}>
                    <Text>WEIGHT/LBS</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{parseAndToFixed(get(overpackData, 'SubTotalWeightLbs', ''), 2)}</Text>
                  </TD>
                  <TD style={styles.cellLabel}>
                    <Text>CFT</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>{parseAndToFixed(get(overpackData, 'SubTotalCFT', ''), 3)}</Text>
                  </TD>
                  <TD style={styles.cellLabel}>
                    <Text>DIMENSIONS INCH</Text>
                    <Text>{' : '}</Text>
                  </TD>
                  <TD>
                    <Text>
                      {`${parseAndToFixed(get(overpackData, 'SubTotalLengthInch', '')) || 0}`}
                      {` x ${parseAndToFixed(get(overpackData, 'SubTotalWidthInch', '')) || 0}`}
                      {` x ${parseAndToFixed(get(overpackData, 'SubTotalHeightInch', '')) || 0}`}
                    </Text>
                  </TD>
                </TR>
              </Table>
              <View style={{ height: 8 }} />
              <Table
                style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
                tdStyle={{ alignItems: 'start' }}
                weightings={[0.5, 0.5]}
              >
                <TR>
                  <TD>
                    <Text style={styles.subTotalHeaderText}>TOTALS FOR CONTAINER</Text>
                  </TD>
                  <TD>
                    <Text style={styles.subTotalHeaderText}>P.O. SUMMARY FOR CONTAINER</Text>
                  </TD>
                </TR>
                <TR>
                  <TD>
                    <Table
                      style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
                      tdStyle={{ alignItems: 'start', padding: 2 }}
                      weightings={[0.3, 0.7]}
                    >
                      <TR>
                        <TD style={styles.cellLabel}>
                          <Text>TOTAL UNIT</Text>
                          <Text>{' : '}</Text>
                        </TD>
                        <TD>
                          <Text>{get(overpackData, 'TotalUnit', '')}</Text>
                        </TD>
                      </TR>
                      <TR>
                        <TD style={styles.cellLabel}>
                          <Text>WEIGHT/KG</Text>
                          <Text>{' : '}</Text>
                        </TD>
                        <TD>
                          <Text>{parseAndToFixed(get(overpackData, 'TotalWeightKgs', ''), 2)}</Text>
                        </TD>
                      </TR>
                    </Table>
                  </TD>
                  <TD>
                    <Text>
                      {get(overpackData, 'POSummaryForContainer', []).join(', ')}
                    </Text>
                  </TD>
                </TR>
              </Table>
            </View>
            <View style={{ height: 8 }} />
          </View>
        ))}

        <View style={styles.grandTotalSection}>
          <View style={{ height: 4 }} />
          <Table
            style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
            tdStyle={{ alignItems: 'start' }}
            weightings={[0.5, 0.3, 0.2]}
          >
            <TR>
              <TD>
                <Text style={styles.subTotalHeaderText}>GRAND TOTAL COST FOR PACKING LIST:</Text>
              </TD>
              <TD>
                <Text style={styles.subTotalHeaderText}>GRAND TOTAL MATERIAL VALUE: </Text>
              </TD>
              <TD>
                <Text style={styles.subTotalHeaderText}>
                  {get(data, 'GrandTotalCostForPackingList.GrandTotalMaterialValueCurrency', '')}
                  {' '}
                  {parseAndToFixed(get(data, 'GrandTotalCostForPackingList.GrandTotalMaterialValue', ''), 2)}
                </Text>
              </TD>
            </TR>
            <TR>
              <TD>
                <Table
                  style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
                  tdStyle={{ alignItems: 'start', padding: 2 }}
                  weightings={[0.3, 0.7]}
                >
                  <TR>
                    <TD style={styles.cellLabel}>
                      <Text>TOTAL UNIT</Text>
                      <Text>{' : '}</Text>
                    </TD>
                    <TD>
                      <Text>{get(data, 'GrandTotalCostForPackingList.TotalUnit', '')}</Text>
                    </TD>
                  </TR>
                  <TR>
                    <TD style={styles.cellLabel}>
                      <Text>WEIGHT/KG</Text>
                      <Text>{' : '}</Text>
                    </TD>
                    <TD>
                      <Text>{parseAndToFixed(get(data, 'GrandTotalCostForPackingList.WeightKgs', ''), 2)}</Text>
                    </TD>
                  </TR>
                  <TR>
                    <TD style={styles.cellLabel}>
                      <Text>TOTAL CFT</Text>
                      <Text>{' : '}</Text>
                    </TD>
                    <TD>
                      <Text>{parseAndToFixed(get(data, 'GrandTotalCostForPackingList.TotalCFT', ''), 3)}</Text>
                    </TD>
                  </TR>
                  <TR>
                    <TD style={styles.cellLabel}>
                      <Text>TOTAL MR/GRN</Text>
                      <Text>{' : '}</Text>
                    </TD>
                    <TD>
                      <Text>{get(data, 'GrandTotalCostForPackingList.TotalMRGRN', '')}</Text>
                    </TD>
                  </TR>
                </Table>
              </TD>
              <TD>&nbsp;</TD>
              <TD>&nbsp;</TD>
            </TR>
            <TR>
              <TD>&nbsp;</TD>
              <TD>
                <Text style={styles.subTotalHeaderText}>TOTAL FREIGHT CHARGES: </Text>
              </TD>
              <TD>
                <Text style={styles.subTotalHeaderText}>
                  {get(data, 'GrandTotalCostForPackingList.TotalFreighChargesCurrency', '')}
                  {' '}
                  {parseAndToFixed(get(data, 'GrandTotalCostForPackingList.TotalFreighCharges', ''), 2)}
                </Text>
              </TD>
            </TR>
            <TR>
              <TD>&nbsp;</TD>
              <TD>
                <Text style={styles.subTotalHeaderText}>TOTAL CFR: </Text>
              </TD>
              <TD>
                <Text style={styles.subTotalHeaderText}>
                  {get(data, 'GrandTotalCostForPackingList.TotalCFRCurrency', '')}
                  {' '}
                  {parseAndToFixed(get(data, 'GrandTotalCostForPackingList.TotalCFR', ''), 2)}
                </Text>
              </TD>
            </TR>
          </Table>
        </View>

        <View style={{ height: 8 }} />
        <View>
          <Text style={styles.subTotalHeaderText}>P.0. SUMMARY FOR ENTIRE PACKING LIST :</Text>
          <Table
            style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
            tdStyle={{ alignItems: 'start', padding: 2 }}
            weightings={[0.5]}
          >
            <TR>
              <TD>
                <Text>
                  {get(data, 'POSummeryForEntirePackingList', []).join(', ')}
                </Text>
              </TD>
            </TR>
          </Table>
        </View>
        <View style={{ height: 8 }} />
        <View>
          <Text style={styles.subTotalHeaderText}>SUPPLIER SUMMARY FOR ENTIRE PACKING LIST :</Text>
          <Table
            style={{ ...styles.tableNoBorder, ...{ width: '100%' } }}
            tdStyle={{ alignItems: 'start', padding: 2 }}
            weightings={[0.5]}
          >
            <TR>
              <TD>
                <Text>
                  {get(data, 'SupplierSummaryForEntirePackingList', []).map((supplier) => supplier?.SupplierCode && `[${supplier?.SupplierCode}] ${supplier?.SupplierName}`).join(', ')}
                </Text>
              </TD>
            </TR>
          </Table>
        </View>
      </Page>
    </Document>
  );
}

export default memo(PrintCIPL);
