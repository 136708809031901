import React, { useCallback, useMemo, useState } from 'react';
import {
  Row, Col,
  Typography,
  Button,
  Flex,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';

import GET_ONHAND_REPORT from '@graphql/getGeneralPrintableOnHandReport';
import GET_ONHAND_REPORT_KERRY from '@graphql/getCustomWarehouseOnhandKerryReport';
import GET_DISPATCH_REPORT_KERRY from '@graphql/getCustomWarehouseDispatchedKerryReport';
import GET_SCAN_ID_ONHAND_DISPATCH_REPORT_KERRY from '@graphql/getCustomWarehouseScanIDOnhandDispatchedKerryReport';

import {
  DISPATCH_REPORT_KERRY, ONHAND_REPORT, ONHAND_REPORT_KERRY, SCAN_ID_ONHAND_DISPATCH_KERRY,
} from '@constants/reports';

import { ContentStyled, TableStyled } from '@components/Styles/Global';
import { CardLeftStyled } from '@components/Styles/CardStyles';
import { SpaceActionButton } from '@components/Styles/ButtonStyles';
import ButtonTooltip from '@components/Button/ButtonTooltip';
import useExcelDownload from '@hooks/useExcelDownload';

const reportTypes = [
  'General On Hand',
  'Custom On Hand Kerry',
  'Custom Dispatched Kerry',
  'Custom Scan ID On Hand and Dispatched Kerry',
];

function TableTitle({ title }) {
  return (
    <Typography.Text ellipsis style={{ whiteSpace: 'nowrap', width: 200 }}>
      {title}
    </Typography.Text>
  );
}

function Reports() {
  const [getOnHandReport, { data: dataOnHand, loading: loadingOnHand }] = useLazyQuery(GET_ONHAND_REPORT);
  const [getOnHandReportKerry, { data: dataOnHandKerry, loading: loadingOnHandKerry }] = useLazyQuery(GET_ONHAND_REPORT_KERRY);
  const [getDispatchReportKerry, { data: dataDispatchKerry, loading: loadingDispatchKerry }] = useLazyQuery(GET_DISPATCH_REPORT_KERRY);
  const [getScanIdOnHandDispatchReportKerry, { data: dataScanIdOnHandDispatchKerry, loading: loadingScanIdOnHandDispatchKerry }] = useLazyQuery(GET_SCAN_ID_ONHAND_DISPATCH_REPORT_KERRY);

  const onhandReport = get(dataOnHand, 'results', []);
  const onhandReportKerry = get(dataOnHandKerry, 'results', []);
  const onhandDispatchKerry = get(dataDispatchKerry, 'results', []);
  const scanIdOnhandDispatchKerry = get(dataScanIdOnHandDispatchKerry, 'results', []);

  const [selectedReportType, setSelectedReportType] = useState(null);
  const [isDownloading, downloadExcel] = useExcelDownload();

  const defaultTitle = () => (selectedReportType ? `${selectedReportType} Report` : 'Select Report Type');

  const [data, loading] = useMemo(() => {
    switch (selectedReportType) {
      case 'General On Hand':
        return [onhandReport, loadingOnHand];
      case 'Custom On Hand Kerry':
        return [onhandReportKerry, loadingOnHandKerry];
      case 'Custom Dispatched Kerry':
        return [onhandDispatchKerry, loadingDispatchKerry];
      case 'Custom Scan ID On Hand and Dispatched Kerry':
        return [scanIdOnhandDispatchKerry, loadingScanIdOnHandDispatchKerry];
      default:
        return [[], false];
    }
  }, [selectedReportType, onhandReport, onhandReportKerry, onhandDispatchKerry, scanIdOnhandDispatchKerry]);

  const columns = useMemo(() => {
    switch (selectedReportType) {
      case 'General On Hand':
        return ONHAND_REPORT.map((item) => ({
          title: <TableTitle title={item.title} />,
          dataIndex: item.key,
          key: item.key,
        }));
      case 'Custom On Hand Kerry':
        return ONHAND_REPORT_KERRY.map((item) => ({
          title: <TableTitle title={item.title} />,
          dataIndex: item.key,
          key: item.key,
        }));
      case 'Custom Dispatched Kerry':
        return DISPATCH_REPORT_KERRY.map((item) => ({
          title: <TableTitle title={item.title} />,
          dataIndex: item.key,
          key: item.key,
        }));
      case 'Custom Scan ID On Hand and Dispatched Kerry':
        return SCAN_ID_ONHAND_DISPATCH_KERRY.map((item) => ({
          title: <TableTitle title={item.title} />,
          dataIndex: item.key,
          key: item.key,
        }));
      default:
        return [];
    }
  }, [selectedReportType, onhandReport, onhandReportKerry, onhandDispatchKerry, scanIdOnhandDispatchKerry]);

  const handleReportTypeChange = useCallback(async (type) => {
    setSelectedReportType(type);

    switch (type) {
      case 'General On Hand':
        getOnHandReport();
        break;
      case 'Custom On Hand Kerry':
        getOnHandReportKerry();
        break;
      case 'Custom Dispatched Kerry':
        getDispatchReportKerry();
        break;
      case 'Custom Scan ID On Hand and Dispatched Kerry':
        getScanIdOnHandDispatchReportKerry();
        break;
      default:
        break;
    }
  }, []);

  const handleDownloadReport = useCallback(() => {
    downloadExcel(selectedReportType, data);
  }, [selectedReportType, data]);

  return (
    <ContentStyled>
      <SpaceActionButton>
        <ButtonTooltip
          type="default"
          onClick={handleDownloadReport}
          icon={<DownloadOutlined />}
          tooltipProps={{ title: 'Download Report' }}
          disabled={loading || isDownloading}
          loading={isDownloading}
        />
      </SpaceActionButton>
      <Row gutter={[16]}>
        {
          reportTypes.map((type) => (
            <Col span={6} key={type}>
              <CardLeftStyled
                size="small"
                style={{ height: '100%' }}
              >
                <Flex vertical style={{ height: '100%' }} justify="space-between">
                  <Typography.Text strong>{type}</Typography.Text>
                  <Button
                    style={{ marginTop: 16 }}
                    type="primary"
                    onClick={() => handleReportTypeChange(type)}
                    disabled={loading || isDownloading}
                    loading={selectedReportType === type && loading}
                  >
                    Preview Report
                  </Button>
                </Flex>
              </CardLeftStyled>
            </Col>
          ))
        }
        <Col span={24}>
          <TableStyled
            title={defaultTitle}
            size="small"
            bordered
            loading={loading}
            dataSource={data}
            columns={columns}
            pagination
            scroll={{ x: true }}
            style={{ marginTop: 16 }}
          />
        </Col>
      </Row>
    </ContentStyled>
  );
}

export default Reports;
