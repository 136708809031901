import { gql } from '@apollo/client';

export const UNAUTHORIZE_TO_PICK_SCAN_ID = gql`
    mutation UnauthorizeToPick($data: UnauthorizeToPickInput!) {
        unauthorizeToPick(data: $data) {
            code
            success
            message
        }
    }`;

export default UNAUTHORIZE_TO_PICK_SCAN_ID;
