import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import {
  Button,
  Col, Divider, Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { get } from 'lodash';
import { useLazyQuery } from '@apollo/client';

import GET_ALL_LOAD_PLAN from '@graphql/getAllLoadPlans';
import GET_LOAD_PLAN_BY_ID from '@graphql/TH_getLoadPlanById';
import GET_LOADED_SCAN_IDS from '@graphql/TH_getLoadedScanIdsByWarehouseOutAndLoadPlanId';
import GET_AVAILABLE_FOR_LOADING_SCAN_IDS from '@graphql/getAllAvailableForLoadingScanIds';
import UPDATE_LOAD_PLAN from '@graphql/TH_updateLoadPlan';
import CREATE_LOAD_PLAN from '@graphql/TH_createLoadPlan';
import LOAD_GOODS_TO_LOAD_PLAN from '@graphql/TH_loadGoods';
import UNLOAD_GOODS_TO_LOAD_PLAN from '@graphql/TH_unloadGoods';
import FINALIZE_LOAD_PLAN from '@graphql/TH_finalizeLoadPlan';
import REOPEN_LOAD_PLAN from '@graphql/TH_reopenLoadPlan';

import useCreateUpdateRecord from '@hooks/useCreateUpdateRecord';
import useList from '@hooks/useList';
import useDetail from '@hooks/useDetail';
import { timestampToDate } from '@utils/helpers/date';

import { CardStyled } from '@components/Styles/CardStyles';
import { ContentStyled } from '@components/Styles/Global';
import { TableSmall, TableSubSmall } from '@components/Styles/TableStyles';
import { parseAndToFixed } from '@utils/helpers/helpers';
import LoadPlanLineItems from '../Components/LoadPlanLineItems';
import LoadPlanForm from '../Components/LoadPlanForm';

function OutboundJobLoadPlan({
  currentTab,
  onSetModalActions,
  record,
}) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowLoadedKeys, setSelectedRowLoadedKeys] = useState([]);
  const [selectedRowPackedKeys, setSelectedRowPackedKeys] = useState([]);
  const [formData, setFormData] = useState(null);
  const [formDataCreateLoadPlan, setFormDataCreateLoadPlan] = useState(null);
  const [selectedScanId, setSelectedScanId] = useState(null);
  const [formDataLoadGoods, setFormDataLoadGoods] = useState(null);
  const [formDataUnloadGoods, setFormDataUnloadGoods] = useState(null);
  const [formDataFinalizeLoadPlan, setFormDataFinalizeLoadPlan] = useState(null);
  const [formDataReopenLoadPlan, setFormDataReopenLoadPlan] = useState(null);
  const [onNewDataCallback, setOnNewDataCallback] = useState(null);
  const [form] = useForm();

  const [getLoadPlanLineItems, { data, loading, refetch }] = useLazyQuery(GET_ALL_LOAD_PLAN, {
    variables: { WarehouseOutId: record?.Id },
  });

  const loadPlanLineItems = get(data, 'results', []);

  const { data: loadPlanDetails, loading: loadPlanDetailLoading, refetch: refetchLoadPlanDetails } = useDetail({ query: GET_LOAD_PLAN_BY_ID, id: selectedScanId });

  const { loading: listLoadedLoading, data: loadedScanIdList, refetch: refetchLoaded } = useList({
    skip: !loadPlanDetails?.Id,
    query: GET_LOADED_SCAN_IDS,
    variables: {
      WarehouseOutId: record?.Id,
      LoadPlanId: loadPlanDetails?.Id,
    },
  });

  const { loading: listAvailableLoading, data: availableScanIdList, refetch: refetchAvailable } = useList({
    query: GET_AVAILABLE_FOR_LOADING_SCAN_IDS,
    variables: {
      WarehouseOutId: record?.Id,
    },
  });

  const { loading: loadingSaveLoadPlan } = useCreateUpdateRecord({
    formData,
    mutation: UPDATE_LOAD_PLAN,
    variables: Boolean(loadPlanDetails?.Id) && { id: loadPlanDetails?.Id },
    callback: () => handleSuccessSaveLoadPlan(),
  });

  const { loading: loadingCreateLoadPlan } = useCreateUpdateRecord({
    formData: formDataCreateLoadPlan,
    mutation: CREATE_LOAD_PLAN,
    callback: () => handleSuccessCreateLoadPlan(),
  });

  const { loading: loadingLoadGoods } = useCreateUpdateRecord({
    formData: formDataLoadGoods,
    mutation: LOAD_GOODS_TO_LOAD_PLAN,
    callback: () => handleSuccess(),
  });

  const { loading: loadingUnloadGoods } = useCreateUpdateRecord({
    formData: formDataUnloadGoods,
    mutation: UNLOAD_GOODS_TO_LOAD_PLAN,
    callback: () => handleSuccess(),
  });

  const { loading: loadingFinalizeLoadPlan } = useCreateUpdateRecord({
    formData: formDataFinalizeLoadPlan,
    mutation: FINALIZE_LOAD_PLAN,
    callback: () => handleSuccessLoadPlan(),
  });

  const { loading: loadingReopenLoadPlan } = useCreateUpdateRecord({
    formData: formDataReopenLoadPlan,
    mutation: REOPEN_LOAD_PLAN,
    callback: () => handleSuccessLoadPlan(),
  });

  const handleSelectRowKeys = useCallback((value) => {
    setSelectedRowKeys(value);
  }, []);

  const handleAdd = useCallback(() => {
    setFormDataCreateLoadPlan({
      WarehouseOut_Fk: record?.Id,
    });
  }, []);

  const handleSuccessSaveLoadPlan = useCallback(() => {
    refetch();
  }, [record?.Id]);

  const handleSuccessCreateLoadPlan = useCallback(async () => {
    await refetch();

    if (onNewDataCallback) {
      onNewDataCallback();
    }
  }, [record?.Id, onNewDataCallback]);

  const handleOnNewDataCallback = useCallback((callback) => {
    setOnNewDataCallback(callback);
  }, []);

  const handleSuccess = useCallback(() => {
    setSelectedRowLoadedKeys([]);
    setSelectedRowPackedKeys([]);
    refetchAvailable();
    refetchLoaded();
  }, [record?.Id]);

  const handleSuccessLoadPlan = useCallback(() => {
    refetchLoadPlanDetails();
    refetchAvailable();
    refetchLoaded();
  }, [record?.Id]);

  const handleFinish = useCallback((values) => {
    const newData = { ...values };

    delete newData.LoadedDate;
    delete newData.LoadPlanId;

    setFormData(newData);
  }, [selectedRowKeys]);

  const handleSelectLoadPlan = useCallback((scanId) => {
    setSelectedScanId(scanId);
  }, []);

  const handleSubmit = useCallback(() => {
    form.submit();
  }, []);

  const handleLoadGoods = useCallback(() => {
    setFormDataLoadGoods({
      ScanId_POPartLocation_Fks: selectedRowPackedKeys,
      LoadPlan_Fk: loadPlanDetails?.Id,
    });
  }, [selectedRowPackedKeys, loadPlanDetails?.Id]);

  const handleUnloadGoods = useCallback(() => {
    setFormDataUnloadGoods({
      ScanId_POPartLocation_Fks: selectedRowLoadedKeys,
    });
  }, [selectedRowLoadedKeys, loadPlanDetails?.Id]);

  const handleFinalizeLoadPlan = useCallback(() => {
    setFormDataFinalizeLoadPlan({
      LoadPlan_Fk: loadPlanDetails?.Id,
    });
  }, [loadPlanDetails?.Id]);

  const handleReopenLoadPlan = useCallback(() => {
    setFormDataReopenLoadPlan({
      LoadPlan_Fk: loadPlanDetails?.Id,
    });
  }, [loadPlanDetails?.Id]);

  useEffect(() => {
    if (record?.Id) {
      getLoadPlanLineItems();
    }
  }, [record?.Id]);

  useEffect(() => {
    if (currentTab === 'loadPlan') {
      const isDisabledAllButton = selectedRowLoadedKeys.length === 0 && selectedRowPackedKeys.length === 0;
      onSetModalActions([
        <Space key={1}>&nbsp;</Space>,
        loadPlanDetails?.Id && (
          <Space key={2}>
            <Button type="default" onClick={handleLoadGoods} disabled={loadingLoadGoods || selectedRowLoadedKeys.length > 0 || isDisabledAllButton} loading={loadingLoadGoods}>
              Load Goods
            </Button>
            <Button type="default" onClick={handleUnloadGoods} disabled={loadingUnloadGoods || selectedRowPackedKeys.length > 0 || isDisabledAllButton} loading={loadingUnloadGoods}>
              Unload Goods
            </Button>
            <Button type="default" onClick={handleReopenLoadPlan} disabled={loadingFinalizeLoadPlan || !loadPlanDetails?.LoadedDate} loading={loadingFinalizeLoadPlan}>
              Re-open Load Plan
            </Button>
            <Button type="default" onClick={handleFinalizeLoadPlan} disabled={loadingReopenLoadPlan || loadPlanDetails?.LoadedDate} loading={loadingReopenLoadPlan}>
              Finalize Load Plan
            </Button>
            <div style={{ width: 24 }}>&nbsp;</div>
            <Button type="primary" onClick={handleSubmit} disabled={loadingSaveLoadPlan} loading={loadingSaveLoadPlan}>
              Save
            </Button>
          </Space>
        ),
      ]);
    }
  }, [currentTab, loadPlanDetails?.Id, selectedRowLoadedKeys, selectedRowPackedKeys, loadingSaveLoadPlan,
    loadingLoadGoods, loadingUnloadGoods, loadPlanDetails?.LoadedDate, loadingReopenLoadPlan]);

  useEffect(() => {
    if (currentTab === 'loadPlan' && loadPlanDetails?.Id) {
      refetchAvailable();
      refetchLoaded();
    }
  }, [currentTab, loadPlanDetails]);

  const scanIdColumns = [
    {
      title: 'Overpack / Scan ID',
      dataIndex: 'ScanId',
      key: 'ScanId',
    },
    {
      title: 'Packed Date',
      dataIndex: 'PackedDate',
      key: 'PackedDate',
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {timestampToDate(text, true)}
        </span>
      ),
    },
    {
      title: 'Total Quantity',
      dataIndex: 'TotalQuantity',
      key: 'TotalQuantity',
    },
    {
      title: 'Warehouse',
      dataIndex: 'Warehouse',
      key: 'Warehouse',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Code && `[${text?.Code}] ${text?.Name}`}
        </span>
      ),
    },
    {
      title: 'Storage Location',
      dataIndex: 'StorageLocation',
      key: 'StorageLocation',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Code}
        </span>
      ),
    },
    {
      title: 'Dimensions (INCH) L x W x H',
      dataIndex: 'DimensionsIn',
      key: 'DimensionsIn',
      render: (_, record) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(record?.LengthInch) || 0} x ${parseAndToFixed(record?.WidthInch) || 0} x ${parseAndToFixed(record?.HeightInch) || 0}`}
        </span>
      ),
    },
    {
      title: 'Dimensions (CM) L x W x H',
      dataIndex: 'DimensionsCm',
      key: 'DimensionsCm',
      render: (_, record) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(record?.LengthCm) || 0} x ${parseAndToFixed(record?.WidthCm) || 0} x ${parseAndToFixed(record?.HeightCm) || 0}`}
        </span>
      ),
    },
    {
      title: 'Volume (CBM)',
      dataIndex: 'VolumeCbm',
      key: 'VolumeCbm',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(text, 3) || ''}`}
        </span>
      ),
    },
    {
      title: 'Volume (CFT)',
      dataIndex: 'VolumeCft',
      key: 'VolumeCft',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(text, 3) || ''}`}
        </span>
      ),
    },
    {
      title: 'Weight (KG)',
      dataIndex: 'WeightKg',
      key: 'WeightKg',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(text) || ''}`}
        </span>
      ),
    },
    {
      title: 'Weight (LBS)',
      dataIndex: 'WeightLbs',
      key: 'WeightLbs',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(text) || ''}`}
        </span>
      ),
    },
  ];

  const loadedScanIdColumns = [...scanIdColumns];

  loadedScanIdColumns.splice(1, 0, {
    title: 'Label Series',
    dataIndex: 'LabelSeries',
    key: 'LabelSeries',
    render: (text) => (
      <span style={{ userSelect: 'text' }}>
        {`${text || ''} of ${loadedScanIdList.length}`}
      </span>
    ),
  });

  const availableScanIdColumns = [...scanIdColumns];

  availableScanIdColumns.splice(1, 0, {
    title: 'Label Series',
    dataIndex: 'LabelSeries',
    key: 'LabelSeries',
    render: (text) => (
      <span style={{ userSelect: 'text' }}>
        {`${text || ''} of ${availableScanIdList.length}`}
      </span>
    ),
  });

  const lineItemColumns = [
    {
      title: 'Line Item No.',
      dataIndex: 'LineNumber',
      key: 'LineNumber',
    },
    {
      title: 'Part No.',
      dataIndex: 'Part_Number',
      key: 'Part_Number',
    },
    {
      title: 'Part Description.',
      dataIndex: 'Part_Description',
      key: 'Part_Description',
    },
    {
      title: 'Assigned Quantity',
      dataIndex: 'AssignedQuantity',
      key: 'AssignedQuantity',
    },
  ];

  const rowSelectionLoaded = {
    onChange: (selectedRowKeys) => {
      setSelectedRowLoadedKeys(selectedRowKeys);
      setSelectedRowPackedKeys([]);
    },
    getCheckboxProps: ({ AttachedPOLineItemToScanId }) => ({
      disabled: (AttachedPOLineItemToScanId && AttachedPOLineItemToScanId.length <= 0) || loadPlanDetails?.LoadedDate,
    }),
  };

  const rowSelectionAttached = {
    onChange: (selectedRowKeys) => {
      setSelectedRowLoadedKeys([]);
      setSelectedRowPackedKeys(selectedRowKeys);
    },
    getCheckboxProps: ({ AttachedPOLineItemToScanId }) => ({
      disabled: (AttachedPOLineItemToScanId && AttachedPOLineItemToScanId.length <= 0) || loadPlanDetails?.LoadedDate,
    }),
  };

  const expandedRowRender = ({ ScanId, AttachedPOLineItemToScanId }) => (
    <>
      {AttachedPOLineItemToScanId.length > 0 ? (
        <div style={{ padding: '18px 12px', backgroundColor: '#eee' }}>
          <TableSubSmall
            size="small"
            dataSource={AttachedPOLineItemToScanId}
            columns={lineItemColumns}
            pagination={false}
            rowKey={(record) => `${ScanId}-${record?.Id}`}
            scroll={{
              x: true,
            }}
          />
        </div>
      ) : (<div>No Items</div>)}
    </>
  );

  return (
    <ContentStyled style={{ margin: 12 }}>
      <Row gutter={[12]}>
        <Col sm={6}>
          <CardStyled style={{ backgroundColor: '#fcfcfc' }}>
            <LoadPlanLineItems
              onAddClick={handleAdd}
              onSelect={handleSelectLoadPlan}
              onSelectRowKeys={handleSelectRowKeys}
              data={loadPlanLineItems}
              loading={loading}
              loadingAdd={loadingCreateLoadPlan}
              selectedRowKeys={selectedRowKeys}
              onNewDataCallback={handleOnNewDataCallback}
            />
          </CardStyled>
        </Col>
        <Col sm={18}>
          {loadPlanDetails?.Id && (
            <>
              <CardStyled style={{ backgroundColor: '#fcfcfc' }}>
                <Spin spinning={loadPlanDetailLoading}>
                  <LoadPlanForm
                    form={form}
                    onFinish={handleFinish}
                    loadPlanDetails={loadPlanDetails}
                  />
                </Spin>
              </CardStyled>
              <CardStyled>
                <Typography.Title level={5}>Loaded Overpack / Scan IDs</Typography.Title>
                <TableSmall
                  style={{ marginTop: 24 }}
                  size="small"
                  dataSource={loadedScanIdList}
                  loading={listLoadedLoading}
                  columns={loadedScanIdColumns}
                  pagination={false}
                  scroll={{
                    x: true,
                  }}
                  rowKey="Id"
                  rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: selectedRowLoadedKeys,
                    ...rowSelectionLoaded,
                  }}
                  expandable={{
                    rowExpandable: ({ AttachedPOLineItemToScanId }) => AttachedPOLineItemToScanId && AttachedPOLineItemToScanId.length > 0,
                    defaultExpandAllRows: true,
                    expandedRowRender,
                  }}
                />
              </CardStyled>
              <Divider />
              <CardStyled>
                <Typography.Title level={5}>Available for Loading</Typography.Title>
                <TableSmall
                  style={{ marginTop: 24 }}
                  size="small"
                  dataSource={availableScanIdList}
                  loading={listAvailableLoading}
                  columns={availableScanIdColumns}
                  pagination={false}
                  scroll={{
                    x: true,
                  }}
                  rowKey="Id"
                  rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: selectedRowPackedKeys,
                    ...rowSelectionAttached,
                  }}
                  expandable={{
                    rowExpandable: ({ AttachedPOLineItemToScanId }) => AttachedPOLineItemToScanId && AttachedPOLineItemToScanId.length > 0,
                    defaultExpandAllRows: true,
                    expandedRowRender,
                  }}
                />
              </CardStyled>
            </>
          )}
        </Col>
      </Row>
    </ContentStyled>
  );
}

export default memo(OutboundJobLoadPlan);
