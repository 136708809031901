import React from 'react';

import {
  Col,
  Row,
  Divider,
  Table,
  Collapse,
} from 'antd';

const styles = {
  rootContainer: {
    backgroundColor: '#1A1A1D',
    height: '100%',
  },
  content: {
    height: '65%',
    padding: '1%',
  },
  header: {
    height: '30%',
    backgroundColor: 'transparent',
    marginTop: '5%',
  },
  card: {
    height: '100%',
    overflow: 'auto',
  },
  collapse: {
    overflow: 'auto',
  },
};

function InboundOutboundJobs() {
  const inboundJobsColumns = [
    {
      title: 'Line No.',
      dataIndex: 'lineno',
      key: 'lineno',
    },
    {
      title: 'Part No.',
      dataIndex: 'partno',
      key: 'partno',
    },
    {
      title: 'Part Name',
      dataIndex: 'partname',
      key: 'partname',
    },
    {
      title: 'Inbound Job No.',
      dataIndex: 'inboundjobno',
      key: 'inboundjobno',
    },
    {
      title: 'P.O. Type',
      dataIndex: 'potype',
      key: 'potype',
    },
    {
      title: 'QTY',
      dataIndex: 'qty',
      key: 'qty',
    },
    {
      title: 'Freight Import Job No (G2G)',
      dataIndex: 'freightimportjobno',
      key: 'freightimportjobno',
    },
  ];

  const inboundJobDataSource = [
    {
      key: '1',
      lineno: 1,
      partno: '110054',
      partname: 'LUBRICANT-OIL,WD-40,110054, WD40,AERSOL...',
      inboundjobno: '13106481',
      potype: 'Ordinary',
      gacscanid: 'USHOU150000000171123',
      qty: 12,
      freightimportjobno: '',
    },
    {
      key: '1',
      lineno: 1,
      partno: '110054',
      partname: 'LUBRICANT-OIL,WD-40,110054, WD40,AERSOL...',
      inboundjobno: '13106481',
      potype: 'Ordinary',
      gacscanid: 'USHOU150000000171123',
      qty: 12,
      freightimportjobno: '',
    },
    {
      key: '1',
      lineno: 1,
      partno: '110054',
      partname: 'LUBRICANT-OIL,WD-40,110054, WD40,AERSOL...',
      inboundjobno: '13106481',
      potype: 'Ordinary',
      gacscanid: 'USHOU150000000171123',
      qty: 12,
      freightimportjobno: '',
    },
  ];

  // OUTBOUND JOBS TABLE SAMPLE DATA AND COLUMNS
  const outboundJobsColumns = [
    {
      title: 'Line No.',
      dataIndex: 'lineno',
      key: 'lineno',
    },
    {
      title: 'Part No.',
      dataIndex: 'partno',
      key: 'partno',
    },
    {
      title: 'Part Name',
      dataIndex: 'partname',
      key: 'partname',
    },
    {
      title: 'Outbound Job No.',
      dataIndex: 'outboundjobno',
      key: 'outboundjobno',
    },
    {
      title: 'Job Type',
      dataIndex: 'jobtype',
      key: 'jobtype',
    },
    {
      title: 'Scan ID',
      dataIndex: 'gacscanid',
      key: 'gacscanid',
    },
    {
      title: 'QTY',
      dataIndex: 'qty',
      key: 'qty',
    },
    {
      title: 'Freight Job No.',
      dataIndex: 'freightjobno',
      key: 'freightjobno',
    },
    {
      title: 'Freight Local Prod..',
      dataIndex: 'freightlocalprod',
      key: 'freightlocalprod',
    },
    {
      title: 'Dispatched Date',
      dataIndex: 'dispatcheddate',
      key: 'dispatcheddate',
    },
    {
      title: 'Tracking Job No.',
      dataIndex: 'trackingjobno',
      key: 'trackingjobno',
    },
  ];

  const outboundJobDataSource = [
    {
      key: '1',
      lineno: 1,
      partno: '110054',
      partname: 'LUBRICANT-OIL,WD-40,110054, WD40,AERSOL...',
      outboundjobno: '14024179',
      jobtype: 'PACKING',
      gacscanid: 'USHOU150000000171123',
      qty: 12,
      freightjobno: '9180152632',
      freightlocalprod: 'Road Export',
      dispatcheddate: '23/08/2017',
      trackingjobno: 'USHOU',
    },
    {
      key: '1',
      lineno: 1,
      partno: '110054',
      partname: 'LUBRICANT-OIL,WD-40,110054, WD40,AERSOL...',
      outboundjobno: '14024179',
      jobtype: 'PACKING',
      gacscanid: 'USHOU150000000171123',
      qty: 12,
      freightjobno: '9180152632',
      freightlocalprod: 'Road Export',
      dispatcheddate: '23/08/2017',
      trackingjobno: 'USHOU',
    },
    {
      key: '1',
      lineno: 1,
      partno: '110054',
      partname: 'LUBRICANT-OIL,WD-40,110054, WD40,AERSOL...',
      outboundjobno: '14024179',
      jobtype: 'PACKING',
      gacscanid: 'USHOU150000000171123',
      qty: 12,
      freightjobno: '9180152632',
      freightlocalprod: 'Road Export',
      dispatcheddate: '23/08/2017',
      trackingjobno: 'USHOU',
    },
  ];

  return (
    <Row gutter={24}>
      <br />
      {' '}
      <br />
      <Col span={24}>
        <Row gutter={24}>
          <Col span={24}>
            <Collapse
              style={styles.collapse}
              items={[
                {
                  key: '1',
                  label: 'Inbound Jobs',
                  children: <Table
                    dataSource={inboundJobDataSource}
                    columns={inboundJobsColumns}
                    pagination={{
                      position: ['bottomRight'],
                    }}
                  />,
                },
              ]}
            />
            <Divider />

            <Collapse
              style={styles.collapse}
              items={[
                {
                  key: '1',
                  label: 'Outbound Jobs',
                  children: <Table
                    dataSource={outboundJobDataSource}
                    columns={outboundJobsColumns}
                    pagination={{
                      position: ['bottomRight'],
                    }}
                  />,
                },
              ]}
            />

          </Col>
        </Row>
      </Col>

    </Row>
  );
}
export default InboundOutboundJobs;
