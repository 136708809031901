import { gql } from '@apollo/client';

const GET_ONHAND_REPORT_KERRY = gql`
    query GetCustomWarehouseOnhandKerryReport {
        getCustomWarehouseOnhandKerryReport {
            code
            success
            message
            count
            results
        }
    }
  `;

export default GET_ONHAND_REPORT_KERRY;
