import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import { Flex, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/es/form/Form';
import styled from 'styled-components';

import ATTACH_PO_LINE_ITEM_TO_SCAN_ID from '@graphql/TH_attachPoLineItemToScanId';
import UPDATE_OR_DETACH_PO_LINE_ITEM_TO_SCAN_ID from '@graphql/TH_updateOrDetachedPOLineItemToScanId';

import useCreateUpdateRecord from '@hooks/useCreateUpdateRecord';

import { CardStyled } from '@components/Styles/CardStyles';
import { TableSmall, TableSubSmall } from '@components/Styles/TableStyles';
import { parseScanIdStatus } from '@utils/helpers/status';
import DistributeQuantity from '../Modals/DistributeQuantity';

const ContentFullHeight = styled(Content)`
  padding: 16px;
  height: 100%;
  background: #eee;
  border-radius: 4px;

  & .ant-row, & .ant-col, & .ant-card {
    height: 100% !important;
  }
`;

function ManualDistributionTransfer({
  scanIdList,
  poLineItemList,
  onSuccess,
}) {
  const [formData, setFormData] = useState(null);
  const [openDistributeQuantity, setOpenDistributeQuantity] = useState(false);
  const [assignDirection, setAssignDirection] = useState(false);
  const [disableAssignLeft, setDisableAssignLeft] = useState(true);
  const [disableAssignRight, setdisableAssignRight] = useState(true);

  const [distributionForm] = useForm();

  const { loading } = useCreateUpdateRecord({
    formData,
    mutation: assignDirection === 'assignLeft' ? ATTACH_PO_LINE_ITEM_TO_SCAN_ID : UPDATE_OR_DETACH_PO_LINE_ITEM_TO_SCAN_ID,
    callback: () => handleSuccess(),
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeysPoLineItem, setSelectedRowKeysPoLineItem] = useState([]);
  const [selectRowScanId, setSelectedRowScanId] = useState(null);
  const [selectedPoLineItemId, setSelectedPoLineItemId] = useState(null);

  const handleSubmit = useCallback(() => {
    distributionForm.submit();
  }, []);

  const handleSuccess = useCallback(() => {
    setOpenDistributeQuantity(false);
    onSuccess();
  }, []);

  const handleFinish = useCallback((values) => {
    const newData = { ...values };
    if (assignDirection === 'assignLeft') {
      newData.ScanId_PoPartLocation_Fk = selectedRowKeys[0];
      newData.LineNumber_PurchaseOrderDetail_Fk = selectedRowKeysPoLineItem[0];
    } else {
      newData.ScanId_PoPartLocation_Fk = selectRowScanId;
      newData.LineNumber_PurchaseOrderDetail_Fk = parseInt(selectedPoLineItemId, 10);
    }
    setFormData(newData);
  }, [selectedRowKeys, selectedRowKeysPoLineItem, assignDirection]);

  const handleOpenAttachDistributeQuantity = useCallback((dir) => {
    setOpenDistributeQuantity(true);
    setAssignDirection(dir);
  }, []);

  const handleCancelDistributeQuantity = useCallback(() => {
    setOpenDistributeQuantity(false);
  }, []);

  useEffect(() => {
    setDisableAssignLeft(true);
    setdisableAssignRight(true);

    if (selectedRowKeys.length > 0 && selectedRowKeysPoLineItem.length > 0 && !selectRowScanId && !selectedPoLineItemId) {
      setDisableAssignLeft(false);
    }
    if (selectRowScanId && selectedPoLineItemId && selectedRowKeysPoLineItem.length === 0) {
      setdisableAssignRight(false);
    }
    if (selectRowScanId && selectedPoLineItemId && selectedRowKeysPoLineItem.length > 1) {
      setdisableAssignRight(true);
      setDisableAssignLeft(true);
    }

    const poConfirmed = scanIdList.filter((scanId) => scanId.Id === selectRowScanId && Boolean(scanId.ConfirmedDate));
    if (poConfirmed.length > 0) {
      setdisableAssignRight(true);
    }
  }, [selectedRowKeys, selectedRowKeysPoLineItem, selectRowScanId, selectedPoLineItemId, scanIdList]);

  const scanIdColumns = [
    {
      title: 'Scan ID',
      dataIndex: 'ScanId',
      key: 'ScanId',
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      render: (_, { ConfirmedDate, PutawayDate }) => (
        <span style={{ userSelect: 'text' }}>
          {parseScanIdStatus(ConfirmedDate, PutawayDate)}
        </span>
      ),
    },
    {
      title: 'Total Quantity',
      dataIndex: 'TotalQuantity',
      key: 'TotalQuantity',
    },
    {
      title: 'Label Series',
      dataIndex: 'LabelSeries',
      key: 'LabelSeries',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {`${text || ''} of ${scanIdList.length}`}
        </span>
      ),
    },
    {
      title: 'Dimensions (INCH) L x W x H',
      dataIndex: 'DimensionsIn',
      key: 'DimensionsIn',
      render: (_, record) => (
        <span style={{ userSelect: 'text' }}>
          {`${record?.LengthInch || 0} x ${record?.WidthInch || 0} x ${record?.HeightInch || 0}`}
        </span>
      ),
    },
    {
      title: 'Dimensions (CM) L x W x H',
      dataIndex: 'DimensionsCm',
      key: 'DimensionsCm',
      render: (_, record) => (
        <span style={{ userSelect: 'text' }}>
          {`${record?.LengthCm || 0} x ${record?.WidthCm || 0} x ${record?.HeightCm || 0}`}
        </span>
      ),
    },
    {
      title: 'Volume (CBM)',
      dataIndex: 'VolumeCbm',
      key: 'VolumeCbm',
    },
    {
      title: 'Weight (KG)',
      dataIndex: 'WeightKg',
      key: 'WeightKg',
    },
    {
      title: 'Weight (LBS)',
      dataIndex: 'WeightLbs',
      key: 'WeightLbs',
    },
  ];

  const lineItemReceiveColumns = [
    {
      title: 'PO No.',
      dataIndex: 'PurchasedOrder_Fk',
      key: 'PurchasedOrder_Fk',
    },
    {
      title: 'Line Item No.',
      dataIndex: 'LineNumber',
      key: 'LineNumber',
    },
    {
      title: 'Part No.',
      dataIndex: 'Part.Part_Number',
      key: 'Part.Part_Number',
      render: (_, record) => (
        <span style={{ userSelect: 'text' }}>
          {record?.Part?.Part_Number}
        </span>
      ),
    },
    {
      title: 'Part Description.',
      dataIndex: 'Part.Part_Description',
      key: 'Part.Part_Description',
      render: (_, record) => (
        <span style={{ userSelect: 'text' }}>
          {record?.Part?.Part_Description}
        </span>
      ),
    },
    {
      title: 'Expected Quantity',
      dataIndex: 'ExpectedQuantity',
      key: 'ExpectedQuantity',
    },
    {
      title: 'Received Quantity',
      dataIndex: 'ReceivedQuantity',
      key: 'ReceivedQuantity',
    },
    {
      title: 'Line Status',
      dataIndex: 'LineStatus',
      key: 'LineStatus',
    },
  ];

  const lineItemColumns = [
    {
      title: 'Line Item No.',
      dataIndex: 'LineNumber',
      key: 'LineNumber',
    },
    {
      title: 'Part No.',
      dataIndex: 'Part_Number',
      key: 'Part_Number',
      render: (_, record) => (
        <span style={{ userSelect: 'text' }}>
          {record?.Part?.Part_Number}
        </span>
      ),
    },
    {
      title: 'Part Description.',
      dataIndex: 'Part_Description',
      key: 'Part_Description',
      render: (_, record) => (
        <span style={{ userSelect: 'text' }}>
          {record?.Part?.Part_Description}
        </span>
      ),
    },
    {
      title: 'Assigned Quantity',
      dataIndex: 'AssignedQuantity',
      key: 'AssignedQuantity',
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      if (selectedRowKeys.length > 1) {
        setSelectedRowKeys([selectedRowKeys[1]]);
      } else {
        setSelectedRowKeys(selectedRowKeys);
      }
      setSelectedRowScanId(null);
      setSelectedPoLineItemId(null);
    },
  };

  const rowSelectionPoLineItems = {
    onChange: (selectedRowKeys) => {
      if (selectedRowKeys.length > 1) {
        setSelectedRowKeysPoLineItem([selectedRowKeys[1]]);
      } else {
        setSelectedRowKeysPoLineItem(selectedRowKeys);
      }
    },
  };

  const expandedRowRender = ({ Id, ScanId, AttachedPOLineItemToScanId }) => (
    <>
      {AttachedPOLineItemToScanId.length > 0 ? (
        <div style={{ padding: '18px 12px', backgroundColor: '#eee' }}>
          <TableSubSmall
            size="small"
            dataSource={AttachedPOLineItemToScanId}
            columns={lineItemColumns}
            pagination={false}
            rowKey={(record) => `${ScanId}-${record?.Id}`}
            rowSelection={{
              hideSelectAll: true,
              type: 'checkbox',
              selectedRowKeys,
              onChange: (selectedRowKeys) => {
                if (selectedRowKeys.length > 1) {
                  setSelectedRowKeys([selectedRowKeys[1]]);
                } else {
                  setSelectedRowKeys(selectedRowKeys);
                }

                if (selectedRowKeys.length > 0) {
                  const currentRowKey = selectedRowKeys.length > 1 ? selectedRowKeys[1] : selectedRowKeys[0];
                  const poLineItemId = currentRowKey.split('-')[1];

                  setSelectedRowScanId(Id);
                  setSelectedPoLineItemId(poLineItemId);
                } else {
                  setSelectedRowScanId(null);
                  setSelectedPoLineItemId(null);
                }
              },
            }}
            scroll={{
              x: true,
            }}
          />
        </div>
      ) : (<div>No Items</div>)}
    </>
  );

  return (
    <ContentFullHeight>
      <Flex gap="small" style={{ height: '100%' }}>
        <CardStyled style={{ flexGrow: 1, overflowY: 'auto' }}>
          <Typography.Title level={5}>Scan IDs</Typography.Title>
          <TableSmall
            style={{ marginTop: 24 }}
            size="small"
            dataSource={scanIdList}
            columns={scanIdColumns}
            pagination={false}
            scroll={{
              x: true,
            }}
            rowKey="Id"
            rowSelection={{
              hideSelectAll: true,
              type: 'checkbox',
              selectedRowKeys,
              ...rowSelection,
            }}
            selectedRowKeys={selectedRowKeys}
            expandable={{
              rowExpandable: ({ AttachedPOLineItemToScanId }) => AttachedPOLineItemToScanId && AttachedPOLineItemToScanId.length > 0,
              defaultExpandAllRows: true,
              expandedRowRender,
            }}
          />
        </CardStyled>
        <Flex vertical justify="center" align="center" gap="small" style={{ height: '100%' }}>
          <DistributeQuantity
            form={distributionForm}
            onSave={handleSubmit}
            onFinish={handleFinish}
            open={openDistributeQuantity}
            onCancel={handleCancelDistributeQuantity}
            onOpenAttach={handleOpenAttachDistributeQuantity}
            assignDirection={assignDirection}
            loading={loading}
            disableAssignLeft={disableAssignLeft}
            disableAssignRight={disableAssignRight}
          />
        </Flex>
        <CardStyled style={{ flexGrow: 1, overflowY: 'auto' }}>
          <Typography.Title level={5}>P.O. Line Items</Typography.Title>
          <TableSmall
            style={{ marginTop: 24 }}
            size="small"
            dataSource={poLineItemList}
            columns={lineItemReceiveColumns}
            pagination={false}
            scroll={{
              x: true,
            }}
            rowKey="Id"
            rowSelection={{
              hideSelectAll: true,
              type: 'checkbox',
              selectedRowKeys: selectedRowKeysPoLineItem,
              ...rowSelectionPoLineItems,
            }}
          />
        </CardStyled>
      </Flex>
    </ContentFullHeight>
  );
}

export default memo(ManualDistributionTransfer);
