import { gql } from '@apollo/client';

const CREATE_PURCHASE_ORDER_OSD_REASON = gql`
    mutation CreatePurchaseOrderOsdReason($data: PurchaseOrderOSDReasonInput!) {
        createPurchaseOrderOSDReason(data: $data) {
            code
            success
            message
        }
    }
`;

export default CREATE_PURCHASE_ORDER_OSD_REASON;
