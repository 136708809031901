import { memo } from 'react';

import GET_ALL_PARENT_PART from '@graphql/getAllParentPart';

import SelectLazy from '../SelectLazy';

function SelectParentPart(props) {
  const renderOptions = (data) => (data || []).map((d) => ({
    value: d.Id,
    label: `[${d.Part_Number}] ${d.Part_Description}`,
  }));

  return (
    <SelectLazy
      {...props}
      placeholder="Select Parent Part"
      customRender={renderOptions}
      query={GET_ALL_PARENT_PART}
    />
  );
}

export default memo(SelectParentPart);
